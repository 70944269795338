<template>
  <v-row>
    <v-col cols="12" md="6" class="pa-0">
      <v-card flat>
        <v-card-title>
          <h2>Kilépő dolgozók</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="kiEv"
                    label="Év"
                    :items="evek"
                    item-text="name"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select
                    v-model="kiHonap"
                    label="Hónap"
                    :items="honapok"
                    item-text="name"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="12" md="7" class="py-0">
                  <v-text-field
                    v-model="kiFilter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="kilepokItems"
                :headers="headers"
                :search="kiFilter"
                :loading="isLoadingKi"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.nev`]="{ item }">
                  <span>{{ item.nev }}</span>
                  <span
                    style="color: red;"
                    title="Egyszerűsített foglalkozatás"
                    >{{ item.fogltipus === 5 ? "*" : "" }}</span
                  >
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-btn
                    :block="true"
                    color="success"
                    :to="`/dolgozok/${item.azonosito}`"
                    >Adatlap</v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" class="pa-0">
      <v-card flat>
        <v-card-title>
          <h2>Belépő dolgozók</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="beEv"
                    label="Év"
                    :items="evek"
                    item-text="name"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select
                    v-model="beHonap"
                    label="Hónap"
                    :items="honapok"
                    item-text="name"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="12" md="7" class="py-0">
                  <v-text-field
                    v-model="beFilter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="belepokItems"
                :headers="headers"
                :search="beFilter"
                :loading="isLoadingBe"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.nev`]="{ item }">
                  <span>{{ item.nev }}</span>
                  <span
                    style="color: red;"
                    title="Egyszerűsített foglalkozatás"
                    >{{ item.fogltipus === 5 ? "*" : "" }}</span
                  >
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-btn
                    :block="true"
                    color="success"
                    :to="`/dolgozok/${item.azonosito}`"
                    >Adatlap</v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Ki/Belépő dolgozók",
  data() {
    return {
      isLoadingKi: true,
      isLoadingBe: true,
      evek: [
        { id: "2007", name: "2007" },
        { id: "2008", name: "2008" },
        { id: "2009", name: "2009" },
        { id: "2010", name: "2010" },
        { id: "2011", name: "2011" },
        { id: "2012", name: "2012" },
        { id: "2013", name: "2013" },
        { id: "2014", name: "2014" },
        { id: "2015", name: "2015" },
        { id: "2016", name: "2016" },
        { id: "2017", name: "2017" },
        { id: "2018", name: "2018" },
        { id: "2019", name: "2019" },
        { id: "2020", name: "2020" },
        { id: "2018", name: "2021" },
        { id: "2019", name: "2022" },
        { id: "2020", name: "2023" },
        { id: "2019", name: "2024" },
        { id: "2020", name: "2025" }
      ],
      honapok: [
        { id: "01", name: "Január" },
        { id: "02", name: "Február" },
        { id: "03", name: "Március" },
        { id: "04", name: "Április" },
        { id: "05", name: "Május" },
        { id: "06", name: "Június" },
        { id: "07", name: "Július" },
        { id: "08", name: "Augusztus" },
        { id: "09", name: "Szeptember" },
        { id: "10", name: "Október" },
        { id: "11", name: "November" },
        { id: "12", name: "December" }
      ],
      kiEv: this.$store.getters.getDolgozokKibelepok.kiEv,
      kiHonap: this.$store.getters.getDolgozokKibelepok.kiHonap,
      beEv: this.$store.getters.getDolgozokKibelepok.beEv,
      beHonap: this.$store.getters.getDolgozokKibelepok.beHonap,
      kiFilter: this.$store.getters.getDolgozokKibelepok.kiFilter,
      beFilter: this.$store.getters.getDolgozokKibelepok.beFilter,
      kilepokItems: [],
      belepokItems: [],
      headers: [
        { text: "Azonosító", value: "azonosito" },
        { text: "Név", value: "nev" },
        { text: "Cég", value: "ceg" },
        { text: "Dátum", value: "datum" },
        { text: "Műveletek", value: "muveletek" }
      ]
    };
  },
  computed: {
    mindenDolgozo() {
      return this.$store.getters.getPrivileges.mindenDolgozo;
    },
    cUser() {
      return this.$store.getters.getUser.id;
    }
  },
  watch: {
    kiEv: function() {
      this.getKiData();
    },
    kiHonap: function() {
      this.getKiData();
    },
    beEv: function() {
      this.getBeData();
    },
    beHonap: function() {
      this.getBeData();
    }
  },
  beforeMount() {
    this.getKiData();
    this.getBeData();
  },
  beforeDestroy() {
    this.saveInfo();
  },
  methods: {
    getKiData() {
      this.kilepokItems = [];
      this.isLoadingKi = true;
      this.$axios
        .get(
          `dolgozok/users_kileptek?ev=${this.kiEv}&honap=${this.kiHonap}&mindenDolgozo=${this.mindenDolgozo}&cUser=${this.cUser}`
        )
        .then(response => {
          let items = [];
          for (let item of response.data) {
            items.push({
              azonosito: item.id,
              nev: item.nev,
              ceg: item.meta_aktualisceg.megnevezes,
              datum: item.kilepesi_datum,
              muveletek: item.id,
              fogltipus: item.fogltipus
            });
          }
          this.kilepokItems = items;
          this.isLoadingKi = false;
        });
    },
    getBeData() {
      this.belepokItems = [];
      this.isLoadingBe = true;
      this.$axios
        .get(
          `dolgozok/users_beleptek?ev=${this.beEv}&honap=${this.beHonap}&mindenDolgozo=${this.mindenDolgozo}&cUser=${this.cUser}`
        )
        .then(response => {
          let items = [];
          for (let item of response.data) {
            items.push({
              azonosito: item.id,
              nev: item.nev,
              ceg: item.meta_aktualisceg.megnevezes,
              datum: item.belepesi_datum,
              muveletek: item.id,
              fogltipus: item.fogltipus
            });
          }
          this.belepokItems = items;
          this.isLoadingBe = false;
        });
    },
    resetKiTable() {
      this.kiEv = this.$moment().format("YYYY");
      this.kiHonap = this.$moment().format("MM");
      this.kiFilter = "";
      this.getKiData();
    },
    resetBeTable() {
      this.beEv = this.$moment().format("YYYY");
      this.beHonap = this.$moment().format("MM");
      this.beFilter = "";
      this.getBeData();
    },
    saveInfo() {
      this.$store.dispatch("saveKibelepok", {
        kiEv: this.kiEv,
        kiHonap: this.kiHonap,
        beEv: this.beEv,
        beHonap: this.beHonap,
        kiFilter: this.kiFilter,
        beFilter: this.beFilter
      });
    }
  }
};
</script>

<style></style>
