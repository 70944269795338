import KutyakIndex from "@/pages/kutyak/index";
import KutyakID from "@/pages/kutyak/_id";
import KutyakNewKutya from "@/pages/kutyak/newKutya";

export default [
  {
    name: "kutyak-index",
    path: "kutyak",
    component: KutyakIndex
  },
  {
    name: "kutyak-newKutya",
    path: "kutyak/newKutya",
    component: KutyakNewKutya
  },
  {
    name: "kutyak-id",
    path: "kutyak/:id",
    component: KutyakID
  }
];
