<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Befejezett projektek</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="3" offset-md="9" class="py-0">
                  <v-text-field
                    v-model="filterBefejezett"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="itemsBefejezett"
                :headers="headersBefejezett"
                :search="filterBefejezett"
                :loading="isLoadingBefejezett"
                show-expand
                single-expand
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.sorszam`]="{ item }">
                  {{
                    `BSTP${item.ev}/${item.sorszam.toString().padStart(4, "0")}`
                  }}
                </template>
                <template v-slot:[`item.megjegyzes`]="{ item }">
                  <v-tooltip v-if="item.megjegyzes.length > 45" bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        {{ item.megjegyzes.substr(0, 45) }}...
                      </span>
                    </template>
                    <span>{{ item.megjegyzes }}</span>
                  </v-tooltip>
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        {{ item.megjegyzes }}
                      </span>
                    </template>
                    <span>{{ item.megjegyzes }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-btn
                    color="success"
                    :block="true"
                    :to="`/projektek/${item.id}`"
                    >Projekt Adatlap</v-btn
                  >
                </template>
                <template
                  v-slot:[`item.data-table-expand`]="{
                    item,
                    isExpanded,
                    expand
                  }"
                >
                  <v-icon
                    v-if="item.esemenyek.length > 0 && !isExpanded"
                    @click="expand(true)"
                    >mdi-chevron-down</v-icon
                  >
                  <v-icon
                    v-if="item.esemenyek.length > 0 && isExpanded"
                    style="transform: rotate(-180deg)"
                    @click="expand(false)"
                    >mdi-chevron-down</v-icon
                  >
                </template>
                <template v-slot:expanded-item="{ item }">
                  <td colspan="8">
                    <v-list>
                      <v-list-item
                        v-for="(i, key) in item.esemenyek"
                        :key="key"
                      >
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="12" md="5" style="text-align: right;">
                              {{ i.datum }}
                            </v-col>
                            <v-col cols="12" md="5" offset-md="1">
                              {{ searchObject("value", i.tipus).text }}
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Befejezett projektek",
  data() {
    return {
      isLoadingBefejezett: true,
      filterBefejezett: "",
      itemsBefejezett: [],
      headersBefejezett: [
        { text: "Sorszám", value: "sorszam" },
        { text: "Ügyfél", value: "ugyfel" },
        { text: "Objektum", value: "objektum" },
        { text: "Megkezdve", value: "megkezdve" },
        { text: "Befejezve", value: "befejezve" },
        { text: "Megjegyzés", value: "megjegyzes" },
        { text: "Műveletek", value: "muveletek" }
      ],
      esemenyTipusok: [
        {
          text: "Ügyfél jelentkezése",
          value: 1
        },
        {
          text: "Felmérés",
          value: 2
        },
        {
          text: "Árajánlat készítése",
          value: 3
        },
        {
          text: "Megrendelés",
          value: 4
        },
        {
          text: "Szerződés",
          value: 5
        },
        {
          text: "Munka megkezdve",
          value: 6
        },
        {
          text: "Munkaközi esemény",
          value: 7
        },
        {
          text: "Munka befejezve",
          value: 8
        },
        {
          text: "Átadás/átvétel",
          value: 9
        },
        {
          text: "Számlázás",
          value: 10
        }
      ]
    };
  },
  beforeMount() {
    this.getBefejezettData();
  },
  methods: {
    getBefejezettData() {
      this.itemsBefejezett = [];
      this.isLoadingBefejezett = true;
      this.$axios.get("projektek/befejezett").then(response => {
        this.itemsBefejezett = response.data;
        this.isLoadingBefejezett = false;
      });
    },
    searchObject(prop, nameKey) {
      for (let i = 0; i < this.esemenyTipusok.length; i++) {
        if (this.esemenyTipusok[i][prop] === nameKey) {
          return this.esemenyTipusok[i];
        }
      }
    }
  }
};
</script>

<style></style>
