<template>
  <v-col cols="12" class="py-0">
    <v-row>
      <v-col cols="12" class="px-0 py-1">
        <v-btn :block="true" color="warning" @click="ujGepDiszpecser">
          Új Számítógép
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["ujGepDiszpecser", "newUgyfel"]
};
</script>
