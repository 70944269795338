<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">Új jelentés</h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <v-autocomplete
                    :items="items"
                    item-value="eid"
                    item-text="objektum"
                    :filter="customFilter"
                    label="Objektum"
                    @change="doChange"
                  >
                    <template v-slot:item="{ item }">
                      {{ item.azonosito }} | {{ item.objektum }} |
                      {{ item.iranyitoszam }} {{ item.varos }},
                      {{ item.utcahsz }} | {{ item.ceg }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.azonosito }} | {{ item.objektum }} |
                      {{ item.iranyitoszam }} {{ item.varos }},
                      {{ item.utcahsz }} | {{ item.ceg }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.datum"
                    label="Dátum"
                    type="date"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.idopont1"
                    label="Riasztás vételének időpontja"
                    type="time"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.idopont2"
                    label="Helyszínre érkezés időpontja"
                    type="time"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.idopont3"
                    label="Ellenőrzés befejezésének időpontja"
                    type="time"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.idopont4"
                    label="Helyszín elhagyásának időpontja"
                    type="time"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    :value="idopont5"
                    label="Kivonulás időtartama"
                    disabled
                    readonly
                    suffix="perc"
                  />
                </v-col>
                <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-checkbox v-model="data.ok1" label="Betörési riasztás" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-checkbox
                    v-model="data.ok2"
                    label="Támadás / Pánikriasztás"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-checkbox v-model="data.ok3" label="Tűzriasztás" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-checkbox v-model="data.ok4" label="Segélyhívás" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-checkbox v-model="data.ok5" label="Egyéb eset" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="data.zona" label="Zóna száma" />
                </v-col>
                <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data1" label="Behatolási nyomok" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data2"
                    label="Bekapcsolt elektromos készülék"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data3" label="Belső ellenőrzés" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data4" label="Betörés" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data5" label="Bezárt háziállat" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data6"
                    label="Bűnmegelőzési intézkedés"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data7" label="Egyéb ok" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data8"
                    label="Eredeti állapot visszaállítása"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data9" label="Észlelt személyek" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data10"
                    label="Feltört ajtó/ablak"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data11" label="Helyszín őrzése" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data12" label="Helyszínentartás" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data13"
                    label="Időntúli riasztás lemondás"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data14"
                    label="Indokolatlan pánikjelzés"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data15"
                    label="Kármegelőzési intézkedés"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data16" label="Kezelői hiba" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data17" label="Külső ellenőrzés" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data18" label="Mentők értesítve" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data19"
                    label="Nyitott ajtó/ablak"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data20"
                    label="Rendőrség értesítve"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data21"
                    label="Riasztó kikapcsolva"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data22"
                    label="Riasztórendszer hiba"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data23" label="Rongálódás, kár" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data24" label="Személyi sérülés" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data25"
                    label="Terület ellenőrzése"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data26" label="Tűz észlelése" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data27"
                    label="Tűzoltóság értesítve"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data28"
                    label="Videó / Fénykép készült"
                  />
                </v-col>
                <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.kierkezes"
                    label="Időntúli kiérkezés"
                  />
                </v-col>
                <v-col v-if="data.kierkezes" cols="12" md="8" class="py-0">
                  <v-textarea
                    v-model="data.kierkezes_oka"
                    label="Időntúli kiérkezés oka"
                  />
                </v-col>
                <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
                <v-col cols="12">
                  <h3>
                    Az objektumban tartózkodott, vagy a helyszínre érkezett:
                  </h3>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.elofizeto" label="Előfizető" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.meghatalmazott"
                    label="Meghatalmazott"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.jogosult" label="Jogosult" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.egyeb" label="Egyéb" />
                </v-col>
                <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea v-model="data.megjegyzes" label="Megjegyzés" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    v-model="data.elofizeto_tapasztalatai"
                    label="Előfizető tapasztalatai"
                  />
                </v-col>
                <v-col cols="12" md="8">
                  <v-autocomplete
                    label="Járőr"
                    :items="jarorok"
                    item-value="id"
                    :filter="jarorFilter"
                    @change="setData"
                  >
                    <template v-slot:item="{ item }">
                      {{ item.id }} - {{ item.nev }} |
                      {{
                        item.szakmai_engedelyek.length > 0
                          ? item.szakmai_engedelyek[0].szam
                          : "Hiányzó vagyonőri engedély"
                      }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.id }} - {{ item.nev }} |
                      {{
                        item.szakmai_engedelyek.length > 0
                          ? item.szakmai_engedelyek[0].szam
                          : "Hiányzó vagyonőri engedély"
                      }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="data.igazolas" label="Igazoló" />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-1 mt-5 d-none d-sm-flex"
              style="align-self: flex-start;"
            >
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="success" @click="handleSubmit">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn
                color="success"
                fab
                bottom
                right
                fixed
                @click="handleSubmit"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
import isMobile from "ismobilejs";

export default {
  title: "Új jelentés",
  data() {
    return {
      mentes: false,
      hiba: false,
      data: {
        datum: this.$moment().format("YYYY-MM-DD"),
        megjegyzes: "",
        elofizeto_tapasztalatai: "",
        kierkezes_oka: "",
        select_id: 0,
        select_ceg: ""
      },
      items: [],
      successLoc: {
        longitude: 0,
        latitude: 0,
        accuracy: 0
      },
      progressLoc: {
        longitude: 0,
        latitude: 0,
        accuracy: 0
      },
      desiredAccuracy: 50,
      hasError: false,
      jarorok: []
    };
  },
  computed: {
    idopont5() {
      let seconddate = this.data.datum;
      if (this.data.idopont2 < this.data.idopont1) {
        seconddate = this.$moment(seconddate, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
      }
      return this.data.idopont1 && this.data.idopont2
        ? this.$moment(
            seconddate + " " + this.data.idopont2,
            "YYYY-MM-DD HH:mm"
          ).diff(
            this.$moment(
              this.data.datum + " " + this.data.idopont1,
              "YYYY-MM-DD HH:mm"
            ),
            "minutes"
          )
        : 0;
    },
    onMobile() {
      return isMobile(window.navigator).any;
    },
    accuracyGood() {
      return this.onMobile
        ? this.successLoc.longitude !== 0 &&
            this.successLoc.latitude !== 0 &&
            this.successLoc.accuracy <= this.desiredAccuracy
        : true;
    }
  },
  mounted() {
    this.getData();
    this.getJarorok();
    this.getPosition();
  },
  methods: {
    setData(data) {
      const jaror = this.jarorok.find(x => x.id === data);
      this.data.jaror_neve = jaror.nev;
      this.data.jaror_igszam =
        jaror.szakmai_engedelyek.length > 0
          ? jaror.szakmai_engedelyek[0].szam
          : "";
    },
    getJarorok() {
      this.$axios
        .get("jelentesek/jarorok")
        .then(response => (this.jarorok = response.data));
    },
    doChange(item) {
      const selectedItem = this.items[item - 1];
      this.data.azonosito = selectedItem.azonosito;
      this.data.ugyfel_neve = selectedItem.objektum;
      this.data.objektum_cime = `${selectedItem.iranyitoszam}, ${selectedItem.varos} ${selectedItem.utcahsz}`;
      this.data.select_ceg = selectedItem.ceg;
      this.data.select_id = selectedItem.id;
    },
    successHandler(location) {
      this.successLoc = location.coords;
    },
    errorHandler(err) {
      console.log("err", err);
      if (err) this.hasError = true;
    },
    progress(prog) {
      this.progressLoc = prog.coords;
    },
    getPosition() {
      navigator.geolocation.getAccurateCurrentPosition(
        this.successHandler,
        this.errorHandler,
        this.progress,
        {
          desiredAccuracy: this.desiredAccuracy,
          maxWait: 60000 * 60
        }
      );
    },
    getData() {
      this.$axios
        .get("jelentesek/objektumok")
        .then(response => (this.items = response.data));
    },
    handleSubmit() {
      this.$axios
        .post("jelentesek/createJelentes", {
          ...this.data,
          idopont5: this.idopont5,
          coords: this.onMobile
            ? `${this.successLoc.latitude.toFixed(
                6
              )},${this.successLoc.longitude.toFixed(6)}`
            : "0,0"
        })
        .then(response => {
          this.$router.push("/jelentesek/" + response.data.id);
        })
        .catch(() => {
          this.hiba = true;
        });
    },
    jarorFilter(item, queryText) {
      const searchText = queryText.toString().toLowerCase();
      const idText = item.id.toString().toLowerCase();
      const nevText = item.nev.toString().toLowerCase();
      const szamText =
        item.szakmai_engedelyek.length > 0
          ? item.szakmai_engedelyek[0].szam.toString().toLowerCase()
          : "";
      return (
        idText.indexOf(searchText) > -1 ||
        nevText.indexOf(searchText) > -1 ||
        szamText.indexOf(searchText) > -1
      );
    },
    customFilter(item, queryText) {
      const azonositoText = item.azonosito.toString().toLowerCase();
      const objektumText = item.objektum.toString().toLowerCase();
      const varosText = item.varos.toString().toLowerCase();
      const irszText = item.iranyitoszam.toString().toLowerCase();
      const utcaText = item.utcahsz.toString().toLowerCase();
      const cegText = item.ceg.toString().toLowerCase();
      const searchText = queryText.toString().toLowerCase();
      const cimText = irszText + " " + varosText + ", " + utcaText;
      const varosUtca = varosText + " " + utcaText;
      return (
        azonositoText.indexOf(searchText) > -1 ||
        objektumText.indexOf(searchText) > -1 ||
        cimText.indexOf(searchText) > -1 ||
        irszText.indexOf(searchText) > -1 ||
        utcaText.indexOf(searchText) > -1 ||
        varosText.indexOf(searchText) > -1 ||
        cegText.indexOf(searchText) > -1 ||
        varosUtca.indexOf(searchText) > -1
      );
    }
  }
};

navigator.geolocation.getAccurateCurrentPosition = function(
  geolocationSuccess,
  geolocationError,
  geoprogress,
  options
) {
  var lastCheckedPosition,
    locationEventCount = 0,
    watchID,
    timerID;

  options = options || {};

  var checkLocation = function(position) {
    lastCheckedPosition = position;
    locationEventCount = locationEventCount + 1;
    // We ignore the first event unless it's the only one received because some devices seem to send a cached
    // location even when maximumAge is set to zero
    if (
      position.coords.accuracy <= options.desiredAccuracy &&
      locationEventCount > 1
    ) {
      clearTimeout(timerID);
      navigator.geolocation.clearWatch(watchID);
      foundPosition(position);
    } else {
      geoprogress(position);
    }
  };

  var stopTrying = function() {
    navigator.geolocation.clearWatch(watchID);
    console.log(lastCheckedPosition);
    //foundPosition(lastCheckedPosition);
  };

  var onError = function(error) {
    clearTimeout(timerID);
    navigator.geolocation.clearWatch(watchID);
    geolocationError(error);
  };

  var foundPosition = function(position) {
    geolocationSuccess(position);
  };

  if (!options.maxWait) options.maxWait = 10000; // Default 10 seconds
  if (!options.desiredAccuracy) options.desiredAccuracy = 20; // Default 20 meters
  if (!options.timeout) options.timeout = options.maxWait; // Default to maxWait

  options.maximumAge = 0; // Force current locations only
  options.enableHighAccuracy = true; // Force high accuracy (otherwise, why are you using this function?)

  watchID = navigator.geolocation.watchPosition(
    checkLocation,
    onError,
    options
  );
  timerID = setTimeout(stopTrying, options.maxWait); // Set a timeout that will abandon the location loop
};
</script>

<style></style>
