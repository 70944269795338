<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Számlák</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="1" offset-md="6" class="py-0">
                  <v-select v-model="ev" label="Év" :items="evItems" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select v-model="honap" label="Hónap" :items="honapItems" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    disabled
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-btn
                        :block="true"
                        color="success"
                        :to="
                          `/telefonok/${item.telefonszam}/szamlak/${item.id}`
                        "
                      >
                        Adatlap
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="4" pl-1 pr-1>
                      <v-btn
                        :block="true"
                        color="info"
                        :to="`/telefonok/${item.telefonszam}`"
                      >
                        Telefon Adatlap
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn
                        :block="true"
                        color="error"
                        @click="deleteSzamla(item.id)"
                      >
                        Számla Törlése
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Számlák",
  data() {
    return {
      isLoading: true,
      filter: "",
      items: [],
      headers: [
        { text: "Azonosító", value: "id" },
        { text: "Tulajdonos Név", value: "nev" },
        { text: "Tulajdonos Szám", value: "nev_szam" },
        { text: "Év - Hónap", value: "targyho" },
        { text: "Összeg", value: "osszeg" },
        { text: "Parkolás", value: "parkolas" },
        { text: "Műveletek", value: "muveletek" }
      ],
      ev: this.$moment().format("YYYY"),
      honap: this.$moment().format("M"),
      honapItems: [
        { text: "Január", value: "1" },
        { text: "Február", value: "2" },
        { text: "Március", value: "3" },
        { text: "Április", value: "4" },
        { text: "Május", value: "5" },
        { text: "Június", value: "6" },
        { text: "Július", value: "7" },
        { text: "Augusztus", value: "8" },
        { text: "Szeptember", value: "9" },
        { text: "Október", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" }
      ],
      evItems: [
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025"
      ]
    };
  },
  watch: {
    honap: function() {
      this.getData();
    },
    ev: function() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    deleteSzamla(id) {
      this.$axios.post("telefonok/deleteSzamla", { id: id }).then(() => {
        this.getData();
      });
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios
        .get(`telefonok/szamlak?targyho=${this.ev}-${this.honap}`)
        .then(response => {
          this.items = response.data;
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="stylus"></style>
