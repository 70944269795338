<template>
  <v-col cols="12" md="3" class="py-0">
    {{ halozati }}
    <v-row>
      <v-col cols="12" class="py-0">
        <h3>{{ vkey + 1 }}. hálózati eszköz</h3>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field v-model="vhalozati.gyarto" label="Gyártó" />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field v-model="vhalozati.tipus" label="Típus" />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field v-model="vhalozati.gyariszam" label="Gyáriszám" />
      </v-col>
      <v-col cols="12">
        <v-btn color="error" text :block="true" @click="removeVHalozati(vkey)">
          {{ `${vkey + 1}.` }}<br />hálózati eszköz törlése
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["vhalozati", "vkey", "removeVHalozati"]
};
</script>
