<template>
  <v-col cols="12" class="py-0">
    <v-row v-if="!azon.deleted">
      <v-col cols="12" md="5" class="py-0">
        <v-text-field
          label="Megnevezés"
          :value="
            `${azon.diszpecser_videoUgyfelek.azonosito} | ${azon.diszpecser_videoUgyfelek.ugyfel} - ${azon.diszpecser_videoUgyfelek.objektum}`
          "
          readonly
          disabled
        />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="azon.megjegyzes" label="Megjegyzés" />
      </v-col>
      <v-col v-if="!azon.newAzonosito" cols="12" md="2" class="py-0" pt-2>
        <v-btn
          :block="true"
          color="info"
          tile
          :to="'/ugyfelek/' + azon.diszpecser_videoUgyfelek.azonosito"
          >Adatlap</v-btn
        >
      </v-col>
      <v-col
        cols="12"
        :block="true"
        :class="{
          md1: azon.newAzonosito ? false : true,
          md3: azon.newAzonosito ? true : false
        }"
        class="py-0"
        pt-2
      >
        <v-btn tile :block="true" color="error" @click="removeAzonosito(vkey)"
          >Törlés</v-btn
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["azon", "vkey", "removeAzonosito"]
};
</script>

<style></style>
