<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title>
          <h2>Jelentések</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="1" offset-md="8" class="py-0">
                  <v-select
                    v-model="torolve"
                    label="Törölve"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :custom-filter="customFilter"
              >
                <template v-slot:[`item.id`]="{ item }">
                  {{ item.id.toString().padStart(5, "0") }}
                </template>
                <template v-slot:[`item.ugyfel`]="{ item }">
                  {{ item.azonosito }} - {{ item.ugyfel_neve }} -
                  {{ item.objektum_cime }}
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        color="success"
                        :block="true"
                        :to="'/jelentesek/' + item.id"
                        >Adatlap</v-btn
                      >
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Jelentések",
  data() {
    return {
      filter: "",
      items: [],
      headers: [
        { text: "Sorszám", value: "id" },
        { text: "Ügyfél", value: "ugyfel" },
        { text: "Cég", value: "select_ceg" },
        { text: "Dátum", value: "datum" },
        { text: "Műveletek", value: "muveletek" }
      ],
      isLoading: false,
      torolve: 0,
      igenNem: [{ text: "Nem", value: 0 }, { text: "Igen", value: 1 }],
      interval: 0
    };
  },
  watch: {
    torolve() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
    this.interval = setInterval(() => this.getData(), 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    customFilter(value, search, item) {
      const searchString = search.toString().toLowerCase();
      return (
        item.id
          .toString()
          .padStart(5, "0")
          .indexOf(search.toString()) !== -1 ||
        item.azonosito
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.ugyfel_neve
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.datum
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.objektum_cime
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.select_ceg
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1
      );
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios
        .get(`jelentesek/jelentesek?torolve=${this.torolve}`)
        .then(response => {
          this.items = response.data;
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="stylus"></style>
