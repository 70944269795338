<template>
  <v-card flat
    ><v-form ref="form">
      <v-card-text>
        <v-row>
          <v-col md="4" cols="12">
            <v-row>
              <v-col cols="12" class="py-0">
                <h1 v-if="!user_data.nev" style="text-align: center;">
                  Új dolgozó adatai
                </h1>
                <h1 v-else style="text-align: center;">
                  {{ user_data.nev }} adatai
                </h1>
              </v-col>
              <v-col cols="12" class="py-0">
                <hr class="thinLine" style="margin-bottom: 10px;" />
              </v-col>
              <v-col cols="12" class="py-0" style="text-align: center;">
                <v-row>
                  <v-col cols="12" class="py-1">
                    <v-img
                      :src="getUserPicture()"
                      style="height: 200px;"
                      contain
                      @error="imageid = 0"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" offset-md="3">
                    <uploadButton ref="upButton" @kep="gotKep" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12"> <hr class="thinLine" /> </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.id"
                  label="Azonosító"
                  :disabled="true"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-autocomplete
                  v-model="user_data.aktualisceg"
                  label="Aktuális cég"
                  :items="cegek"
                  item-value="id"
                  item-text="megnevezes"
                  required
                  :rules="[v => !!v || 'Aktuális cég kötelező!']"
                  :filter="singleFilter"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-select
                  v-model="user_data.ellenorzes"
                  label="Ellenőrzés"
                  :items="igenNem"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-autocomplete
                  v-model="user_data.regio"
                  label="Régió"
                  :items="regiok"
                  item-value="azonosito"
                  item-text="megnevezes"
                  :filter="multiFilter"
                  required
                  :rules="[v => !!v || 'Régió kötelező!']"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-select
                  v-model="user_data.figyeles"
                  label="Figyelés"
                  :items="igenNem"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-autocomplete
                  v-model="user_data.objektum"
                  label="Objektum"
                  :items="objektumok"
                  item-value="id"
                  item-text="megnevezes"
                  :filter="singleFilter"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.nev"
                  label="Teljes név"
                  required
                  :rules="[v => !!v || 'Teljes név kötelező!']"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.anyjaneve"
                  label="Anyja neve"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.szulhely"
                  label="Születési hely"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.szuletesi_datum"
                  label="Születési dátum"
                  type="date"
                  max="2999-12-31"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field v-model="user_data.cim" label="Lakcím" />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.idglakcim"
                  label="Ideiglenes lakcím"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field v-model="user_data.email" label="E-mail cím" />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-autocomplete
                  v-model="user_data.vegzettseg"
                  label="Végzettség"
                  :items="vegzettsegek"
                  item-value="id"
                  item-text="megnevezes"
                  :filter="singleFilter"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field v-model="user_data.nyelv" label="Nyelvvizsga" />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.nyelvtip"
                  label="Nyelvvizsga szint"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.tel1"
                  v-mask="'##/###-####'"
                  label="Telefon 1"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.tel2"
                  v-mask="'##/###-####'"
                  label="Telefon 2"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.szukseg_nev"
                  label="Szükség esetén értesítendő neve"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.szukseg_ertek"
                  v-mask="'##/###-####'"
                  label="Szükség esetén értesítendő telefonszáma"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.user.username"
                  label="Felhasználónév"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-autocomplete
                  v-model="user_data.beosztas"
                  label="Beosztás"
                  :items="beosztasok"
                  item-value="id"
                  item-text="megnevezes"
                  :filter="singleFilter"
                  required
                  :rules="[v => !!v || 'Beosztás kötelező!']"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-autocomplete
                  v-model="user_data.fogltipus"
                  label="Foglalkoztatás típusa"
                  :items="fogltipusok"
                  item-value="id"
                  item-text="megnevezes"
                  required
                  :rules="[v => !!v || 'Foglalkoztatás típusa kötelező!']"
                  :filter="singleFilter"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.foglora"
                  label="Napi óraszám"
                  required
                  :rules="[v => !!v || 'Napi óraszám kötelező!']"
                />
              </v-col>
              <v-col md="4" cols="12" class="py-0">
                <v-text-field
                  v-model.number="user_data.napi_km"
                  label="Napi km"
                />
              </v-col>
              <v-col md="4" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.gepjarmu_tipusa"
                  label="Gépjármű típusa"
                />
              </v-col>
              <v-col md="4" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.gepjarmu_rendszam"
                  label="Gépjármű rendszáma"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.taj"
                  v-mask="'###-###-###'"
                  label="TAJ Szám"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field v-model="user_data.ado" label="Adószám" />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.belepesi_datum"
                  label="Belépés dátuma"
                  type="date"
                  max="2999-12-31"
                  required
                  :rules="[v => !!v || 'Belépés dátuma kötelező!']"
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <v-text-field
                  v-model="user_data.kilepesi_datum"
                  label="Kilépés dátuma"
                  type="date"
                  max="2999-12-31"
                />
              </v-col>
              <v-col cols="12" class="py-0">
                <v-textarea v-model="user_data.megjegyzes" label="Megjegyzés" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <v-row>
              <v-col cols="12" class="py-0">
                <h1 style="text-align: center;">Engedélyek</h1>
              </v-col>
              <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
              <add-engedely
                v-for="(value, vkey) in user_data.szakmai_engedelyek"
                :key="vkey"
                v-bind="{
                  value,
                  engedelytipusok,
                  userData: user_data,
                  delEngedely,
                  vkey,
                  canSave,
                  userRegio,
                  kulonID,
                  kulonUID
                }"
              />
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <v-row>
              <v-col cols="12" class="py-0">
                <h1 style="text-align: center;">Cégek</h1>
              </v-col>
              <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
              <add-ceg
                v-for="(value, vkey) in user_data.user_cegek"
                :key="vkey"
                v-bind="{ value, cegek, delCeg, vkey, canSave, singleFilter }"
              />
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="2"
            :class="{ fixedSave: !isMobile }"
            style="margin-top: 15px"
          >
            <v-row>
              <v-col cols="12" class="py-1">
                <v-btn :block="true" color="info" @click="$router.go(-1)">
                  Vissza
                </v-btn>
              </v-col>
              <v-col v-if="canSave" cols="12" class="py-1 my-2">
                <v-row>
                  <v-col cols="12" class="py-1">
                    <v-btn :block="true" color="success" @click="handleSubmit">
                      Mentés
                    </v-btn>
                  </v-col>
                  <v-col cols="6" md="12" class="py-1">
                    <v-btn :block="true" color="info" @click="ujEngedely">
                      Új engedély
                    </v-btn>
                  </v-col>
                  <v-col cols="6" md="12" class="py-1">
                    <v-btn :block="true" color="info" @click="ujCeg">
                      Új cég
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="d-sm-none">
            <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
              ><v-icon>arrow_left</v-icon></v-btn
            >
            <v-btn color="success" fab bottom right fixed @click="handleSubmit"
              ><v-icon>save</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-snackbar
          v-model="mentes"
          :timeout="3000"
          :bottom="true"
          :right="true"
          color="success"
        >
          Sikeres mentés!
        </v-snackbar>
        <v-snackbar
          v-model="hiba"
          :timeout="3000"
          :bottom="true"
          :right="true"
          color="error"
        >
          Hiba mentés közben!
        </v-snackbar>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import addEngedely from "./components/addEngedely.vue";
import addCeg from "./components/addCeg.vue";
import uploadButton from "@/components/uploadButton.vue";

export default {
  title: "Új dolgozó",
  components: { addEngedely, addCeg, uploadButton },
  data() {
    return {
      imageid: 0,
      user_data: {
        ellenorzes: 0,
        figyeles: 1,
        user: {},
        szakmai_engedelyek: [],
        user_cegek: [],
        megjegyzes: ""
      },
      kep: "",
      cegek: [],
      regiok: [],
      objektumok: [],
      vegzettsegek: [],
      beosztasok: [],
      fogltipusok: [],
      engedelytipusok: [],
      mentes: false,
      hiba: false,
      igenNem: [{ text: "Igen", value: 1 }, { text: "Nem", value: 0 }],
      userRegio: 0,
      kulonUID: [],
      kulonID: []
    };
  },
  computed: {
    canSave() {
      return this.$store.getters.getPrivileges.editDolgozo;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    }
  },
  watch: {
    "user_data.regio": function() {
      this.getRegio();
      this.getNewObjektumok();
    }
  },
  beforeMount() {
    if (!this.$store.getters.getPrivileges.editDolgozo) this.$router.go(-1);
    this.getMeta();
  },
  methods: {
    getUserPicture() {
      return window.location.protocol == "http:"
        ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/userimages/${this.imageid}.jpg`
        : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/userimages/${this.imageid}.jpg`;
    },
    multiFilter(item, queryText) {
      const searchText = queryText.toString().toLowerCase();
      const itemAzon = item.azonosito.toString().toLowerCase();
      const itemText = item.megnevezes.toString().toLowerCase();
      return (
        itemAzon.indexOf(searchText) > -1 || itemText.indexOf(searchText) > -1
      );
    },
    singleFilter(item, queryText) {
      const searchText = queryText.toString().toLowerCase();
      const itemText = item.megnevezes.toString().toLowerCase();
      return itemText.indexOf(searchText) > -1;
    },
    gotKep(e) {
      this.kep = e;
    },
    getMeta() {
      const cegek = this.axios.get("meta/cegek");
      const regiok = this.axios.get("meta/regiok");
      const objektumok = this.axios.get("meta/objektumok?id=0");
      const vegzettsegek = this.axios.get("meta/vegzettsegek");
      const beosztasok = this.axios.get("meta/beosztasok");
      const fogltipusok = this.axios.get("meta/fogltipusok");
      const engedelytipusok = this.axios.get("meta/engedelytipusok");
      const kulonID = this.$axios.get("meta/getKulonID");
      Promise.all([
        cegek,
        regiok,
        objektumok,
        vegzettsegek,
        beosztasok,
        fogltipusok,
        engedelytipusok,
        kulonID
      ]).then(results => {
        this.cegek = results[0].data;
        this.regiok = results[1].data;
        this.objektumok = results[2].data;
        this.vegzettsegek = results[3].data;
        this.beosztasok = results[4].data;
        this.fogltipusok = results[5].data;
        this.engedelytipusok = results[6].data;
        for (let item of results[7].data) {
          if (item.tipus == 1) this.kulonUID.push(item.azonosito);
          else if (item.tipus == 2) this.kulonID.push(item.azonosito);
        }
      });
    },
    getNewObjektumok() {
      this.$axios
        .get(`meta/objektumok?id=${this.user_data.regio}`)
        .then(response => {
          this.objektumok = response.data[0]
            ? response.data
            : [{ megnevezes: "Nincs megadva", id: 0 }];
          this.user_data.objektum = this.objektumok[0].id;
        });
    },
    getRegio() {
      this.$axios
        .get(`meta/regioAdat?id=${this.user_data.regio}`)
        .then(response => (this.userRegio = response.data.azonosito));
    },
    ujEngedely() {
      this.user_data.szakmai_engedelyek.push({
        engedely_tipus: {
          megnevezes: "Új engedély"
        },
        hatosag: "",
        jelzett: 0,
        lejarat: "0000-00-00",
        szam: "",
        tipus: 0,
        isNew: true
      });
    },
    ujCeg() {
      this.user_data.user_cegek.push({
        ceg: 0,
        cegbe: "",
        cegki: "",
        id: 0,
        isNew: true
      });
    },
    delCeg(key) {
      this.user_data.user_cegek.splice(key, 1);
    },
    delEngedely(key) {
      this.user_data.szakmai_engedelyek.splice(key, 1);
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        let userData = this.user_data;
        userData.userRegio = this.userRegio;
        this.$axios
          .post("dolgozok/createUser", userData)
          .then(response => {
            let formData = new FormData();
            formData.append("file", this.kep);
            formData.append("id", response.data.id);
            this.$axios
              .post("dolgozok/profilePictureUpload", formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              })
              .then(() => {
                this.mentes = true;
                this.$router.push({
                  path: `/dolgozok/${response.data.id}`
                });
              })
              .catch(() => {
                this.hiba = true;
              });
          })
          .catch(() => {
            this.hiba = true;
          });
      } else this.hiba = true;
    }
  }
};
</script>

<style lang="stylus" scoped>
.fixedSave {
  position: fixed;
  right: 0;
  width: 280px;
  top: 50px;
}
</style>
