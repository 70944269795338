import AutokIndex from "@/pages/autok/index";
import AutokID from "@/pages/autok/_id";
import AutokNewAuto from "@/pages/autok/newAuto";
import AutokTankolasokIndex from "@/pages/autok/tankolasok";
import AutokIDTankolasok from "@/pages/autok/_id/tankolasok/index";
import AutokIDTankolasokID from "@/pages/autok/_id/tankolasok/_azon";
import AutokIdNewTankolas from "@/pages/autok/_id/tankolasok/newTankolas";
import AutokNewTankolas from "@/pages/autok/newTankolas";
import AutokNewMenetlevel from "@/pages/autok/newMenetlevel";
import AutokIDSzervizek from "@/pages/autok/_id/szervizek/index";
import AutokIDNewSzerviz from "@/pages/autok/_id/szervizek/newSzerviz";
import AutokIDSzervizekID from "@/pages/autok/_id/szervizek/_azon";
import AutokIDMenetlevelek from "@/pages/autok/_id/menetlevelek/index";
import AutokIDNewMenetlevel from "@/pages/autok/_id/menetlevelek/newMenetlevel";
import AutokIDMenetlevelekID from "@/pages/autok/_id/menetlevelek/_azon";

export default [
  {
    name: "autok-index",
    path: "autok",
    component: AutokIndex
  },
  {
    name: "autok-newAuto",
    path: "autok/newAuto",
    component: AutokNewAuto
  },
  {
    name: "autok-tankolasok-index",
    path: "autok/tankolasok",
    component: AutokTankolasokIndex
  },
  {
    name: "autok-ujTankolas",
    path: "autok/newTankolas",
    component: AutokNewTankolas
  },
  {
    name: "autok-ujMenetlevel",
    path: "autok/newMenetlevel",
    component: AutokNewMenetlevel
  },
  {
    name: "autok-id",
    path: "autok/:id",
    component: AutokID
  },
  {
    name: "autok-id-tankolasok",
    path: "autok/:id/tankolasok",
    component: AutokIDTankolasok
  },
  {
    name: "autok-id-newTankolas",
    path: "autok/:id/tankolasok/newTankolas",
    component: AutokIdNewTankolas
  },
  {
    name: "autok-tankolasok-id",
    path: "autok/:id/tankolasok/:azon",
    component: AutokIDTankolasokID
  },
  {
    name: "autok-id-szervizek",
    path: "autok/:id/szervizek",
    component: AutokIDSzervizek
  },
  {
    name: "autok-id-newSzerviz",
    path: "autok/:id/szervizek/newSzerviz",
    component: AutokIDNewSzerviz
  },
  {
    name: "autok-szervizek-id",
    path: "autok/:id/szervizek/:azon",
    component: AutokIDSzervizekID
  },
  {
    name: "autok-id-menetlevelek",
    path: "autok/:id/menetlevelek",
    component: AutokIDMenetlevelek
  },
  {
    name: "autok-id-newMenetlevel",
    path: "autok/:id/menetlevelek/newMenetlevel",
    component: AutokIDNewMenetlevel
  },
  {
    name: "autok-menetlevelek-id",
    path: "autok/:id/menetlevelek/:azon",
    component: AutokIDMenetlevelekID
  }
];
