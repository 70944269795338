var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{class:{ fixedSave: !_vm.isMobile },staticStyle:{"margin-top":"15px"},attrs:{"cols":"12","md":"2"}},[_c('v-row',[_c('v-col',{staticClass:"px-0 py-1 d-none d-sm-flex",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":true,"color":"info"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Vissza ")])],1),_c('v-col',{staticClass:"px-0 py-1 d-none d-sm-flex",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":true,"color":"success"},on:{"click":_vm.handleSubmit}},[_vm._v(" Mentés ")])],1),(!_vm.newUgyfel)?_c('v-col',{staticClass:"px-0 py-1",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"warning","block":true,"to":`/munkalapok/newMunkalap`}},[_vm._v(" Új munkalap ")])],1):_vm._e(),(!_vm.newUgyfel)?_c('v-col',{staticClass:"px-0 py-1",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"warning","block":true,"to":`/karbantartasok/${_vm.azonosito}/newKarbantartas`}},[_vm._v(" Új karbantartás ")])],1):_vm._e(),(!_vm.newUgyfel)?_c('v-col',{staticClass:"px-0 py-1",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"warning","block":true,"to":`/hibak/${_vm.azonosito}/newHiba`}},[_vm._v(" Új hiba ")])],1):_vm._e(),(!_vm.newUgyfel)?_c('v-col',{staticClass:"px-0 py-1",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":true,"to":`/karbantartasok/${_vm.azonosito}`}},[_vm._v(" Karbantartások ")])],1):_vm._e(),(!_vm.newUgyfel)?_c('v-col',{staticClass:"px-0 py-1",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":true,"to":`/hibak/${_vm.azonosito}`}},[_vm._v(" Hibák ")])],1):_vm._e(),(_vm.rendszerTipus === 1)?_c('v-col',{staticClass:"pa-0 my-3",attrs:{"cols":"12"}},[_c('riaszto-buttons',_vm._b({},'riaszto-buttons',{
            nyomtatPdf: _vm.nyomtatPdf,
            nyomtatUres: _vm.nyomtatUres,
            ujZonaRiaszto: _vm.ujZonaRiaszto,
            ujErtesitendoRiaszto: _vm.ujErtesitendoRiaszto,
            ujKezeloRiaszto: _vm.ujKezeloRiaszto,
            ujBovitoRiaszto: _vm.ujBovitoRiaszto,
            newUgyfel: _vm.newUgyfel
          },false))],1):(_vm.rendszerTipus === 2)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('video-buttons',_vm._b({},'video-buttons',{
            nyomtatPdf: _vm.nyomtatPdf,
            nyomtatUres: _vm.nyomtatUres,
            ujNetworking: _vm.ujNetworking,
            ujKamera: _vm.ujKamera,
            ujNano: _vm.ujNano,
            ujTartomany: _vm.ujTartomany,
            newUgyfel: _vm.newUgyfel,
            ujVHalozati: _vm.ujVHalozati
          },false))],1):(_vm.rendszerTipus === 3)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('belepteto-buttons',_vm._b({},'belepteto-buttons',{
            nyomtatPdf: _vm.nyomtatPdf,
            nyomtatUres: _vm.nyomtatUres,
            ujCsatolo: _vm.ujCsatolo,
            ujBovito: _vm.ujBovito,
            newUgyfel: _vm.newUgyfel,
            ujOlvaso: _vm.ujOlvaso
          },false))],1):(_vm.rendszerTipus === 4)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('tuzjelzo-buttons',_vm._b({},'tuzjelzo-buttons',{
            nyomtatPdf: _vm.nyomtatPdf,
            nyomtatUres: _vm.nyomtatUres,
            ujErtesitendoTuzjelzo: _vm.ujErtesitendoTuzjelzo,
            newUgyfel: _vm.newUgyfel
          },false))],1):(_vm.rendszerTipus === 5)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('diszpecser-buttons',_vm._b({},'diszpecser-buttons',{
            ujGepDiszpecser: _vm.ujGepDiszpecser,
            newUgyfel: _vm.newUgyfel
          },false))],1):_vm._e(),(_vm.rendszerTipus !== 0 && _vm.rendszerTipus !== 3)?_c('v-col',{staticClass:"px-0 py-1",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"warning","block":true},on:{"click":_vm.ujHalozati}},[_vm._v(" Új V.N. Hálózati Eszköz ")])],1):_vm._e(),(_vm.rendszerTipus !== 0)?_c('v-col',{staticClass:"px-0 py-1",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"warning","block":true},on:{"click":_vm.ujFile}},[_vm._v(" Új fájl ")])],1):_vm._e()],1)],1),_c('v-col',{staticClass:"d-sm-none"},[_c('v-btn',{attrs:{"color":"info","fab":"","bottom":"","left":"","fixed":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("arrow_left")])],1),_c('v-btn',{attrs:{"color":"success","fab":"","bottom":"","right":"","fixed":""},on:{"click":_vm.handleSubmit}},[_c('v-icon',[_vm._v("save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }