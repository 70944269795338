<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Megkezdetlen GPS Hibajavítások</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="3" offset-md="9" class="py-0">
                  <v-text-field
                    v-model="filterMegkezdetlen"
                    append-icon="search"
                    label="Keresés"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="itemsMegkezdetlen"
                :headers="headersMegkezdetlen"
                :search="filterMegkezdetlen"
                :loading="isLoadingMegkezdetlen"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.leiras`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.leiras.rovid }}</span>
                    </template>
                    <span>{{ item.leiras.hosszu }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-btn
                        :block="true"
                        :to="`/gps/${item.sorszam}`"
                        color="success"
                      >
                        Adatlap
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" pl-1>
                      <v-btn
                        :block="true"
                        color="warning"
                        @click="nyomtatGps(item.sorszam)"
                      >
                        Nyomtatás
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Megkezdetlen GPS Hibajavítások",
  data() {
    return {
      isLoadingMegkezdetlen: true,
      filterMegkezdetlen: "",
      itemsMegkezdetlen: [],
      headersMegkezdetlen: [
        { text: "Sorszám", value: "sorszam" },
        { text: "GPS eszköz", value: "eszkoz" },
        { text: "Bejelentés dátuma", value: "bejelentes_datuma" },
        { text: "Bejelentő", value: "bejelento" },
        { text: "Hiba leírása", value: "leiras" },
        { text: "Műveletek", value: "muveletek" }
      ]
    };
  },
  beforeMount() {
    this.getMegkezdetlenData();
  },
  methods: {
    nyomtatGps(sorszam) {
      this.$axios
        .post("/pdf/gpshiba", { id: sorszam })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    getMegkezdetlenData() {
      this.itemsMegkezdetlen = [];
      this.isLoadingMegkezdetlen = true;
      this.$axios.get("bizttech/megkezdetlenGpsHibak").then(response => {
        let items = [];
        let index = 0;
        for (let item of response.data) {
          items.push({
            sorszam: item.sorszam,
            eszkoz: item.eszkoz,
            bejelentes_datuma: item.bejelentes_datuma,
            bejelento: item.bejelento,
            leiras: {}
          });
          if (item.hiba.length > 45)
            items[index].leiras = {
              rovid: item.hiba.substr(0, 45) + "...",
              hosszu: item.hiba
            };
          else items[index].leiras = { rovid: item.hiba, hosszu: item.hiba };
          index++;
        }
        this.itemsMegkezdetlen = items;
        this.isLoadingMegkezdetlen = false;
      });
    }
  }
};
</script>

<style></style>
