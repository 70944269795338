<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-card flat>
        <v-card-title>
          <h2>Jelszóváltoztatás</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="oldPassword"
                label="Jelenlegi jelszó"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'visibility' : 'visibility_off'"
                @click:append="show1 = !show1"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="newPassword"
                label="Új jelszó"
                :type="show2 ? 'text' : 'password'"
                :append-icon="show2 ? 'visibility' : 'visibility_off'"
                @click:append="show2 = !show2"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="newPasswordAgain"
                label="Új jelszó újra"
                :type="show3 ? 'text' : 'password'"
                :append-icon="show3 ? 'visibility' : 'visibility_off'"
                @click:append="show3 = !show3"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-alert
                type="success"
                :value="passwordsMatch"
                transition="fade-transition"
                outlined
              >
                Az új jelszavak megegyeznek
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            :disabled="!passwordsMatch"
            @click="updatePassword"
            >Mentés</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Jelszóváltoztatás",
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordAgain: "",
      hiba: false,
      show1: false,
      show2: false,
      show3: false
    };
  },
  computed: {
    passwordsMatch() {
      return (
        this.newPassword === this.newPasswordAgain &&
        (this.newPassword !== "" || this.newPasswordAgain !== "")
      );
    }
  },
  methods: {
    updatePassword() {
      if (
        this.newPassword === this.newPasswordAgain &&
        this.oldPassword !== this.newPassword &&
        this.newPassword !== this.$store.getters.getUser.username
      ) {
        this.$axios
          .post("auth/updatePassword", {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword
          })
          .then(() => {
            this.$store
              .dispatch("doLogout")
              .then(() => {
                this.$router.push({
                  path: "/login"
                });
              })
              .catch(err => {
                this.hiba = true;
                console.log(err);
              });
          });
      } else this.hiba = true;
    }
  }
};
</script>

<style lang="stylus"></style>
