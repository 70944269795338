import MunkalapokIndex from "@/pages/munkalapok/index";
import MunkalapokID from "@/pages/munkalapok/_id";
import MunkalapokNewMunkalap from "@/pages/munkalapok/newMunkalap";

export default [
  {
    name: "munkalapok-index",
    path: "munkalapok",
    component: MunkalapokIndex
  },
  {
    name: "munkalapok-newMunkalap",
    path: "munkalapok/newMunkalap",
    component: MunkalapokNewMunkalap
  },
  {
    name: "munkalapok-id",
    path: "munkalapok/:id",
    component: MunkalapokID
  }
];
