import DolgozokIndex from "@/pages/dolgozok/index";
import DolgozokLejart from "@/pages/dolgozok/lejart";
import DolgozokLejaro from "@/pages/dolgozok/lejaro";
import DolgozokKibelepok from "@/pages/dolgozok/kibelepok";
import DolgozokNewDolgozo from "@/pages/dolgozok/newDolgozo";
import DolgozokID from "@/pages/dolgozok/_id";
import DolgozokRegiok from "@/pages/dolgozok/regiok";
import DolgozokJogok from "@/pages/dolgozok/dolgozoJogok";

export default [
  {
    name: "dolgozok-index",
    path: "dolgozok",
    component: DolgozokIndex
  },
  {
    name: "dolgozok-lejart",
    path: "dolgozok/lejart",
    component: DolgozokLejart
  },
  {
    name: "dolgozok-lejaro",
    path: "dolgozok/lejaro",
    component: DolgozokLejaro
  },
  {
    name: "dolgozok-kibelepok",
    path: "dolgozok/kibelepok",
    component: DolgozokKibelepok
  },
  {
    name: "dolgozok-newDolgozo",
    path: "dolgozok/newDolgozo",
    component: DolgozokNewDolgozo
  },
  {
    name: "dolgozok-regiok",
    path: "dolgozok/regiok",
    component: DolgozokRegiok
  },
  {
    name: "dolgozok-dolgozojogok",
    path: "dolgozok/dolgozoJogok",
    component: DolgozokJogok
  },
  {
    name: "dolgozok-id",
    path: "dolgozok/:id",
    component: DolgozokID
  }
];
