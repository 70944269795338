<template>
  <v-col cols="12" md="4" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0"
        ><h3>{{ vkey + 1 }}. vezérlő</h3></v-col
      >
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="bovito.nev" label="Név" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="bovito.cim" label="Címzés" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="bovito.gyarto" label="Gyártó" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="bovito.tipus" label="Tipus" />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field v-model="bovito.sorozatszam" label="Sorozatszám" />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-textarea v-model="bovito.megjegyzes" label="Megjegyzés" />
      </v-col>
      <v-col cols="12">
        <v-btn color="error" text :block="true" @click="removeBovito(vkey)">{{
          `${vkey + 1}. vezérlő törlése`
        }}</v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["bovito", "vkey", "removeBovito"]
};
</script>
