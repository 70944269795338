<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Cég engedélyek</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="3" offset-md="9" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="(item, key) in items"
                      :key="key"
                      :class="
                        item.rowType === 2
                          ? 'danger'
                          : item.rowType === 1
                          ? 'warn'
                          : ''
                      "
                    >
                      <td>{{ item.id }}</td>
                      <td>{{ item.tipus }}</td>
                      <td>{{ item.ceg }}</td>
                      <td>{{ item.lejarati_datum }}</td>
                      <td>
                        <v-btn
                          disabled
                          :block="true"
                          :to="`/ceginfo/engedelyek/${item.id}`"
                          color="success"
                        >
                          Adatlap
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Cég engedélyek",
  data() {
    return {
      isLoading: true,
      ceg: 0,
      filter: "",
      items: [],
      headers: [
        { text: "Azonosító", value: "id" },
        { text: "Típus", value: "tipus" },
        { text: "Cég", value: "ceg" },
        { text: "Lejárati dátum", value: "lejarati_datum" },
        { text: "Műveletek", value: "muveletek" }
      ],
      tipusok: [
        { id: 0, nev: "Nincs megadva" },
        { id: 1, nev: "BON-SEC" },
        { id: 2, nev: "Pronob" }
      ]
    };
  },
  watch: {
    kilepett: function() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios.get(`ceginfo/engedelyek?ceg=${this.ceg}`).then(response => {
        let data = [];
        response.data.forEach((item, index) => {
          data.push({
            id: item.id,
            tipus:
              item.tipus == 1 ? "Működési engedély" : "Fegyvertartási engedély",
            ceg: item.ceg == 1 ? "BON-SEC" : "Pronob",
            lejarati_datum: item.lejarati_datum,
            rowType: 0
          });
          if (item.lejarati_datum < this.$moment().format("YYYY-MM-DD"))
            data[index].rowType = 2;
          else if (
            item.lejarati_datum > this.$moment().format("YYYY-MM-DD") &&
            item.lejarati_datum <
              this.$moment()
                .add(2, "months")
                .format("YYYY-MM-DD")
          )
            data[index].rowType = 1;
        });
        this.items = data.sort((a, b) => b.rowType - a.rowType);
        this.isLoading = false;
      });
    }
  }
};
</script>

<style lang="stylus"></style>
