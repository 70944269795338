<template>
  <v-col cols="12" md="6" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <h3>{{ parseInt(vkey + 1) }}. számítógép</h3>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="szamitogep.processzor" label="Processzor" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="szamitogep.videokartya" label="Videókártya" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="szamitogep.memoria" label="Memória" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="szamitogep.tarhely" label="Tárhely" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="szamitogep.monitorok" label="Monitorok száma" />
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" style="text-align: center;">
            <h3>Felhasználók</h3>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="success"
              :block="true"
              text
              @click="ujSzamitogepUser(vkey)"
              >Új Felhasználó</v-btn
            >
          </v-col>
          <diszpecser-szamitogep-felhasznalo
            v-for="(felhasznalo, key) in szamitogep.felhasznalok"
            :key="key"
            v-bind="{
              felhasznalo,
              vkey,
              fkey: key,
              removeSzamitogepUser
            }"
          />
        </v-row>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-textarea v-model="szamitogep.megjegyzes" label="Megjegyzés" />
      </v-col>
      <v-col cols="12">
        <v-btn
          color="error"
          text
          :block="true"
          @click="removeSzamitogep(vkey)"
          >{{ `${vkey + 1}. számítógép törlése` }}</v-btn
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import diszpecserSzamitogepFelhasznalo from "./diszpecserSzamitogepFelhasznalo";

export default {
  components: {
    diszpecserSzamitogepFelhasznalo
  },
  props: [
    "szamitogep",
    "vkey",
    "removeSzamitogep",
    "ujSzamitogepUser",
    "removeSzamitogepUser"
  ]
};
</script>
