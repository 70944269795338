import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import configureStore from "./store";
import titleMixin from "./plugins/titleMixin";
import moment from "moment";
import { VueMaskDirective } from "v-mask";
require("moment/locale/hu");
import VueSignaturePad from "vue-signature-pad";

Vue.use(VueSignaturePad);

Vue.config.productionTip = false;
Vue.mixin(titleMixin);
Vue.use(require("vue-moment"), {
  moment
});
Vue.directive("mask", VueMaskDirective);

new Vue({
  router,
  vuetify,
  store: configureStore(),
  render: h => h(App)
}).$mount("#app");
