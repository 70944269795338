import CegFegyverLejaratIndex from "@/pages/ceginfo/fegyverlejarat/index";
import CegFegyverLejaratID from "@/pages/ceginfo/fegyverlejarat/_id";
import CegFegyverLejaratNewFegyver from "@/pages/ceginfo/fegyverlejarat/newFegyver";
import CegEngedelyekIndex from "@/pages/ceginfo/engedelyek/index";

export default [
  {
    name: "ceginfo-fegyverlejarat-index",
    path: "ceginfo/fegyverlejarat",
    component: CegFegyverLejaratIndex
  },
  {
    name: "ceginfo-fegyverlejarat-newFegyver",
    path: "ceginfo/fegyverlejarat/newFegyver",
    component: CegFegyverLejaratNewFegyver
  },
  {
    name: "ceginfo-fegyverlejarat-id",
    path: "ceginfo/fegyverlejarat/:id",
    component: CegFegyverLejaratID
  },
  {
    name: "ceginfo-engedelyek-index",
    path: "ceginfo/engedelyek",
    component: CegEngedelyekIndex
  }
];
