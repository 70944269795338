import UgyfelekIndex from "@/pages/ugyfelek/index";
import UgyfelekNewUgyfel from "@/pages/ugyfelek/newUgyfel";
import UgyfelekID from "@/pages/ugyfelek/_id";
import UgyfelekPrintUgyfelek from "@/pages/ugyfelek/PrintUgyfelek";

export default [
  {
    name: "ugyfelek-index",
    path: "ugyfelek",
    component: UgyfelekIndex
  },
  {
    name: "ugyfelek-newUgyfel",
    path: "ugyfelek/newUgyfel",
    component: UgyfelekNewUgyfel
  },
  {
    name: "ugyfelek-printUgyfelek",
    path: "ugyfelek/export",
    component: UgyfelekPrintUgyfelek
  },
  {
    name: "ugyfelek-id",
    path: "ugyfelek/:id",
    component: UgyfelekID
  }
];
