import moment from "moment";

export const SAVE_DOLGOZOK = "SAVE_DOLGOZOK";
export const SAVE_DOLGOZOINFO = "SAVE_DOLGOZOINFO";
export const SAVE_KIBELEPOK = "SAVE_KIBELEPOK";
export const SAVE_UGYFELEK = "SAVE_UGYFELEK";
export const SAVE_AUTOK = "SAVE_AUTOK";
export const SAVE_TANKOLASOK = "SAVE_TANKOLASOK";
export const SAVE_SZERVIZ = "SAVE_SZERVIZ";
export const SAVE_TANKOLAS = "SAVE_TANKOLAS";
export const SAVE_MENETLEVEL = "SAVE_MENETLEVEL";
export const SAVE_TELEFONOK = "SAVE_TELEFONOK";
export const LOGOUT = "LOGOUT";

const state = {
  dolgozok: {
    kereses: {
      kilepett: false,
      filter: "",
      moreFilter: {
        cegFilter: 0,
        beosztasFilter: 0,
        regioFilter: 0,
        objektumFilter: 0
      }
    },
    info: {
      lejartTipus: "",
      lejartFilter: "",
      lejaroTipus: "",
      lejaroFilter: ""
    },
    kibelepok: {
      kiEv: moment().format("YYYY"),
      kiHonap: moment().format("MM"),
      kiFilter: "",
      beEv: moment().format("YYYY"),
      beHonap: moment().format("MM"),
      beFilter: ""
    }
  },
  ugyfelek: {
    type: 0,
    filter: ""
  },
  autok: {
    kereses: {
      deleted: 0,
      filter: ""
    },
    tankolasok: {
      filter: "",
      ev: moment().format("YYYY"),
      honap: moment().format("MM")
    },
    szerviz: "",
    tankolas: "",
    menetlevel: ""
  },
  telefonok: {
    kereses: {
      filter: "",
      deleted: 0,
      ceg: 0,
      szolgaltato: 0
    }
  }
};

const defaultState = { ...state };

const actions = {
  saveDolgozok({ commit }, saveInfo) {
    return commit(SAVE_DOLGOZOK, saveInfo);
  },
  saveDolgozoInfo({ commit }, saveInfo) {
    return commit(SAVE_DOLGOZOINFO, saveInfo);
  },
  saveKibelepok({ commit }, saveInfo) {
    return commit(SAVE_KIBELEPOK, saveInfo);
  },
  saveUgyfelek({ commit }, saveInfo) {
    return commit(SAVE_UGYFELEK, saveInfo);
  },
  saveAutok({ commit }, saveInfo) {
    return commit(SAVE_AUTOK, saveInfo);
  },
  saveTankolasok({ commit }, saveInfo) {
    return commit(SAVE_TANKOLASOK, saveInfo);
  },
  saveSzerviz({ commit }, saveInfo) {
    return commit(SAVE_SZERVIZ, saveInfo);
  },
  saveTankolas({ commit }, saveInfo) {
    return commit(SAVE_TANKOLAS, saveInfo);
  },
  saveMenetlevel({ commit }, saveInfo) {
    return commit(SAVE_MENETLEVEL, saveInfo);
  },
  saveTelefonok({ commit }, saveInfo) {
    return commit(SAVE_TELEFONOK, saveInfo);
  },
  doLogout({ commit }) {
    return commit(LOGOUT);
  }
};

const mutations = {
  [SAVE_DOLGOZOK](state, data) {
    state.dolgozok.kereses = { ...data };
  },
  [SAVE_DOLGOZOINFO](state, data) {
    state.dolgozok.info = { ...data };
  },
  [SAVE_KIBELEPOK](state, data) {
    state.dolgozok.kibelepok = { ...data };
  },
  [SAVE_UGYFELEK](state, data) {
    state.ugyfelek = { ...data };
  },
  [SAVE_AUTOK](state, data) {
    state.autok.kereses = { ...data };
  },
  [SAVE_TANKOLASOK](state, data) {
    state.autok.tankolasok = { ...data };
  },
  [SAVE_SZERVIZ](state, data) {
    state.autok.szerviz = data;
  },
  [SAVE_TANKOLAS](state, data) {
    state.autok.tankolas = data;
  },
  [SAVE_MENETLEVEL](state, data) {
    state.autok.menetlevel = data;
  },
  [SAVE_TELEFONOK](state, data) {
    state.telefonok.kereses = data;
  },
  [LOGOUT](state) {
    state.dolgozok = defaultState.dolgozok;
    state.ugyfelek = defaultState.ugyfelek;
    state.autok = defaultState.autok;
    state.telefonok = defaultState.telefonok;
  }
};

const getters = {
  getDolgozok: state => {
    return state.dolgozok.kereses;
  },
  getDolgozokInfo: state => {
    return state.dolgozok.info;
  },
  getDolgozokKibelepok: state => {
    return state.dolgozok.kibelepok;
  },
  getUgyfelek: state => {
    return state.ugyfelek;
  },
  getAutok: state => {
    return state.autok.kereses;
  },
  getTankolasok: state => {
    return state.autok.tankolasok;
  },
  getSzerviz: state => {
    return state.autok.szerviz;
  },
  getTankolas: state => {
    return state.autok.tankolas;
  },
  getMenetlevel: state => {
    return state.autok.menetlevel;
  },
  getTelefonok: state => {
    return state.telefonok.kereses;
  },
  isMobile: () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
};

export default {
  //namespaced: true,
  state,
  mutations,
  actions,
  getters
};
