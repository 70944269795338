<template>
  <v-col cols="12" md="4" class="py-0">
    <v-row>
      <v-col cols="12" md="3" class="py-0">
        <v-text-field v-model="zona.zonaszam" label="Zóna száma" />
      </v-col>
      <v-col cols="12" md="9" class="py-0">
        <v-text-field v-model="zona.zonanev" label="Zóna neve" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="zona.tipus" label="Tipus" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="zona.gyariszam" label="Gyáriszám" />
      </v-col>
      <v-col cols="12">
        <v-btn color="error" :block="true" text @click="removeZonaRiaszto(vkey)"
          >Törlés</v-btn
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["zona", "vkey", "removeZonaRiaszto"]
};
</script>
