<template>
  <v-col cols="12" md="6" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <h3>{{ parseInt(vkey) + 1 }}. hálózati eszköz</h3>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="item.eszkoz" label="Eszköz" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="item.tipus" label="Tipus" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="item.sorozatszam" label="Sorozatszám" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="item.sim" label="SIM" />
      </v-col>
      <v-col cols="12" md="2" class="py-0">
        <v-text-field v-model="item.pin" label="PIN" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="item.ssid" label="SSID" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="item.ssidpass"
          label="Wifi jelszó"
          :append-icon="
            item.ssidpassshow
              ? 'mdi-checkbox-outline'
              : 'mdi-checkbox-blank-outline'
          "
          @click:append="item.ssidpassshow = !item.ssidpassshow"
        />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="item.service" label="Domain" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="item.ip" label="IP" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="item.port" label="Port" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="item.user" label="Felhasználónév" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="item.pass"
          label="Jelszó"
          :append-icon="
            item.passshow
              ? 'mdi-checkbox-outline'
              : 'mdi-checkbox-blank-outline'
          "
          @click:append="item.passshow = !item.passshow"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-textarea v-model="item.megjegyzes" label="Megjegyzés" />
      </v-col>
      <v-col cols="12">
        <v-btn color="error" text :block="true" @click="removeHalozati(vkey)"
          >Törlés</v-btn
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["item", "vkey", "removeHalozati"]
};
</script>
