<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">Új rendelés</h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="5">
                  <v-row>
                    <v-col cols="12" md="7" class="py-0">
                      <v-text-field
                        v-model="rendeles.megnevezes"
                        label="Megnevezés"
                      />
                    </v-col>
                    <v-col cols="12" md="5" class="py-0">
                      <v-text-field
                        v-model="rendeles.rendeles_datuma"
                        label="Rendelés dátuma"
                        type="date"
                        max="2999-12-31"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="7">
                  <v-row>
                    <v-col
                      v-for="(ruha, key) in rendeles_ruhak"
                      :key="key"
                      cols="12"
                    >
                      <v-row>
                        <v-col cols="12" md="2" class="py-0">
                          <v-text-field
                            :ref="'vonalkod' + key"
                            v-model="ruha.barcode"
                            label="Vonalkód"
                            autofocus
                            @keyup.enter="setId(key)"
                            @focus="selectField('vonalkod' + key)"
                          />
                        </v-col>
                        <v-col cols="12" md="7" class="py-0">
                          <v-autocomplete
                            v-model="ruha.ruha_id"
                            :items="items"
                            item-value="id"
                            :filter="customFilter"
                            @change="setBarcode({ key, $event })"
                          >
                            <template slot="selection" slot-scope="data">
                              {{ data.item.barcode }} -
                              {{ data.item.ruha_nev }} -
                              {{ data.item.ruha_meret }}
                            </template>
                            <template slot="item" slot-scope="data">
                              {{ data.item.barcode }} -
                              {{ data.item.ruha_nev }} -
                              {{ data.item.ruha_meret }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="2" class="py-0">
                          <v-text-field
                            v-model="ruha.rendeles_darab"
                            label="Mennyiség"
                            suffix="db"
                            required
                          />
                        </v-col>
                        <v-col cols="12" md="1">
                          <v-icon
                            style="color: red!important; margin-top: 15px;"
                            @click="deleteItem(key)"
                          >
                            delete
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="2" offset-md="9" class="py-0">
                          <v-text-field
                            label="Összesen"
                            :value="sumOsszeg()"
                            :disabled="true"
                            suffix="Ft"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="py-0 mt-3">
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn color="primary" :block="true" @click="newRuha">
                    Új ruha
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn color="info" :block="true" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn color="success" :block="true" @click="sendCreate">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn color="success" fab bottom right fixed @click="sendCreate"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Új rendelés",
  data() {
    return {
      rendeles: {
        rendeles_datuma: this.$moment().format("YYYY-MM-DD"),
        raktaron: 0
      },
      rendeles_ruhak: [],
      items: [],
      mentes: false,
      hiba: false
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    selectField(field) {
      this.$refs[
        field
      ][0].$el.children[0].children[0].children[0].children[1].select();
    },
    customFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      const ruhaText = item.ruha_nev.toLowerCase();
      const ruhaBarcode = item.barcode.toLowerCase();
      return (
        ruhaText.indexOf(searchText) > -1 ||
        ruhaBarcode.indexOf(searchText) > -1
      );
    },
    sendCreate() {
      this.$axios
        .post("ruhak/createRendeles", {
          ...this.rendeles,
          rendeles_ruhak: this.rendeles_ruhak.filter(
            x => parseInt(x.rendeles_darab) !== 0
          )
        })
        .then(response => {
          this.$router.push("/ruhak/rendelesek");
        });
    },
    newRuha() {
      const def = {
        barcode: this.items[0].barcode,
        ruha_id: this.items[0].id,
        brutto_ar: this.items[0].brutto_ar,
        rendeles_darab: 0
      };
      this.rendeles_ruhak.push(def);
    },
    setBarcode(data) {
      this.rendeles_ruhak[data.key].barcode = data.$event
        .toString()
        .padStart(4, "0");
      let ruhaAr = searchObject(this.items, "id", data.$event).brutto_ar;
      this.rendeles_ruhak[data.key].brutto_ar = ruhaAr;
    },
    setId(key) {
      this.rendeles_ruhak[key].ruha_id = parseInt(
        this.rendeles_ruhak[key].barcode
      );
      let ruhaAr = searchObject(
        this.items,
        "barcode",
        this.rendeles_ruhak[key].barcode
      ).brutto_ar;
      this.rendeles_ruhak[key].brutto_ar = ruhaAr;
    },
    sumOsszeg() {
      let sum = 0;
      this.rendeles_ruhak.forEach(
        val => (sum += val.brutto_ar * val.rendeles_darab)
      );
      return sum;
    },
    deleteItem(key) {
      this.rendeles_ruhak.splice(key, 1);
    },
    getData() {
      this.axios
        .get("ruhak?deleted=0")
        .then(response => (this.items = response.data));
    }
  }
};

function searchObject(myArray, prop, nameKey) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i][prop] === nameKey) {
      return myArray[i];
    }
  }
}
</script>

<style></style>
