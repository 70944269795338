<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Kész GPS Hibajavítások</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="3" offset-md="9" class="py-0">
                  <v-text-field
                    v-model="filter"
                    append-icon="search"
                    label="Keresés"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :search="filter"
                :loading="isLoading"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.leiras`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.leiras.rovid }}</span>
                    </template>
                    <span>{{ item.leiras.hosszu }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-btn
                        :block="true"
                        :to="`/gps/${item.sorszam}`"
                        color="success"
                      >
                        Adatlap
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" pl-1>
                      <v-btn
                        :block="true"
                        color="warning"
                        @click="nyomtatGps(item.sorszam)"
                      >
                        Nyomtatás
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Elvégzett GPS Hibajavítások",
  data() {
    return {
      isLoading: true,
      filter: "",
      items: [],
      headers: [
        { text: "Sorszám", value: "sorszam" },
        { text: "GPS eszköz", value: "eszkoz" },
        { text: "Hiba leírása", value: "leiras" },
        { text: "Munkalap száma", value: "munkalap_szama" },
        { text: "Javítás dátuma", value: "javitas_datuma" },
        { text: "Műveletek", value: "muveletek" }
      ]
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    nyomtatGps(sorszam) {
      this.$axios
        .post("/pdf/gpshiba", { id: sorszam })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios.get("bizttech/keszGpsHibak").then(response => {
        let items = [];
        response.data.forEach((item, index) => {
          items.push({
            sorszam: item.sorszam,
            eszkoz: item.eszkoz,
            leiras: {},
            javitas_datuma: item.javitas_datuma,
            munkalap_szama: item.munkalap_szama
          });
          if (item.hiba.length > 45)
            items[index].leiras = {
              rovid: item.hiba.substr(0, 45) + "...",
              hosszu: item.hiba
            };
          else items[index].leiras = { rovid: item.hiba, hosszu: item.hiba };
        });
        this.items = items;
        this.isLoading = false;
      });
    }
  }
};
</script>

<style></style>
