<template>
  <v-col cols="12" md="6" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <h3>{{ parseInt(vkey + 1) }}. rögzítő</h3>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="networking.rogzito_neve" label="Rögzítő neve" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="networking.rogzito_helye"
          label="Rögzítő helye"
        />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="networking.gyarto" label="Gyártó" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="networking.tipus" label="Tipus" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="networking.sorozatszam" label="Sorozatszám" />
      </v-col>
      <v-col cols="12" md="3" class="py-0">
        <v-text-field v-model="networking.hdd_meret" label="HDD Méret" />
      </v-col>
      <v-col cols="12" md="3" class="py-0">
        <v-text-field v-model="networking.idotartam" label="Időtartam" />
      </v-col>
      <v-col cols="12" md="3" class="py-0">
        <v-text-field
          v-model="networking.adminuser"
          label="ADMIN felhasználónév"
        />
      </v-col>
      <v-col cols="12" md="3" class="py-0">
        <v-text-field
          v-model="networking.adminpass"
          label="ADMIN jelszó"
          :append-icon="
            networking.adminpassshow
              ? 'mdi-checkbox-outline'
              : 'mdi-checkbox-blank-outline'
          "
          @click:append="networking.adminpassshow = !networking.adminpassshow"
        />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="networking.belso_ip" label="Belső IP cím" />
      </v-col>
      <v-col cols="12" md="2" class="py-0">
        <v-text-field v-model="networking.http_port" label="Belső HTTP Port" />
      </v-col>
      <v-col cols="12" md="2" class="py-0">
        <v-text-field v-model="networking.tcp_port" label="Belső TCP Port" />
      </v-col>
      <v-col cols="12" md="2" class="py-0">
        <v-text-field v-model="networking.udp_port" label="Belső UDP Port" />
      </v-col>
      <v-col cols="12" md="2" class="py-0">
        <v-text-field v-model="networking.rtsp_port" label="Belső RTSP Port" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="networking.domain" label="Domain" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="networking.kulso_ip" label="Külső IP cím" />
      </v-col>
      <v-col cols="12" md="2" class="py-0">
        <v-text-field v-model="networking.khttp_port" label="Külső HTTP port" />
      </v-col>
      <v-col cols="12" md="2" class="py-0">
        <v-text-field v-model="networking.ktcp_port" label="Külső TCP port" />
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" style="text-align: center;">
            <h3>Felhasználók</h3>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="success"
              :block="true"
              text
              @click="ujRogzitoUser(vkey)"
              >Új Felhasználó</v-btn
            >
          </v-col>
          <video-rogzito-felhasznalo
            v-for="(felhasznalo, key) in networking.felhasznalok"
            :key="key"
            v-bind="{
              felhasznalo,
              vkey,
              fkey: key,
              removeRogzitoUser
            }"
          />
        </v-row>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-textarea v-model="networking.megjegyzes" label="Megjegyzés" />
      </v-col>
      <v-col cols="12">
        <v-btn
          color="error"
          text
          :block="true"
          @click="removeNetworking(vkey)"
          >{{ `${vkey + 1}. rögzítő törlése` }}</v-btn
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import videoRogzitoFelhasznalo from "./videoRogzitoFelhasznalo";

export default {
  components: {
    videoRogzitoFelhasznalo
  },
  props: [
    "networking",
    "vkey",
    "removeNetworking",
    "ujRogzitoUser",
    "removeRogzitoUser"
  ]
};
</script>
