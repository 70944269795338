var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('h2',[_vm._v("Lejáró engedélyek")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2","offset-md":"7"}},[_c('v-select',{attrs:{"items":_vm.tipusok,"label":"Engedély típusa"},model:{value:(_vm.tipusLejaro),callback:function ($$v) {_vm.tipusLejaro=$$v},expression:"tipusLejaro"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Keresés"},model:{value:(_vm.filterLejaro),callback:function ($$v) {_vm.filterLejaro=$$v},expression:"filterLejaro"}})],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.filteredList,"loading":_vm.loadingLejaro,"headers":_vm.headers,"footer-props":{ showFirstLastPage: true },"search":_vm.filterLejaro},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item,key){return _c('tr',{key:key,staticClass:"warn"},[_c('td',[_vm._v(_vm._s(item.azonosito))]),_c('td',[_c('span',[_vm._v(_vm._s(item.nev))]),_c('span',{staticStyle:{"color":"red"},attrs:{"title":"Egyszerűsített foglalkozatás"}},[_vm._v(_vm._s(item.fogltipus === 5 ? "*" : ""))])]),_c('td',[_vm._v(_vm._s(item.regio))]),_c('td',[_vm._v(_vm._s(item.ceg))]),_c('td',[_vm._v(_vm._s(item.engedely))]),_c('td',[_vm._v(_vm._s(item.lejarat))]),_c('td',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","pr-1":""}},[_c('v-btn',{attrs:{"color":"success","block":true,"to":`/dolgozok/${item.azonosito}`}},[_vm._v("Adatlap")])],1),(
                            item.tipus === 1 ||
                              item.tipus === 2 ||
                              item.tipus === 3 ||
                              item.tipus === 5 ||
                              item.tipus === 8 ||
                              item.tipus === 9
                          )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"warning","block":true},on:{"click":function($event){return _vm.lejarNyomtat(item.engid)}}},[_vm._v("Nyomtatás "),(item.jelzett)?_c('v-icon',[_vm._v("error")]):_vm._e()],1)],1):(item.tipus === 6)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[(
                              _vm.kulonUID.includes(parseInt(item.azonosito)) ||
                                _vm.kulonID.includes(parseInt(item.regioid))
                            )?_c('v-btn',{attrs:{"color":"warning","block":true},on:{"click":function($event){return _vm.lejarMunkaZ(item.engid)}}},[_vm._v("Nyomtatás "),(item.jelzett)?_c('v-icon',[_vm._v("error")]):_vm._e()],1):_c('v-btn',{attrs:{"color":"warning","block":true},on:{"click":function($event){return _vm.lejarMunka(item.engid)}}},[_vm._v(" Nyomtatás "),(item.jelzett)?_c('v-icon',[_vm._v("error")]):_vm._e()],1)],1):_vm._e()],1)],1)])}),0)]}}])})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }