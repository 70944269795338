<template>
  <v-col cols="12" md="6" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <h3>{{ parseInt(vkey + 1) }}. tartomány</h3>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="tartomany.ip" label="IP" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="tartomany.almaszk" label="Almaszk" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="tartomany.alapertelmezett_atjaro"
          label="Alapértelmezett átjáró"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="tartomany.dns" label="DNS" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="tartomany.kezdes" label="Tartomány kezdés" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="tartomany.vege" label="Tartomány vége" />
      </v-col>
      <v-col cols="12">
        <v-btn
          color="error"
          text
          :block="true"
          @click="removeTartomany(vkey)"
          >{{ `${vkey + 1}. Tartomány törlése` }}</v-btn
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["tartomany", "vkey", "removeTartomany"]
};
</script>
