<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title>
          <h2>Munkalapok</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="1" offset-md="5" class="py-0">
                  <v-select
                    v-model="allapot"
                    label="Munkalap állapotok"
                    :items="munkalapAllapotok"
                  />
                </v-col>
                <v-col cols="12" md="1" class="py-0">
                  <v-select
                    v-model="torolve"
                    label="Törölve"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select v-model="tipus" label="Tipusok" :items="tipusok" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="filteredList"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :custom-filter="customFilter"
              >
                <template v-slot:[`item.id`]="{ item }">
                  {{ item.id.toString().padStart(6, "0") }}
                </template>
                <template v-slot:[`item.tipus`]="{ item }">
                  <p
                    v-for="(tipus, key) in item.munkavegzes_tipusa"
                    :key="key"
                    style="margin: 0"
                  >
                    {{
                      tipus === 0
                        ? "Karbantartás"
                        : tipus === 1
                        ? "Javítás"
                        : "Telepítés / Kiépítés"
                    }}
                  </p>
                </template>
                <template v-slot:[`item.ugyfel`]="{ item }">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      {{ item.azonosito }} - {{ item.ugyfel_neve }} -
                      {{ item.objektum_neve }}
                    </v-col>
                    <v-col cols="12" class="py-0">
                      {{ item.objektum_cime }}
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-row>
                    <v-col v-if="userId !== 2565" cols="6">
                      <v-btn
                        color="success"
                        :block="true"
                        :to="'/munkalapok/' + item.id"
                        >Adatlap</v-btn
                      >
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        color="warning"
                        :block="true"
                        :disabled="allapot !== 2 || item.alairas === -1"
                        @click="printPdf(item.id)"
                        >Nyomtatás</v-btn
                      >
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Munkalapok",
  data() {
    return {
      filter: "",
      items: [],
      headers: [
        { text: "Sorszám", value: "id" },
        { text: "Tipus", value: "tipus" },
        { text: "Ügyfél", value: "ugyfel" },
        { text: "Dátum", value: "datum" },
        { text: "Műveletek", value: "muveletek" }
      ],
      isLoading: false,
      torolve: 0,
      igenNem: [{ text: "Nem", value: 0 }, { text: "Igen", value: 1 }],
      munkalapAllapotok: [
        { text: "Új", value: 0 },
        { text: "Nyitott", value: 1 },
        { text: "Lezárt", value: 2 }
      ],
      allapot: this.$store.getters["getUser"].id !== 2565 ? 0 : 2,
      tipus: -1,
      tipusok: [
        { text: "Nincs megadva", value: -1 },
        { text: "Karbantartás", value: 0 },
        { text: "Javítás", value: 1 },
        { text: "Telepítés / Kiépítés", value: 2 }
      ]
    };
  },
  computed: {
    userId() {
      return this.$store.getters["getUser"].id;
    },
    filteredList() {
      return this.items
        .filter(item => item.allapot === this.allapot)
        .filter(item =>
          this.tipus === -1
            ? item
            : item.munkavegzes_tipusa.includes(this.tipus)
            ? item
            : null
        );
    }
  },
  watch: {
    torolve() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    customFilter(value, search, item) {
      const searchString = search.toString().toLowerCase();
      return (
        item.id
          .toString()
          .padStart(6, "0")
          .indexOf(search.toString()) !== -1 ||
        item.azonosito
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.ugyfel_neve
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.datum
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.objektum_cime
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.objektum_neve
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1
      );
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios.get("munkalapok/kulonBonafarm").then(resp => {
        this.$axios.get(`munkalapok?torolve=${this.torolve}`).then(response => {
          if (this.$store.getters.getUser.id === 2565) {
            this.items = response.data.filter(item =>
              resp.data.includes(item.azonosito) ? item : null
            );
          } else {
            this.items = response.data;
          }
          this.isLoading = false;
        });
      });
    },
    printPdf(id) {
      this.$axios
        .post(`/pdf/munkalapNyomtat`, {
          id: id
        })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/munkalapok/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/munkalapok/${response.data.fileName}`,
            "_blank"
          )
        );
    }
  }
};
</script>

<style lang="stylus"></style>
