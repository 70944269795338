<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0"
              ><v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">Számla adatai</h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>

                <v-col cols="12" md="3" class="py-0">
                  <v-select v-model="data.ev" :items="evItems" label="Év" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select
                    v-model="data.honap"
                    :items="honapItems"
                    label="Hónap"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.parkolas"
                    label="Parkolás"
                    suffix="Ft"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.osszeg"
                    label="Összeg"
                    suffix="Ft"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="py-0 mt-3 d-none d-sm-flex">
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="success" @click="handleSubmit">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn
                color="success"
                fab
                bottom
                right
                fixed
                @click="handleSubmit"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres feltöltés!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Számla",
  data() {
    return {
      data: {
        telefonszam: this.$route.params.id,
        ev: this.$moment().format("YYYY"),
        honap: this.$moment().format("M")
      },
      mentes: false,
      hiba: false,
      telefonok: [],
      honapItems: [
        { text: "Január", value: "1" },
        { text: "Február", value: "2" },
        { text: "Március", value: "3" },
        { text: "Április", value: "4" },
        { text: "Május", value: "5" },
        { text: "Június", value: "6" },
        { text: "Július", value: "7" },
        { text: "Augusztus", value: "8" },
        { text: "Szeptember", value: "9" },
        { text: "Október", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" }
      ],
      evItems: [
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025"
      ]
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    handleSubmit() {
      this.axios
        .post("telefonok/updateSzamla", {
          ...this.data,
          targyho: this.data.ev + "-" + this.data.honap
        })
        .then(() => (this.mentes = true))
        .catch(() => (this.hiba = true));
    },
    getData() {
      this.axios
        .get(`telefonok/szamla/${this.$route.params.azon}`)
        .then(response => {
          this.data = response.data;
          this.data.ev = this.$moment(response.data.targyho, "YYYY-M").format(
            "YYYY"
          );
          this.data.honap = this.$moment(
            response.data.targyho,
            "YYYY-M"
          ).format("M");
        });
    }
  }
};
</script>

<style></style>
