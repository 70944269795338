import RuhakIndex from "@/pages/ruhak/index";
import RuhakID from "@/pages/ruhak/_id";
import RuhakRaktar from "@/pages/ruhak/raktarkeszlet";
import RuhakNewRuha from "@/pages/ruhak/newRuha";
import RuhakNewRendeles from "@/pages/ruhak/newRendeles";
import RuhakRendelesek from "@/pages/ruhak/rendelesek";
import RuhakNewKiadas from "@/pages/ruhak/newKiadas";
import RuhakKiadasok from "@/pages/ruhak/kiadasok";
import RuhakVisszavetelek from "@/pages/ruhak/visszavetelek";

export default [
  {
    name: "ruhak-index",
    path: "ruhak",
    component: RuhakIndex
  },
  {
    name: "ruhak-raktarkeszlet",
    path: "ruhak/raktarkeszlet",
    component: RuhakRaktar
  },
  {
    name: "ruhak-rendelesek",
    path: "ruhak/rendelesek",
    component: RuhakRendelesek
  },
  {
    name: "ruhak-newruha",
    path: "ruhak/newRuha",
    component: RuhakNewRuha
  },
  {
    name: "ruhak-newrendeles",
    path: "ruhak/newRendeles",
    component: RuhakNewRendeles
  },
  {
    name: "ruhak-newkiadas",
    path: "ruhak/newKiadas",
    component: RuhakNewKiadas
  },
  {
    name: "ruhak-kiadasok",
    path: "ruhak/kiadasok",
    component: RuhakKiadasok
  },
  {
    name: "ruhak-visszavetelek",
    path: "ruhak/visszavetelek",
    component: RuhakVisszavetelek
  },
  {
    name: "ruhak-id",
    path: "ruhak/:id",
    component: RuhakID
  }
];
