<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">
                    {{ data.marka }} {{ data.tipus }} -
                    {{ data.rendszam }} adatai
                  </h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_marka"
                    v-model="data.marka"
                    label="Márka"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_tipus"
                    v-model="data.tipus"
                    label="Típus"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_rendszam"
                    v-model="data.rendszam"
                    label="Rendszám"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_evjarat"
                    v-model="data.evjarat"
                    label="Évjárat"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-select
                    id="auto_uzemanyag"
                    v-model="data.uzemanyag"
                    label="Üzemanyag típusa"
                    :items="uzemTipus"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_hasznalja"
                    v-model="data.hasznalja"
                    label="Használja"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_alvazszam"
                    v-model="data.alvazszam"
                    label="Alvázszám"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_motorszam"
                    v-model="data.motorszam"
                    label="Motorszám"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_forgalmiszam"
                    v-model="data.forgalmiszam"
                    label="Forgalmiszám"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_terfogat"
                    v-model="data.terfogat"
                    label="Térfogat"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_szin"
                    v-model="data.szin"
                    label="Szín"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_vetelar"
                    v-model="data.vetelar"
                    label="Vételár"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_hasznalatbavetel"
                    v-model="data.hasznalatbavetel"
                    label="Használatbavétel dátuma"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_gumimeret"
                    v-model="data.gumimeret"
                    label="Gumiméret"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_gumiraktar"
                    v-model="data.gumiraktar"
                    label="Gumiraktár"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_gktipus"
                    v-model="data.gktipus"
                    label="Gépkocsi típus"
                    :items="gkTipus"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_muszaki_kezdet"
                    v-model="data.muszaki_kezdet"
                    label="Műszaki kezdet"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_muszaki_lejarat"
                    v-model="data.muszaki_lejarat"
                    label="Műszaki lejárat"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_kotelezo"
                    v-model="data.kotelezo"
                    label="Kötelező"
                  />
                </v-col>
                <v-col cols="12" md="4" class="pb-0 pt-3">
                  <v-row v-if="data.forgalmi">
                    <v-col cols="12" md="6" class="py-0">
                      <v-text-field
                        id="auto_forgalmi"
                        v-model="data.forgalmi"
                        label="Forgalmi"
                        readonly
                        clearable
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0 pt-3">
                      <v-btn color="warning" :block="true" @click="getSzamla">
                        Forgalmi
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" md="6" class="py-0">
                      <v-text-field
                        id="auto_forgalmi"
                        v-model="data.forgalmi"
                        label="Forgalmi"
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <uploadButton
                        ref="upButton"
                        :type="'képet'"
                        @kep="gotKep"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_figyelmezteto"
                    v-model="data.figyelmezteto_lejarat"
                    label="Figyelmeztető lejárat"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_casco"
                    v-model="data.casco"
                    label="Casco"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_cascoszazalek"
                    v-model="data.cascoszazalek"
                    label="Önrész %"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="auto_cascoforint"
                    v-model="data.cascoforint"
                    label="Önrész FT"
                  />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    id="auto_megjegyzes"
                    v-model="data.megjegyzes"
                    label="Megjegyzés"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="py-0">
              <v-row>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="success" @click="handleSubmit">
                    Mentés
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/autok/${$route.params.id}/tankolasok`"
                  >
                    Tankolások
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/autok/${$route.params.id}/szervizek`"
                  >
                    Szervizek
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/autok/${$route.params.id}/menetlevelek`"
                  >
                    Menetlevelek
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/autok/${$route.params.id}/tankolasok/newTankolas`"
                  >
                    Új Tankolás
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/autok/${$route.params.id}/szervizek/newSzerviz`"
                  >
                    Új Szervíz
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="d-sm-none">
      <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
        ><v-icon>arrow_left</v-icon></v-btn
      >
      <v-btn color="success" fab bottom right fixed @click="handleSubmit"
        ><v-icon>save</v-icon></v-btn
      >
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
import uploadButton from "@/components/uploadButton.vue";

export default {
  title: "Autó adatai",
  components: { uploadButton },
  data() {
    return {
      data: {
        megjegyzes: ""
      },
      mentes: false,
      hiba: false,
      uzemTipus: [
        "Nincs megadva",
        "95 benzin",
        "LPG autógáz",
        "D Gázolaj",
        "CNG földgáz"
      ],
      gkTipus: [
        "Nincs megadva",
        "Személygépkocsi",
        "Teherautó",
        "Munkagép",
        "Tehergépkocsi"
      ],
      matricak: [],
      kep: {
        name: ""
      }
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    gotKep(e) {
      this.kep = e;
    },
    getSzamla() {
      return window.open(
        window.location.protocol == "http:"
          ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/forgalmik/${this.data.forgalmi}`
          : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/forgalmik/${this.data.forgalmi}`,
        "_blank"
      );
    },
    getData() {
      const promise = this.$axios.get(`autok/${this.$route.params.id}`);
      const amatrica = this.$axios.get("autok/matricak");
      Promise.all([promise, amatrica]).then(responses => {
        this.data = responses[0].data;
        this.matricak = responses[1].data;
      });
    },
    handleSubmit() {
      this.$axios
        .post("autok/updateAuto", {
          ...this.data,
          forgalmi: this.data.forgalmi ? this.data.forgalmi : ""
        })
        .then(() => {
          if (this.kep.name == "") {
            this.mentes = true;
          } else {
            let formData = new FormData();
            formData.append("file", this.kep);
            formData.append("id", this.data.id);
            this.$axios
              .post("autok/upForgalmi", formData)
              .then(() => {
                this.mentes = true;
                this.data.forgalmi = this.kep.name;
                this.$refs.upButton.removeFile();
              })
              .catch(() => {
                this.hiba = true;
              });
          }
        })
        .catch(() => (this.hiba = true));
    }
  }
};
</script>

<style></style>
