<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">
                    {{ data.auto_adatok.marka }} {{ data.auto_adatok.tipus }} -
                    {{ data.auto_adatok.rendszam }} tankolása
                  </h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="tankolas_rogzitette"
                    v-model="data.rogzitette"
                    label="Rögzítette"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="tankolas_datum"
                    v-model="data.datum"
                    label="Dátum"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="tankolas_dolgozo"
                    v-model="data.dolgozo"
                    label="Dolgozó"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="tankolas_km_ora"
                    v-model="data.km_ora"
                    label="Kilóméteróra állása"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="tankolas_liter"
                    v-model="data.liter"
                    label="Tankolt Liter"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field id="tankolas_ar" v-model="data.ar" label="Ár" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    label="Előző Kilóméteróra állása"
                    :value="data.elozo_km"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    label="Átlagfogyasztás"
                    :value="fogyasztas(data.liter, data.km_ora, data.elozo_km)"
                    :disabled="true"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="py-1 mt-3">
              <v-row>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="success" @click="handleSubmit">
                    Mentés
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/autok/${$route.params.id}`"
                  >
                    Autó Adatlap
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/autok/${$route.params.id}/tankolasok`"
                  >
                    További Tankolások
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="d-sm-none">
      <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
        ><v-icon>arrow_left</v-icon></v-btn
      >
      <v-btn color="success" fab bottom right fixed @click="handleSubmit"
        ><v-icon>save</v-icon></v-btn
      >
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Tankolás",
  data() {
    return {
      data: {
        auto_adatok: {}
      },
      mentes: false,
      hiba: false
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    fogyasztas(liter, km_ora, elozo_km) {
      let fogy = liter / ((km_ora - elozo_km) / 100);
      if (isNaN(fogy)) return 0;
      return fogy.toFixed(2);
    },
    handleSubmit() {
      this.$axios
        .post("autok/updateTankolas", this.data)
        .then(() => (this.mentes = true))
        .catch(() => (this.hiba = true));
    },
    getData() {
      this.$axios
        .get(`autok/tankolasok/${this.$route.params.azon}`)
        .then(response => (this.data = response.data));
    }
  }
};
</script>
