const moment = require("moment");
require("moment/locale/hu");
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PRIVILEGES = "UPDATE_PRIVILEGES";

const state = {
  token: "",
  tokenExpiry: "",
  user: {},
  privileges: {}
};

const actions = {
  doLogin({ commit }, { post, username, password }) {
    return new Promise((resolve, reject) => {
      post("auth/login", {
        username,
        password
      }).then(response => {
        if (!response.data.success)
          reject({ success: false, message: response.data.msg });
        else {
          commit(LOGIN, response.data);
          resolve({
            message: "Success!",
            success: true,
            needPasswordChange: response.data.user.needPasswordChange,
            homePage: response.data.user.homePage,
            user_type: response.data.user.user_type
          });
        }
      });
    });
  },
  doLogout({ commit }) {
    return new Promise(resolve => {
      commit(LOGOUT);
      resolve({
        success: true
      });
    });
  },
  updatePrivileges({ commit }, { get, uid }) {
    return new Promise((resolve, reject) => {
      get("auth/updatePrivileges?uid=" + uid)
        .then(response => {
          commit(UPDATE_PRIVILEGES, response.data);
          resolve({ success: true });
        })
        .catch(err => {
          if (
            err
              .toString()
              .split(" ")
              .includes("403")
          ) {
            reject({ success: false, message: "Error 403", error: 403 });
          }
        });
    });
  }
};

const mutations = {
  [LOGIN](state, data) {
    state.token = data.token;
    state.user = data.user;
    state.privileges = data.privileges;
    state.tokenExpiry = data.expiry;
  },
  [LOGOUT](state) {
    state.token = "";
    state.tokenExpiry = "";
    state.user = {};
    state.privileges = {};
  },
  [UPDATE_PRIVILEGES](state, data) {
    state.privileges = data;
  }
};

const getters = {
  getUser: state => {
    return state.user;
  },
  getPrivileges: state => {
    return state.privileges;
  },
  getToken: state => {
    return state.token;
  },
  checkExpiry: state => {
    return moment().isBefore(state.tokenExpiry);
  }
};

export default {
  //namespaced: true,
  state,
  mutations,
  actions,
  getters
};
