<template>
  <v-row>
    <v-col cols="12" style="text-align: center" mt-5>
      <h1>Alapadatok</h1>
    </v-col>
    <v-col cols="12"> <hr class="thinLine" /> </v-col>
    <v-col cols="12" md="3" px-1>
      <v-text-field v-model="tuzjelzoAdat.minosites" label="Minősítés" />
    </v-col>
    <v-col cols="12" md="3" px-1>
      <v-text-field v-model="tuzjelzoAdat.mernokikod" label="Mérnöki kód" />
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="3" px-1>
          <v-select
            v-model="tuzjelzoAdat.atjelzes"
            label="Átjelzés"
            :items="[{ text: 'Igen', value: 1 }, { text: 'Nem', value: 0 }]"
          />
        </v-col>
        <v-col v-if="tuzjelzoAdat.atjelzes" cols="12" md="9">
          <v-row>
            <v-col cols="12" md="4" px-1>
              <v-text-field
                v-model="tuzjelzoAdat.atjelzestipus"
                label="Átjelzés típus"
              />
            </v-col>
            <v-col cols="12" md="4" px-1>
              <v-text-field
                v-model="tuzjelzoAdat.atjelzesazonosito"
                label="Átjelzés azonosító"
              />
            </v-col>
            <v-col cols="12" md="4" px-1>
              <v-text-field
                v-model="tuzjelzoAdat.atjelzestelefonszam"
                label="Telefonszám"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="tuzjelzoAdat.ertesitendok.length > 0" cols="12">
      <v-row>
        <v-col cols="12" style="text-align: center;" mt-5>
          <h1>Értesítendők</h1>
        </v-col>
        <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
        <tuzjelzo-ertesitendo
          v-for="(ertesitendo, key) in tuzjelzoAdat.ertesitendok"
          :key="key"
          v-bind="{ ertesitendo, vkey: key, removeErtesitendoTuzjelzo }"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import tuzjelzoErtesitendo from "./components/tuzjelzoErtesitendo";

export default {
  components: {
    tuzjelzoErtesitendo
  },
  props: ["tuzjelzoAdat", "removeErtesitendoTuzjelzo"]
};
</script>
