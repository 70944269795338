<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">
                    {{ data.id.toString().padStart(5, "0") }}. jelentés
                  </h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <v-text-field
                    label="Objektum"
                    :value="
                      `${data.azonosito} | ${data.ugyfel_neve} | ${data.objektum_cime} | ${data.select_ceg}`
                    "
                    readonly
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.datum"
                    label="Dátum"
                    type="date"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.idopont1"
                    label="Riasztás vételének időpontja"
                    type="time"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.idopont2"
                    label="Helyszínre érkezés időpontja"
                    type="time"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.idopont3"
                    label="Ellenőrzés befejezésének időpontja"
                    type="time"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.idopont4"
                    label="Helyszín elhagyásának időpontja"
                    type="time"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    :value="idopont5"
                    label="Kivonulás időtartama"
                    disabled
                    readonly
                    suffix="perc"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.coords"
                    label="Koordináták"
                    disabled
                    readonly
                  />
                </v-col>
                <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-checkbox v-model="data.ok1" label="Betörési riasztás" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-checkbox
                    v-model="data.ok2"
                    label="Támadás / Pánikriasztás"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-checkbox v-model="data.ok3" label="Tűzriasztás" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-checkbox v-model="data.ok4" label="Segélyhívás" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-checkbox v-model="data.ok5" label="Egyéb eset" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="data.zona" label="Zóna száma" />
                </v-col>
                <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data1" label="Behatolási nyomok" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data2"
                    label="Bekapcsolt elektromos készülék"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data3" label="Belső ellenőrzés" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data4" label="Betörés" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data5" label="Bezárt háziállat" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data6"
                    label="Bűnmegelőzési intézkedés"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data7" label="Egyéb ok" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data8"
                    label="Eredeti állapot visszaállítása"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data9" label="Észlelt személyek" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data10"
                    label="Feltört ajtó/ablak"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data11" label="Helyszín őrzése" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data12" label="Helyszínentartás" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data13"
                    label="Időntúli riasztás lemondás"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data14"
                    label="Indokolatlan pánikjelzés"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data15"
                    label="Kármegelőzési intézkedés"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data16" label="Kezelői hiba" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data17" label="Külső ellenőrzés" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data18" label="Mentők értesítve" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data19"
                    label="Nyitott ajtó/ablak"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data20"
                    label="Rendőrség értesítve"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data21"
                    label="Riasztó kikapcsolva"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data22"
                    label="Riasztórendszer hiba"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data23" label="Rongálódás, kár" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data24" label="Személyi sérülés" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data25"
                    label="Terület ellenőrzése"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox v-model="data.data26" label="Tűz észlelése" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data27"
                    label="Tűzoltóság értesítve"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.data28"
                    label="Videó / Fénykép készült"
                  />
                </v-col>
                <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-checkbox
                    v-model="data.kierkezes"
                    label="Időntúli kiérkezés"
                  />
                </v-col>
                <v-col v-if="data.kierkezes" cols="12" md="8" class="py-0">
                  <v-textarea
                    v-model="data.kierkezes_oka"
                    label="Időntúli kiérkezés oka"
                  />
                </v-col>
                <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
                <v-col cols="12">
                  <h3>
                    Az objektumban tartózkodott, vagy a helyszínre érkezett:
                  </h3>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.elofizeto" label="Előfizető" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.meghatalmazott"
                    label="Meghatalmazott"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.jogosult" label="Jogosult" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.egyeb" label="Egyéb" />
                </v-col>
                <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea v-model="data.megjegyzes" label="Megjegyzés" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    v-model="data.elofizeto_tapasztalatai"
                    label="Előfizető tapasztalatai"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="data.jaror_neve" label="Járőr neve" />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="data.jaror_igszam"
                    label="Járőr igazolványszáma"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="data.igazolas" label="Igazoló" />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-1 mt-5"
              style="align-self: flex-start;"
            >
              <v-row>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col
                  v-if="getPrivileges.jelentes_edit === 1"
                  cols="12"
                  class="py-1 d-none d-sm-flex"
                >
                  <v-btn :block="true" color="success" @click="handleSubmit">
                    Mentés
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="warning"
                    style="height: 70px;"
                    @click="printPdf"
                  >
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-icon>print</v-icon>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        Nyomtatás
                      </v-col>
                      <v-col cols="12" class="py-0">
                        (Bonafarm)
                      </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="warning"
                    style="height: 70px;"
                    @click="printPdfPajzs"
                  >
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-icon>print</v-icon>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        Nyomtatás
                      </v-col>
                      <v-col cols="12" class="py-0">
                        (Bonsec)
                      </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="primary" @click="openMaps">
                    GPS
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn
                color="success"
                fab
                bottom
                right
                fixed
                @click="handleSubmit"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Jelentés adatok",
  data() {
    return {
      mentes: false,
      hiba: false,
      data: {
        id: "00000",
        idopont5: 0,
        megjegyzes: "",
        elofizeto_tapasztalatai: "",
        kierkezes_oka: ""
      },
      items: []
    };
  },
  computed: {
    idopont5() {
      let seconddate = this.data.datum;
      if (this.data.idopont2 < this.data.idopont1) {
        seconddate = this.$moment(seconddate, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
      }
      return this.data.idopont1 && this.data.idopont2
        ? this.$moment(
            seconddate + " " + this.data.idopont2,
            "YYYY-MM-DD HH:mm"
          ).diff(
            this.$moment(
              this.data.datum + " " + this.data.idopont1,
              "YYYY-MM-DD HH:mm"
            ),
            "minutes"
          )
        : 0;
    },
    getPrivileges() {
      return this.$store.getters.getPrivileges;
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.$axios
        .get(`jelentesek/jelentesek/${this.$route.params.id}`)
        .then(response => (this.data = response.data));
    },
    handleSubmit() {
      this.$axios
        .post("jelentesek/updateJelentes", this.data)
        .then(() => {
          this.mentes = true;
        })
        .catch(() => {
          this.hiba = true;
        });
    },
    printPdf() {
      this.$axios
        .post(`/pdf/jelentesNyomtat`, { id: this.$route.params.id })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/jelentesek/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/jelentesek/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    printPdfPajzs() {
      this.$axios
        .post(`/pdf/jelentesPajzsNyomtat`, { id: this.$route.params.id })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/jelentesek/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/jelentesek/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    openMaps() {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${this.data.coords}`,
        "_blank"
      );
    }
  }
};
</script>

<style></style>
