<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-form ref="form">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="10" class="py-0">
                <v-row>
                  <v-col cols="12">
                    <h1 style="text-align: center;">Új kiadás</h1>
                  </v-col>
                  <v-col cols="12"> <hr class="thinLine" /> </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-row>
                          <v-col cols="12" md="6" class="py-0">
                            <v-text-field
                              v-model="kiadas.datum"
                              type="date"
                              label="Kiadás dátuma"
                              max="2999-12-31"
                            />
                          </v-col>
                          <v-col cols="12" md="6" class="py-0">
                            <v-autocomplete
                              v-model="kiadas.dolgozo"
                              label="Dolgozó"
                              :items="dolgozok"
                              :filter="dolgozoFilter"
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.id }} - {{ data.item.nev }}
                              </template>
                              <template slot="item" slot-scope="data">
                                {{ data.item.id }} - {{ data.item.nev }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="5" class="py-0">
                            <v-select
                              v-model="kiadas.kiadas_oka"
                              label="Kiadás oka"
                              :items="okItems"
                              @change="kiadas.kiadas_okaText = ''"
                            />
                          </v-col>
                          <v-col cols="12" md="7" class="py-0">
                            <v-text-field
                              v-model="kiadas.kiadas_okaText"
                              :disabled="kiadas.kiadas_oka != 4"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-row>
                          <v-col
                            v-for="(ruha, key) in kiadas_ruhak"
                            :key="key"
                            cols="12"
                          >
                            <v-row>
                              <v-col cols="12" md="2" class="py-0">
                                <v-text-field
                                  v-model="ruha.barcode"
                                  label="Vonalkód"
                                  @keyup.enter="
                                    changeMax({ key, $event });
                                    setId(key);
                                  "
                                />
                              </v-col>
                              <v-col cols="12" md="8" class="py-0">
                                <v-autocomplete
                                  v-model="ruha.ruha_id"
                                  label="Ruha típusa"
                                  :items="ruhak"
                                  :filter="ruhakFilter"
                                  item-value="id"
                                  @change="
                                    changeMax({ key, $event });
                                    setBarcode({ key, $event });
                                  "
                                >
                                  <template slot="selection" slot-scope="data">
                                    {{ data.item.barcode }} -
                                    {{ data.item.ruha_nev }} -
                                    {{ data.item.ruha_meret }} |
                                    {{ data.item.kihordasi_ido }} hó | Raktár:
                                    {{ data.item.raktar_darab }} db /
                                    {{ data.item.vissza_darab }} db
                                  </template>
                                  <template slot="item" slot-scope="data">
                                    {{ data.item.barcode }} -
                                    {{ data.item.ruha_nev }} -
                                    {{ data.item.ruha_meret }} |
                                    {{ data.item.kihordasi_ido }} hó | Raktár:
                                    {{ data.item.raktar_darab }} db /
                                    {{ data.item.vissza_darab }} db
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" md="2" class="py-0">
                                <v-text-field
                                  v-model="ruha.darab"
                                  label="Darab"
                                  suffix="db"
                                  type="number"
                                  :max="ruha.max"
                                  min="1"
                                  required
                                  :rules="[v => !!v || 'Darabszám kötelező']"
                                />
                              </v-col>
                              <v-col cols="12" md="3" class="py-0">
                                <v-select
                                  v-model="ruha.raktar"
                                  label="Raktár"
                                  :items="[
                                    { text: 'Ruharaktár', value: 0 },
                                    { text: 'Használt raktár', value: 1 }
                                  ]"
                                  @change="
                                    changeMax({
                                      key,
                                      $event,
                                      type: 'changeRaktar'
                                    })
                                  "
                                />
                              </v-col>
                              <v-col cols="12" md="8" class="py-0">
                                <v-text-field
                                  v-model="ruha.megjegyzes"
                                  label="Megjegyzés"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                md="1"
                                style="text-align: center;"
                              >
                                <v-icon
                                  style="color: red!important; margin-top: 15px;"
                                  @click="deleteItem(key)"
                                >
                                  delete
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="2" class="py-0 mt-3">
                <v-row>
                  <v-col cols="12" class="py-1">
                    <v-btn color="primary" :block="true" @click="addRuha">
                      Új ruha
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="py-1 d-none d-sm-flex">
                    <v-btn color="info" :block="true" @click="$router.go(-1)">
                      Vissza
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="py-1 d-none d-sm-flex">
                    <v-btn
                      color="success"
                      :block="true"
                      :disabled="!kiadas.dolgozo"
                      @click="sendCreate"
                    >
                      Mentés
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="d-sm-none">
                <v-btn
                  color="info"
                  fab
                  bottom
                  left
                  fixed
                  @click="$router.go(-1)"
                  ><v-icon>arrow_left</v-icon></v-btn
                >
                <v-btn
                  color="success"
                  fab
                  bottom
                  right
                  fixed
                  @click="sendCreate"
                  ><v-icon>save</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Új kiadás",
  data() {
    return {
      ruhak: [],
      dolgozok: [],
      mentes: false,
      hiba: false,
      isLoading: true,
      kiadas: {
        datum: this.$moment().format("YYYY-MM-DD"),
        kiadas_oka: "Belépő dolgozó",
        kiadas_okaText: ""
      },
      kiadas_ruhak: [],
      okItems: [
        { text: "Belépő dolgozó", value: "Belépő dolgozó" },
        { text: "Munkakör-változás", value: "Munkakör-változás" },
        { text: "Csere", value: "Csere" },
        { text: "Egyéb", value: 4 }
      ]
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    setId(key) {
      this.kiadas_ruhak[key].ruha_id = parseInt(this.kiadas_ruhak[key].barcode);
      const ruha = searchObject(
        this.ruhak,
        "barcode",
        this.kiadas_ruhak[key].barcode
      );
      this.kiadas_ruhak[key].brutto_ar = ruha.brutto_ar;
      this.kiadas_ruhak[key].kezelesi_koltseg = ruha.kezelesi_koltseg;
      if (this.kiadas_ruhak[key].darab > ruha.raktar_darab)
        this.kiadas_ruhak[key].darab = ruha.raktar_darab;
    },
    setBarcode(data) {
      this.kiadas_ruhak[data.key].barcode = data.$event
        .toString()
        .padStart(4, "0");
      const ruha = searchObject(this.ruhak, "id", data.$event);
      this.kiadas_ruhak[data.key].brutto_ar = ruha.brutto_ar;
      this.kiadas_ruhak[data.key].kezelesi_koltseg = ruha.kezelesi_koltseg;
      if (this.kiadas_ruhak[data.key].darab > ruha.raktar_darab)
        this.kiadas_ruhak[data.key].darab = ruha.raktar_darab;
    },
    deleteItem(key) {
      this.kiadas_ruhak.splice(key, 1);
    },
    changeMax(data) {
      let ruhaDarab;
      if (data.$event.target) {
        ruhaDarab = searchObject(
          this.ruhak,
          "barcode",
          data.$event.target.value
        ).raktar_darab;
      } else {
        ruhaDarab = searchObject(this.ruhak, "id", data.$event).raktar_darab;
      }
      this.kiadas_ruhak[data.key].max = ruhaDarab;
    },
    dolgozoFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      const dolgozoNev = item.nev.toLowerCase();
      const dolgozoId = item.id.toString().toLowerCase();
      return (
        dolgozoNev.indexOf(searchText) > -1 ||
        dolgozoId.indexOf(searchText) > -1
      );
    },
    ruhakFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      const ruhaText = item.ruha_nev.toLowerCase();
      const ruhaBarcode = item.barcode.toLowerCase();
      return (
        ruhaText.indexOf(searchText) > -1 ||
        ruhaBarcode.indexOf(searchText) > -1
      );
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      const raktarkeszlet = this.axios.get(`ruhak/raktarkeszlet`);
      const dolgozok = this.axios.get("dolgozok/ruhaDolgozok");
      Promise.all([raktarkeszlet, dolgozok]).then(response => {
        this.ruhak = response[0].data;
        this.dolgozok = response[1].data;
        this.isLoading = false;
      });
    },
    addRuha() {
      this.kiadas_ruhak.push({
        ruha_id: this.ruhak[0].id,
        darab: 0,
        max: this.ruhak[0].raktar_darab,
        barcode: this.ruhak[0].barcode,
        brutto_ar: this.ruhak[0].brutto_ar,
        megjegyzes: "",
        raktar: 0,
        kezelesi_koltseg: this.ruhak[0].kezelesi_koltseg
      });
    },
    sendCreate() {
      if (this.$refs.form.validate()) {
        this.$axios
          .post("ruhak/createKiadas", {
            ...this.kiadas,
            kiadas_ruhak: this.kiadas_ruhak.filter(x => parseInt(x.darab) !== 0)
          })
          .then(() => {
            this.kiadas = {
              datum: this.$moment().format("YYYY-MM-DD")
            };
            this.kiadas_ruhak = [];
          })
          .catch(() => (this.hiba = true));
      } else this.hiba = true;
    },
    sumOsszeg() {
      let sum = 0;
      this.kiadas_ruhak.forEach(val => (sum += val.brutto_ar * val.darab));
      return sum;
    }
  }
};

function searchObject(myArray, prop, nameKey) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i][prop] === nameKey) {
      return myArray[i];
    }
  }
}
</script>
