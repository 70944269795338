<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Karbantartások</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-select v-model="ev" label="Év" :items="evek" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="negyedev"
                    label="Negyedév"
                    :items="negyedevek"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="searchFelelos"
                    label="Felelős"
                    :items="tipusok"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="searchTipus"
                    label="Rendszertípus"
                    :items="rendszerTipusok"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="search"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="filteredList"
                :headers="headers"
                :loading="isLoading"
                :search="search"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.azonosito">
                      <td>{{ item.azonosito }}</td>
                      <td>{{ item.ugyfel }}</td>
                      <td>{{ item.objektum }}</td>
                      <td>{{ item.karbantartok }}</td>
                      <td class="hidden-lg-and-down">{{ item.felelos }}</td>
                      <td>{{ item.munkalap }}</td>
                      <td>{{ item.datum }}</td>
                      <td class="hidden-lg-and-down">{{ item.igazolo }}</td>
                      <td class="hide">{{ item.tipus }}</td>
                      <td>
                        <v-btn
                          color="success"
                          :to="`/karbantartasok/${item.azonosito}/${item.id}`"
                          >Karbantartás Adatlap</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { union } from "lodash";

export default {
  title: "Karbantartások",
  data() {
    return {
      isLoading: true,
      ev: this.$moment().format("YYYY"),
      negyedev: this.$moment().format("Q"),
      search: "",
      searchTipus: "",
      searchFelelos: "",
      items: [],
      headers: [
        { text: "Azonosító", value: "azonosito" },
        { text: "Ügyfél", value: "ugyfel" },
        { text: "Objektum", value: "objektum" },
        { text: "Karbantartók", value: "karbantartok" },
        { text: "Felelős", value: "felelos", class: "hidden-lg-and-down" },
        { text: "Munkalap száma", value: "munkalap" },
        { text: "Dátum", value: "datum" },
        { text: "Igazoló", value: "igazolo", class: "hidden-lg-and-down" },
        { text: "Típus", value: "tipus", class: "hide" },
        { text: "Műveletek", value: "muveletek" }
      ],
      tipusok: [{ text: "Nincs megadva", value: "" }],
      rendszerTipusok: [
        { text: "Nincs megadva", value: "" },
        { text: "Riasztórendszer", value: "Riasztórendszer" },
        { text: "Videórendszer", value: "Videórendszer" },
        { text: "Beléptetőrendszer", value: "Beléptetőrendszer" },
        { text: "Tűzjelzőrendszer", value: "Tűzjelzőrendszer" }
      ],
      evek: [
        { text: "2007", value: "2007" },
        { text: "2008", value: "2008" },
        { text: "2009", value: "2009" },
        { text: "2010", value: "2010" },
        { text: "2011", value: "2011" },
        { text: "2012", value: "2012" },
        { text: "2013", value: "2013" },
        { text: "2014", value: "2014" },
        { text: "2015", value: "2015" },
        { text: "2016", value: "2016" },
        { text: "2017", value: "2017" },
        { text: "2018", value: "2018" },
        { text: "2019", value: "2019" },
        { text: "2020", value: "2020" },
        { text: "2021", value: "2021" },
        { text: "2022", value: "2022" },
        { text: "2023", value: "2023" },
        { text: "2024", value: "2024" },
        { text: "2025", value: "2025" }
      ],
      negyedevek: [
        { text: "1. Negyedév", value: "1" },
        { text: "2. Negyedév", value: "2" },
        { text: "3. Negyedév", value: "3" },
        { text: "4. Negyedév", value: "4" }
      ]
    };
  },
  computed: {
    filteredList() {
      return this.items
        .filter(val =>
          this.searchTipus === "" ? true : this.searchTipus === val.tipus
        )
        .filter(val =>
          this.searchFelelos === "" ? true : this.searchFelelos === val.felelos
        );
    }
  },
  watch: {
    ev: function() {
      this.getData();
    },
    negyedev: function() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.items = [];
      this.isLoading = true;
      const riaszto = this.axios.get(
        `karbantartasok/riaszto_karbantartasok?ev=${this.ev}&negyedev=${this.negyedev}`
      );
      const video = this.axios.get(
        `karbantartasok/video_karbantartasok?ev=${this.ev}&negyedev=${this.negyedev}`
      );
      const belepteto = this.axios.get(
        `karbantartasok/belepteto_karbantartasok?ev=${this.ev}&negyedev=${this.negyedev}`
      );
      const tuzjelzo = this.axios.get(
        `karbantartasok/tuzjelzo_karbantartasok?ev=${this.ev}&negyedev=${this.negyedev}`
      );
      const karbcsoportok = this.axios.get(
        "karbantartasok/karbantartas_csoportok"
      );
      const diszpecser = this.axios.get(
        `karbantartasok/diszpecser_karbantartasok?ev=${this.ev}&negyedev=${this.negyedev}`
      );
      Promise.all([
        riaszto,
        video,
        belepteto,
        tuzjelzo,
        karbcsoportok,
        diszpecser
      ]).then(response => {
        for (let i = 0; i < response[4].data.length; i++) {
          if (response[4].data[i].id != 0)
            this.tipusok.push({
              value: response[4].data[i].nev,
              text: response[4].data[i].nev
            });
        }
        const data = union(
          response[0].data,
          response[1].data,
          response[2].data,
          response[3].data,
          response[5].data
        );
        let items = [];
        data.forEach((item, index) => {
          items.push({
            id: item.id,
            azonosito: item.azonosito,
            ugyfel: "",
            objektum: "",
            karbantartok: item.nev,
            felelos: item.felelos,
            munkalap: item.munkalapszama,
            datum: item.datum,
            igazolo: item.igazolta,
            muveletek: item.id,
            tipus: ""
          });
          if (item.azonosito.toString().charAt(0) == 5) {
            items[index].ugyfel = item.bizttech_ugyfelek_video.ugyfel;
            items[index].objektum = item.bizttech_ugyfelek_video.objektum;
            items[index].tipus = "Videórendszer";
          } else if (item.azonosito.toString().charAt(0) == 6) {
            items[index].ugyfel = item.bizttech_ugyfelek_belepteto.ugyfel;
            items[index].objektum = item.bizttech_ugyfelek_belepteto.objektum;
            items[index].tipus = "Beléptetőrendszer";
          } else if (item.azonosito.toString().charAt(0) == 7) {
            items[index].ugyfel = item.bizttech_ugyfelek_tuzjelzo.ugyfel;
            items[index].objektum = item.bizttech_ugyfelek_tuzjelzo.objektum;
            items[index].tipus = "Tűzjelzőrendszer";
          } else if (item.azonosito.toString().charAt(0) == 9) {
            items[index].ugyfel = item.bizttech_diszpecserkozpont.ugyfel;
            items[index].objektum = item.bizttech_diszpecserkozpont.objektum;
            items[index].tipus = "Diszpécserközpont";
          } else {
            items[index].ugyfel = item.bizttech_ugyfelek_riaszto.ugyfel;
            items[index].objektum = item.bizttech_ugyfelek_riaszto.objektum;
            items[index].tipus = "Riasztórendszer";
          }
        });
        this.items = items;
        this.isLoading = false;
      });
    }
  }
};
</script>

<style></style>
