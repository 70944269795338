import ProjektekBefejezetlen from "@/pages/projektek/befejezetlen";
import ProjektekBefejezett from "@/pages/projektek/befejezett";
import ProjektekID from "@/pages/projektek/_id";
import ProjektekNewProjekt from "@/pages/projektek/newProjekt";

export default [
  {
    name: "projektek-befejezetlen",
    path: "projektek/befejezetlen",
    component: ProjektekBefejezetlen
  },
  {
    name: "projektek-befejezett",
    path: "projektek/befejezett",
    component: ProjektekBefejezett
  },
  {
    name: "projektek-new-projekt",
    path: "projektek/newProjekt",
    component: ProjektekNewProjekt
  },
  {
    name: "projektek-id",
    path: "projektek/:id",
    component: ProjektekID
  }
];
