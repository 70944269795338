<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Kiadott ruhák</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="3" offset-md="9" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :custom-filter="customFilter"
                show-expand
                single-expand
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.dolgozo`]="{ item }">
                  {{ item.kiadas_dolgozoAdat.id }} -
                  {{ item.kiadas_dolgozoAdat.nev }}
                </template>
                <template v-slot:[`item.osszesen_darab`]="{ item }">
                  {{
                    sum(item.ruha_kiadas, 'kiadas_darab') -
                      sum(item.ruha_kiadas, 'visszavett')
                  }}
                  db
                </template>
                <template v-slot:[`item.osszesen_ara`]="{ item }">
                  {{ sumOsszesenAr(item.ruha_kiadas) }} Ft
                </template>
                <template v-slot:[`item.kint_darab`]="{ item }">
                  {{
                    sum(item.ruha_kiadas, 'kiadas_darab') -
                      sum(item.ruha_kiadas, 'visszavett')
                  }}
                  db
                </template>
                <template v-slot:[`item.vissza_darab`]="{ item }">
                  {{ sum(item.ruha_kiadas, 'visszavett') }} db
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-btn color="warning" @click="getKiadaslap(item.id)"
                    >Kiadás lap</v-btn
                  >
                </template>
                <template v-slot:expanded-item="{ item }">
                  <td colspan="9">
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="12" md="2">
                              Kiadott ruha
                            </v-col>
                            <v-col cols="12" md="2">
                              Kiadott darab
                            </v-col>
                            <v-col cols="12" md="2">
                              Kiadás ár
                            </v-col>
                            <v-col cols="12" md="2">
                              Visszavehető darab
                            </v-col>
                            <v-col cols="12" md="2">
                              Visszavett darab
                            </v-col>
                            <v-col cols="12" md="2">
                              Műveletek
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-for="(i, key) in item.ruha_kiadas"
                        :key="key"
                      >
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="12" md="2">
                              {{ i.kiadas_kiadottRuha.barcode }} -
                              {{ i.kiadas_kiadottRuha.ruha_nev }} -
                              {{ i.kiadas_kiadottRuha.ruha_meret }}
                            </v-col>
                            <v-col cols="12" md="2"
                              >{{ i.kiadas_darab }} db</v-col
                            >
                            <v-col cols="12" md="2"
                              >{{ i.kiadas_ar * i.kiadas_darab }} Ft</v-col
                            >
                            <v-col cols="12" md="2"
                              >{{ i.kiadas_darab - i.visszavett }} db</v-col
                            >
                            <v-col cols="12" md="2"
                              >{{ i.visszavett }} db</v-col
                            >
                            <v-col cols="12" md="2">
                              <v-btn
                                color="info"
                                :block="true"
                                :disabled="i.disabled"
                                @click="addVisszavetel(item, key)"
                                >Visszavétel</v-btn
                              ></v-col
                            >
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </td>
                </template>
              </v-data-table>
            </v-col>
            <v-col v-if="visszavetel.visszavettek.length > 0" cols="12">
              <v-row>
                <v-col cols="12" style="text-align: center;" mt-5>
                  <h1>Visszavétel</h1>
                </v-col>
                <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="2" px-1>
                  <v-text-field
                    v-model="visszavetel.visszavetel_datuma"
                    label="Visszavétel dátuma"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="2" px-1>
                  <v-select
                    v-model="visszavetel.visszavetel_ok"
                    label="Visszavétel oka"
                    :items="visszaOkok"
                  />
                </v-col>
                <v-col cols="12" md="3" px-1>
                  <v-text-field
                    v-model="visszavetel.visszavetel_oka"
                    label="Egyéb ok..."
                    :class="
                      visszavetel.visszavetel_ok !== 'Egyéb' ? 'hide' : ''
                    "
                  />
                </v-col>
                <v-col
                  v-for="(visszavett, key) in visszavetel.visszavettek"
                  :key="key"
                  cols="12"
                >
                  <v-row>
                    <v-col cols="12" md="3" px-1>
                      <v-text-field
                        label="Megnevezés"
                        :value="
                          `${visszavett.kiadas_kiadottRuha.barcode} - ${visszavett.kiadas_kiadottRuha.ruha_nev} - ${visszavett.kiadas_kiadottRuha.ruha_meret}`
                        "
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" md="1" px-1>
                      <v-text-field
                        v-model="visszavett.visszavetel_darab"
                        label="Vissza darab"
                        :max="visszavett.kiadas_darab - visszavett.visszavett"
                        min="0"
                        type="number"
                      />
                    </v-col>
                    <v-col cols="12" md="1" px-1>
                      <v-select
                        v-model="visszavett.visszavetel_tipus"
                        label="Visszavétel tipusa"
                        :items="visszaTipusok"
                      />
                    </v-col>
                    <v-col cols="12" md="2" px-1 mt-3>
                      <v-btn
                        color="primary"
                        :block="true"
                        @click="megseVisszavetel(visszavett, key)"
                        >Mégse</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :block="true"
                    color="success"
                    @click="createVisszavetel"
                  >
                    Visszavétel
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      {{ hibaMsg }}
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: 'Kiadott ruhák',
  data() {
    return {
      mentes: false,
      hiba: false,
      hibaMsg: '',
      isLoading: false,
      filter: '',
      items: [],
      headers: [
        {
          text: 'Azonosító',
          value: 'id',
        },
        {
          text: 'Dolgozó',
          value: 'dolgozo',
        },
        {
          text: 'Kiadás dátuma',
          value: 'kiadas_datuma',
        },
        {
          text: 'Összesen darab',
          value: 'osszesen_darab',
        },
        {
          text: 'Összesen ár',
          value: 'osszesen_ara',
        },
        {
          text: 'Kint darab',
          value: 'kint_darab',
        },
        {
          text: 'Visszavett darab',
          value: 'vissza_darab',
        },
        {
          text: 'Műveletek',
          value: 'muveletek',
        },
      ],
      visszavetel: {
        visszavetel_datuma: this.$moment().format('YYYY-MM-DD'),
        visszavetel_ok: 'Kilépő dolgozó',
        visszavetel_oka: '',
        visszavetel_dolgozo: 0,
        visszavettek: [],
      },
      visszaTipusok: [
        { value: 0, text: 'Teljes' },
        { value: 1, text: 'Használt' },
        { value: 2, text: 'Térítéses' },
        { value: 3, text: 'Selejt' },
      ],
      visszaOkok: ['Kilépő dolgozó', 'Munkakör változás', 'Csere', 'Egyéb'],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    customFilter(value, search, item) {
      const searchString = search.toString().toLowerCase();
      return (
        item.kiadas_dolgozoAdat.id.toString().indexOf(search.toString()) !==
          -1 ||
        item.kiadas_dolgozoAdat.nev
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.kiadas_datuma
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1
      );
    },
    createVisszavetel() {
      this.$axios
        .post('ruhak/createVisszavetel', {
          ...this.visszavetel,
          visszavetel_oka:
            this.visszavetel.visszavetel_ok !== 'Egyéb'
              ? this.visszavetel.visszavetel_ok
              : this.visszavetel.visszavetel_oka,
          visszavettek: this.visszavetel.visszavettek.filter(
            (x) => x.visszavetel_darab !== 0
          ),
        })
        .then((response) => {
          if (response.data.error) {
            this.hiba = true;
            this.hibaMsg = response.data.msg;
          } else {
            this.visszavetel = {
              visszavetel_datuma: this.$moment().format('YYYY-MM-DD'),
              visszavetel_ok: 'Kilépő dolgozó',
              visszavetel_oka: '',
              visszavetel_dolgozo: 0,
              visszavettek: [],
            };
            this.mentes = true;
            this.getData();
          }
        });
    },
    addVisszavetel(item, key) {
      item.selected = true;
      item.ruha_kiadas[key].disabled = true;
      this.items.forEach((val) => {
        if (!val.selected)
          val.ruha_kiadas.map((rval) => (rval.disabled = true));
      });
      this.visszavetel.visszavettek.push({
        ...item.ruha_kiadas[key],
        visszaID: item.id,
        visszaKey: key,
        visszavetel_darab: 0,
        visszavetel_tipus: 0,
      });
      this.visszavetel.visszavetel_dolgozo = item.kiadas_dolgozo;
    },
    megseVisszavetel(visszavett, key) {
      this.visszavetel.visszavettek.splice(key, 1);
      this.items.forEach((val) => {
        if (val.id === visszavett.visszaID)
          val.ruha_kiadas[visszavett.visszaKey].disabled = false;
      });
      if (this.visszavetel.visszavettek.length === 0) {
        this.items.map((val) => {
          val.selected = false;
          val.ruha_kiadas.map((rval) => {
            rval.disabled = false;
            rval.visszavetel_darab = 0;
          });
        });
      }
    },
    sumOsszesenAr(ruhak) {
      let ar = 0;
      ruhak.forEach(
        (ruha) => (ar += (ruha.kiadas_darab - ruha.visszavett) * ruha.kiadas_ar)
      );
      return ar;
    },
    sum(items, type) {
      let sum = 0;
      items.forEach((val) => (sum += val[type]));
      return sum;
    },
    getKiadaslap(kiadas_szama) {
      this.$axios
        .post('pdf/kiadaslap', { id: kiadas_szama })
        .then((response) => {
          window.open(
            window.location.protocol == 'http:'
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            '_blank'
          );
        });
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios.get('ruhak/kiadottRuhak').then((response) => {
        response.data.map((val) => {
          val.selected = false;
          val.ruha_kiadas.map((rval) => (rval.disabled = false));
        });
        this.items = response.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
