import TelefonokIndex from "@/pages/telefonok/index";
import TelefonokSzamlak from "@/pages/telefonok/szamlak";
import TelefonokID from "@/pages/telefonok/_id/index";
import TelefonokIDSzamla from "@/pages/telefonok/_id/szamlak/index";
import TelefonokSzamlaID from "@/pages/telefonok/_id/szamlak/_azon";
import TelefonokKimutatasok from "@/pages/telefonok/kimutatasok";
import TelefonokUjTelefon from "@/pages/telefonok/newTelefon";
import TelefonokUjSzamla from "@/pages/telefonok/newSzamla";
import TelefonokIDUjSzamla from "@/pages/telefonok/_id/newSzamla";

export default [
  {
    name: "telefonok-index",
    path: "telefonok",
    component: TelefonokIndex
  },
  {
    name: "telefon-kimutatasok",
    path: "telefonok/kimutatasok",
    component: TelefonokKimutatasok
  },
  {
    name: "telefonok-newTelefon",
    path: "telefonok/newTelefon",
    component: TelefonokUjTelefon
  },
  {
    name: "telefonok-szamlak",
    path: "telefonok/szamlak",
    component: TelefonokSzamlak
  },
  {
    name: "telefonok-ujszamla",
    path: "telefonok/newSzamla",
    component: TelefonokUjSzamla
  },
  {
    name: "telefonok-id",
    path: "telefonok/:id",
    component: TelefonokID
  },
  {
    name: "telefonok-idszamlak",
    path: "telefonok/:id/szamlak",
    component: TelefonokIDSzamla
  },
  {
    name: "telefonok-idujszamla",
    path: "telefonok/:id/newSzamla",
    component: TelefonokIDUjSzamla
  },
  {
    name: "telefonok-szamlaid",
    path: "telefonok/:id/szamlak/:azon",
    component: TelefonokSzamlaID
  }
];
