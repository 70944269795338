<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Autók</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="1" offset-md="8" class="py-0">
                  <v-select
                    v-model="deleted"
                    label="Törölt autók?"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :search="filter"
                :loading="isLoading"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="(item, key) in items"
                      :key="key"
                      :class="
                        item.rowType === 2
                          ? 'danger'
                          : item.rowType === 1
                          ? 'warn'
                          : ''
                      "
                    >
                      <td>{{ item.id }}</td>
                      <td>{{ item.marka }} {{ item.tipus }}</td>
                      <td>{{ item.rendszam }}</td>
                      <td>{{ item.hasznalja }}</td>
                      <td>{{ item.hasznalatbavetel }}</td>
                      <td>{{ item.muszaki_lejarat }}</td>
                      <td class="hide">{{ item.rowType }}</td>
                      <td>
                        <v-row>
                          <v-col cols="12" md="3">
                            <v-btn
                              :block="true"
                              :to="`/autok/${item.id}`"
                              color="success"
                            >
                              Adatlap
                            </v-btn>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-btn
                              :block="true"
                              :to="`/autok/${item.id}/tankolasok`"
                              color="primary"
                            >
                              Tankolás
                            </v-btn>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-btn
                              :block="true"
                              :to="`/autok/${item.id}/szervizek`"
                              color="info"
                            >
                              Szerviz
                            </v-btn>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-btn
                              :block="true"
                              :to="`/autok/${item.id}/menetlevelek`"
                              color="warning"
                            >
                              Menetlevél
                            </v-btn>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Autók",
  data() {
    return {
      isLoading: true,
      filter: this.$store.getters["getAutok"].filter,
      items: [],
      headers: [
        { text: "Azonosító", value: "azonosito" },
        { text: "Autó", value: "auto" },
        { text: "Rendszám", value: "rendszam" },
        { text: "Használja", value: "hasznalja" },
        { text: "Használatbavétel dátuma", value: "hasznalatbavetel_datuma" },
        { text: "Műszaki lejárat", value: "muszaki_lejarat" },
        { text: "Row Type", value: "rowType", class: "hide" },
        { text: "Műveletek", value: "muveletek" }
      ],
      igenNem: [{ value: 0, text: "Nem" }, { value: 1, text: "Igen" }],
      deleted: this.$store.getters["getAutok"].deleted
    };
  },
  watch: {
    deleted() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  beforeDestroy() {
    this.saveInfo();
  },
  methods: {
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios.get(`autok?deleted=${this.deleted}`).then(response => {
        let data = [];
        response.data.forEach((item, index) => {
          data.push({
            id: item.id,
            marka: item.marka,
            tipus: item.tipus,
            rendszam: item.rendszam,
            forgalmiszam: item.forgalmiszam,
            uzemanyag: item.uzemanyag,
            hasznalja: item.hasznalja,
            hasznalatbavetel: item.hasznalatbavetel,
            muszaki_lejarat: item.muszaki_lejarat,
            matrica_lejarat: item.matrica_lejarat,
            rowType: 0
          });
          if (item.muszaki_lejarat < this.$moment().format("YYYY-MM-DD"))
            data[index].rowType = 2;
          else if (
            item.muszaki_lejarat > this.$moment().format("YYYY-MM-DD") &&
            item.muszaki_lejarat <
              this.$moment()
                .add(2, "months")
                .format("YYYY-MM-DD")
          )
            data[index].rowType = 1;
        });
        this.items = data.sort((a, b) => b.rowType - a.rowType);
        this.isLoading = false;
      });
    },
    saveInfo() {
      this.$store.dispatch("saveAutok", {
        filter: this.filter,
        deleted: this.deleted
      });
    }
  }
};
</script>

<style lang="stylus"></style>
