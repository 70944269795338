<template>
  <v-col cols="12" md="4" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <h3>{{ vkey + 1 }}. fájl</h3>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field v-model="file.megnevezes" label="Megnevezés" />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-row v-if="file.name">
          <v-col cols="12" md="6">
            <v-text-field v-model="file.name" label="Fájl" readonly clearable />
          </v-col>
          <v-col cols="12" md="6">
            <v-btn color="warning" :block="true" @click="getFile(file.name)">
              Fájl
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" md="6">
            <v-text-field v-model="file.name" label="Fájl" disabled />
          </v-col>
          <v-col cols="12" md="6">
            <uploadButton
              ref="upButton"
              :esemeny-key="vkey"
              :type="'fájlt'"
              @melleklet="gotFile"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-textarea v-model="file.megjegyzes" label="Megjegyzés" />
      </v-col>
      <v-col cols="12">
        <v-btn :block="true" text color="error" @click="removeFile(vkey)">
          Törlés
        </v-btn></v-col
      >
    </v-row>
  </v-col>
</template>

<script>
import uploadButton from "@/components/uploadButton.vue";

export default {
  components: {
    uploadButton
  },
  props: ["file", "vkey", "getFile", "removeFile", "gotFile"]
};
</script>
