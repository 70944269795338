<template>
  <v-row>
    <v-col v-if="!newImage.imageUrl" class="pa-0">
      <v-btn :block="true" raised class="mt-3" @click="onPickFile">
        Válassz egy {{ type }}
      </v-btn>
    </v-col>
    <v-col v-else class="pa-0">
      <v-btn :block="true" raised class="error mt-3" @click="removeFile">
        Mégse: {{ newImage.fileName }}
      </v-btn>
    </v-col>
    <input ref="newImage" type="file" name="newImage" @change="onFilePicked" />
  </v-row>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "képet"
    },
    esemenyKey: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      newImage: {
        imageUrl: "",
        fileName: ""
      }
    };
  },
  methods: {
    onPickFile() {
      this.$refs.newImage.click();
    },
    onFilePicked(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (files && files[0]) {
        let filename = files[0].name;
        this.newImage.fileName = filename;
        if (filename && filename.lastIndexOf(".") <= 0) {
          return; //return alert('Please add a valid image!')
        }
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.newImage.imageUrl = fileReader.result;
        });
        fileReader.readAsDataURL(files[0]);
        if (this.esemenyKey != -1)
          this.$emit("melleklet", { file: files[0], key: this.esemenyKey });
        else this.$emit("kep", files[0]);
      }
    },
    removeFile() {
      this.newImage = {
        imageUrl: "",
        fileName: ""
      };
      this.$emit("removedKep");
    }
  }
};
</script>

<style lang="stylus">
input[type="file"] {
  position: absolute;
  left: -99999px;
}
</style>
