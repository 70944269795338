<template>
  <v-col cols="12" md="4" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <h3>{{ parseInt(vkey) + 1 }}. értesítendő</h3>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="ertesitendo.ertesit" label="Név"
      /></v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="ertesitendo.telszam" label="Telefonszám"
      /></v-col>
      <v-col cols="12">
        <v-btn
          color="error"
          :block="true"
          text
          @click="removeErtesitendoRiaszto(vkey)"
          >Törlés</v-btn
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["ertesitendo", "vkey", "removeErtesitendoRiaszto"]
};
</script>
