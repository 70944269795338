<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Raktárkészlet</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" offset-md="7" class="py-0">
                  <v-btn :block="true" color="primary" @click="exportKeszlet"
                    >Exportálás</v-btn
                  >
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                ref="ruhak"
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.darabar`]="{ item }">
                  {{ item.brutto_ar }} Ft
                </template>
                <template v-slot:[`item.raktar_darab`]="{ item }">
                  {{ item.raktar_darab }} db
                </template>
                <template v-slot:[`item.hasznalt_darab`]="{ item }">
                  {{ item.vissza_darab }} db
                </template>
                <template v-slot:[`item.kihordasi_ido`]="{ item }">
                  {{ item.kihordasi_ido }} hó
                </template>
                <template v-slot:[`item.osszar`]="{ item }">
                  {{ item.brutto_ar * item.raktar_darab }}
                  Ft
                </template>
                <template v-slot:[`body.append`]>
                  <tr>
                    <td colspan="6"></td>
                    <td>
                      <strong>Oldal összesen: </strong>
                    </td>
                    <td>{{ oldalAr() }} Ft</td>
                  </tr>
                  <tr>
                    <td colspan="6"></td>
                    <td>
                      <strong>Összesen: </strong>
                    </td>
                    <td>{{ teljesAr() }} Ft</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Raktárkészlet",
  data() {
    return {
      isLoading: true,
      filter: "",
      items: [],
      headers: [
        { text: "Vonalkód", value: "barcode" },
        { text: "Ruha név", value: "ruha_nev" },
        { text: "Kihordási idő", value: "kihordasi_ido" },
        { text: "Méret", value: "ruha_meret" },
        { text: "Darabár", value: "darabar" },
        { text: "Raktáron darab", value: "raktar_darab" },
        { text: "Használt darab", value: "hasznalt_darab" },
        { text: "Összár", value: "osszar" }
      ],
      igennem: [{ value: 0, text: "Nem" }, { value: 1, text: "Igen" }],
      ruhak: []
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.items = [];
      this.isLoading = true;
      this.axios.get(`ruhak/raktarkeszlet`).then(response => {
        this.items = response.data;
        this.isLoading = false;
      });
    },
    teljesAr() {
      let ar = 0;
      this.items.forEach(val => (ar += val.brutto_ar * val.raktar_darab));
      return ar;
    },
    oldalAr() {
      let ar = 0;
      if (this.$refs.ruhak)
        this.$refs.ruhak.internalCurrentItems.forEach(
          val => (ar += val.brutto_ar * val.raktar_darab)
        );
      return ar;
    },
    exportKeszlet() {
      this.$axios
        .post("/ruhak/exportKeszlet")
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/kimutatasok/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/kimutatasok/${response.data.fileName}`,
            "_blank"
          )
        );
    }
  }
};
</script>
