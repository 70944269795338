<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Visszavett ruhák</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="3" offset-md="9" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                show-expand
                single-expand
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.users_data`]="{ item }">
                  {{ item.users_data.id }} - {{ item.users_data.nev }}
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-btn color="warning" @click="getBevetellap(item.id)">
                    Bevételi lap
                  </v-btn>
                </template>
                <template v-slot:expanded-item="{ item }">
                  <td colspan="3">
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="12" md="3">
                              Ruha megnevezés
                            </v-col>
                            <v-col cols="12" md="2">
                              Visszavett darab
                            </v-col>
                            <v-col cols="12" md="2">
                              Térítéses
                            </v-col>
                            <v-col cols="12" md="2">
                              Visszavétel típusa
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-for="(visszavetel, key) in item.ruha_visszaveteleks"
                        :key="key"
                      >
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="12" md="3">
                              {{ visszavetel.ruha_adat.barcode }} -
                              {{ visszavetel.ruha_adat.ruha_nev }} -
                              {{ visszavetel.ruha_adat.ruha_meret }}
                            </v-col>
                            <v-col cols="12" md="2">
                              {{ visszavetel.visszavetel_darab }}
                            </v-col>
                            <v-col cols="12" md="2">
                              {{ visszavetel.fizetes === 1 ? 'Igen' : 'Nem' }}
                            </v-col>
                            <v-col cols="12" md="2">
                              {{
                                visszaTipusok[visszavetel.visszavetel_tipusa]
                                  .text
                              }}
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: 'Visszavett ruhák',
  data() {
    return {
      items: [],
      headers: [
        { text: 'Visszavétel sorszáma', value: 'id' },
        { text: 'Dolgozó', value: 'users_data' },
        { text: 'Visszavétel dátuma', value: 'visszavetel_datuma' },
        { text: 'Visszavétel oka', value: 'visszavetel_oka' },
        { text: 'Műveletek', value: 'muveletek' },
      ],
      isLoading: true,
      filter: '',
      visszaTipusok: [
        { value: 0, text: 'Teljes' },
        { value: 1, text: 'Használt' },
        { value: 2, text: 'Térítéses' },
        { value: 3, text: 'Selejt' },
      ],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios.get('ruhak/visszavettRuhak').then((response) => {
        this.items = response.data;
        this.isLoading = false;
      });
    },
    getBevetellap(kiadas_szama) {
      this.$axios
        .post('pdf/bevetellap', { id: kiadas_szama })
        .then((response) => {
          window.open(
            window.location.protocol == 'http:'
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            '_blank'
          );
        });
    },
  },
};
</script>

<style lang="stylus"></style>
