import HibakMegkezdett from "@/pages/hibak/megkezdett";
import HibakMegkezdetlen from "@/pages/hibak/megkezdetlen";
import HibakJavitott from "@/pages/hibak/javitott";
import HibakID from "@/pages/hibak/_id";
import HibakNewHiba from "@/pages/hibak/_id/newHiba";
import HibakAzon from "@/pages/hibak/_id/_azon";

export default [
  {
    name: "hibak-megkezdett",
    path: "hibak/megkezdett",
    component: HibakMegkezdett
  },
  {
    name: "hibak-megkezdetlen",
    path: "hibak/megkezdetlen",
    component: HibakMegkezdetlen
  },
  {
    name: "hibak-javitott",
    path: "hibak/javitott",
    component: HibakJavitott
  },
  {
    name: "hibak-newHiba",
    path: "hibak/:id/newHiba",
    component: HibakNewHiba
  },
  {
    name: "hibak-id",
    path: "hibak/:id",
    component: HibakID
  },
  {
    name: "hibak-azon",
    path: "hibak/:id/:azon",
    component: HibakAzon
  }
];
