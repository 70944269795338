<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">Új tankolás</h1>
                </v-col>
                <v-col cols="12" md="6" offset-md="3" class="py-0">
                  <v-autocomplete
                    id="newtankolas_valasztott"
                    v-model="valasztott"
                    label="Autó"
                    :items="autok"
                    item-value="id"
                    item-text="text"
                    :filter="filterAutok"
                  />
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="newtankolas_rogzitette"
                    v-model="data.rogzitette"
                    label="Rögzítette"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="newtankolas_datum"
                    v-model="data.datum"
                    label="Dátum"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="newtankolas_dolgozo"
                    v-model="data.dolgozo"
                    label="Dolgozó"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="newtankolas_km_ora"
                    v-model="data.km_ora"
                    label="Kilóméteróra állása"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="newtankolas_liter"
                    v-model="data.liter"
                    label="Tankolt Liter"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="newtankolas_ar"
                    v-model="data.ar"
                    label="Ár"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    label="Előző Kilóméteróra állás"
                    :value="data.elozo_km"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    label="Átlagfogyasztás"
                    :value="fogyasztas(data.liter, data.km_ora, data.elozo_km)"
                    :disabled="true"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-1 mt-5 d-none d-sm-flex"
              style="align-items: flex-start;"
            >
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="success"
                    :disabled="valasztott === 0"
                    @click="handleSubmit"
                  >
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn
                color="success"
                fab
                bottom
                right
                fixed
                @click="handleSubmit"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Új Tankolás",
  data() {
    return {
      data: {
        rogzitette: this.$store.getters.getUser.nev,
        datum: this.$moment().format("YYYY-MM-DD"),
        elozo_km: 0,
        deleted: 0
      },
      mentes: false,
      hiba: false,
      autok: [],
      valasztott: 0
    };
  },
  watch: {
    valasztott: function() {
      this.getElozo();
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    fogyasztas(liter, km_ora, elozo_km) {
      let fogy = liter / ((km_ora - elozo_km) / 100);
      if (isNaN(fogy)) return 0;
      return fogy.toFixed(2);
    },
    getElozo() {
      this.$axios
        .get(`autok/elozo?autoid=${this.valasztott}`)
        .then(
          response => (this.data.elozo_km = response.data ? response.data : 0)
        );
    },
    handleSubmit() {
      let autoData = this.data;
      autoData.autoid = this.valasztott;
      autoData.liter = autoData.liter.toString().replace(",", ".");
      this.$axios
        .post("autok/createTankolas", autoData)
        .then(() => {
          this.mentes = true;
          this.data = {
            rogzitette: this.$store.getters.getUser.nev,
            datum: this.$moment().format("YYYY-MM-DD"),
            elozo_km: 0,
            liter: null,
            km_ora: null,
            dolgozo: 0,
            ar: null
          };
          this.valasztott = 0;
        })
        .catch(() => (this.hiba = true));
    },
    getData() {
      this.$axios.get("autok?deleted=0").then(response => {
        let data = [];
        for (let item of response.data) {
          data.push({
            id: item.id,
            text: `${item.id} - ${item.rendszam} | ${item.marka} ${item.tipus}`
          });
        }
        this.autok = data;
      });
    },
    filterAutok(item, filterText) {
      const searchText = filterText.toLowerCase();
      const text = item.text.toLowerCase();
      return text.indexOf(searchText) > 1;
    }
  }
};
</script>
