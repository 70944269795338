<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Megkezdett hibajavítások</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="3" offset-md="9" class="py-0">
                  <v-text-field
                    v-model="filterMegkezdett"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :headers="headersMegkezdett"
                :items="itemsMegkezdett"
                :search="filterMegkezdett"
                :loading="isLoadingMegkezdett"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.azonosito`]="{ item }">
                  {{
                    `${item.azonosito} - ${item.ugyfel.ugyfel} - ${item.ugyfel.objektum}`
                  }}
                </template>
                <template v-slot:[`item.leiras`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.leiras.rovid }}</span>
                    </template>
                    <span>{{ item.leiras.hosszu }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-row md="6">
                    <v-col cols="12" md="6">
                      <v-btn
                        color="success"
                        :block="true"
                        :to="`/hibak/${item.azonosito}/${item.sorszam}`"
                        >Hiba Adatlap</v-btn
                      >
                    </v-col>
                    <v-col cols="12" md="6" pl-1>
                      <v-btn
                        color="warning"
                        :block="true"
                        @click="nyomtat(item.sorszam)"
                        >Nyomtatás</v-btn
                      >
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Megkezdett hibajavítások",
  data() {
    return {
      isLoadingMegkezdett: true,
      filterMegkezdett: "",
      itemsMegkezdett: [],
      headersMegkezdett: [
        { text: "Azonosító, Ügyfél", value: "azonosito" },
        { text: "Bejelentés dátuma", value: "bejelentes_datuma" },
        { text: "Hibajavító", value: "hibajavito" },
        { text: "Hiba leírása", value: "leiras" },
        { text: "Javítás megkezdése", value: "javitas_kezdes" },
        { text: "Műveletek", value: "muveletek" }
      ]
    };
  },
  beforeMount() {
    this.getMegkezdettData();
  },
  methods: {
    nyomtat(sorszam) {
      this.$axios
        .post("/pdf/hiba", { id: sorszam })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    getMegkezdettData() {
      this.itemsMegkezdett = [];
      this.isLoadingMegkezdett = true;
      this.$axios.get("bizttech/megkezdettHibak").then(response => {
        let items = [];
        response.data.forEach((item, index) => {
          items.push({
            sorszam: item.sorszam,
            azonosito: item.azonosito,
            bejelentes_datuma: item.bejelentes_datuma,
            bejelento: item.bejelento,
            hibajavito: item.munkavegzo,
            leiras: {},
            javitas_kezdes: item.kiadas_datuma,
            muveletek: item.sorszam,
            ugyfel:
              item.bizttech_ugyfelek_riaszto != null
                ? item.bizttech_ugyfelek_riaszto
                : item.bizttech_ugyfelek_video != null
                ? item.bizttech_ugyfelek_video
                : item.bizttech_ugyfelek_belepteto != null
                ? item.bizttech_ugyfelek_belepteto
                : item.bizttech_ugyfelek_tuzjelzo
          });
          if (item.hiba.length > 45)
            items[index].leiras = {
              rovid: item.hiba.substr(0, 45) + "...",
              hosszu: item.hiba
            };
          else items[index].leiras = { rovid: item.hiba, hosszu: item.hiba };
        });
        this.itemsMegkezdett = items;
        this.isLoadingMegkezdett = false;
      });
    }
  }
};
</script>

<style></style>
