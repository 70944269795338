<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12" class="py-0">
                  <h1 style="text-align: center;">Új Fegyver</h1>
                </v-col>
                <v-col cols="12" class="py-0"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.id"
                    label="Sorszám"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    id="fegyver_tipus"
                    v-model="data.tipus"
                    label="Típus"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    id="fegyver_ervenyesseg"
                    v-model="data.ervenyesseg"
                    label="Érvényesség"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    id="fegyver_fegyverszam"
                    v-model="data.fegyverszam"
                    label="Fegyverszám"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    id="fegyver_kaliber"
                    v-model="data.cal"
                    label="Kaliber"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    id="fegyver_tar"
                    v-model="data.tar"
                    label="Tár"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    id="fegyver_javitas"
                    v-model="data.javitas"
                    label="Javítás"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    id="fegyver_engedely"
                    v-model="data.engedely"
                    label="Engedély"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-textarea
                    id="fegyver_megjegyzes"
                    v-model="data.megjegyzes"
                    label="Megjegyzés"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select
                    id="fegyver_ceg"
                    v-model="data.ceg"
                    label="Cég"
                    :items="tipusok"
                    item-text="nev"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    id="fegyver_hely"
                    v-model="data.hely"
                    label="Hely"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-1 mt-5 d-none d-sm-flex"
              style="align-items: flex-start;"
            >
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="success" @click="sendCreate">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn color="success" fab bottom right fixed @click="sendCreate"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Új Fegyver",
  data() {
    return {
      data: {},
      mentes: false,
      hiba: false,
      tipusok: [
        { id: 0, nev: "Nincs megadva" },
        { id: 1, nev: "BON-SEC" },
        { id: 2, nev: "Pronob" }
      ]
    };
  },
  methods: {
    sendCreate() {
      this.$axios
        .post("ceginfo/createFegyverlejarat")
        .then(response => {
          this.$router.push("/ceginfo/fegyverlejarat/" + response.data.id);
        })
        .catch(() => {
          this.hiba = true;
        });
    }
  }
};
</script>

<style></style>
