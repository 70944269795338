<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Tankolások</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="1" offset-md="6" class="py-0">
                  <v-select
                    v-model="ev"
                    label="Év"
                    :items="evek"
                    item-text="name"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="honap"
                    label="Hónap"
                    :items="honapok"
                    item-text="name"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    append-icon="search"
                    label="Keresés"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :search="filter"
                :loading="isLoading"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.auto`]="{ item }">
                  {{ `${item.auto_adatok.marka} ${item.auto_adatok.tipus}` }}
                </template>
                <template v-slot:[`item.atlagfogyasztas`]="{ item }">
                  {{
                    `${fogyasztas(
                      item.liter,
                      item.km_ora,
                      item.elozo_km
                    )} l/100km`
                  }}
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-btn
                    :block="true"
                    :to="`/autok/${item.autoid}/tankolasok/${item.id}`"
                    color="success"
                  >
                    Adatlap
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Tankolások",
  data() {
    return {
      isLoading: true,
      filter: this.$store.getters.getTankolasok.filter,
      items: [],
      headers: [
        { text: "Azonosító", value: "id" },
        { text: "Autó", value: "auto" },
        { text: "Rendszám", value: "auto_adatok.rendszam" },
        { text: "Dátum", value: "datum" },
        { text: "Kilóméteróra állása", value: "km_ora" },
        { text: "Dolgozó", value: "dolgozo" },
        { text: "Ár", value: "ar" },
        { text: "Átlagfogyasztás", value: "atlagfogyasztas" },
        { text: "Műveletek", value: "muveletek" }
      ],
      igennem: [{ value: 0, text: "Nem" }, { value: 1, text: "Igen" }],
      deleted: 0,
      ev: this.$store.getters.getTankolasok.ev,
      honap: this.$store.getters.getTankolasok.honap,
      evek: [
        { id: "2007", name: "2007" },
        { id: "2008", name: "2008" },
        { id: "2009", name: "2009" },
        { id: "2010", name: "2010" },
        { id: "2011", name: "2011" },
        { id: "2012", name: "2012" },
        { id: "2013", name: "2013" },
        { id: "2014", name: "2014" },
        { id: "2015", name: "2015" },
        { id: "2016", name: "2016" },
        { id: "2017", name: "2017" },
        { id: "2018", name: "2018" },
        { id: "2019", name: "2019" },
        { id: "2020", name: "2020" },
        { id: "2021", name: "2021" },
        { id: "2022", name: "2022" },
        { id: "2023", name: "2023" },
        { id: "2024", name: "2024" },
        { id: "2025", name: "2025" }
      ],
      honapok: [
        { id: "01", name: "Január" },
        { id: "02", name: "Február" },
        { id: "03", name: "Március" },
        { id: "04", name: "Április" },
        { id: "05", name: "Május" },
        { id: "06", name: "Június" },
        { id: "07", name: "Július" },
        { id: "08", name: "Augusztus" },
        { id: "09", name: "Szeptember" },
        { id: "10", name: "Október" },
        { id: "11", name: "November" },
        { id: "12", name: "December" }
      ]
    };
  },
  watch: {
    ev() {
      this.getData();
    },
    honap() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  beforeDestroy() {
    this.saveInfo();
  },
  methods: {
    fogyasztas(liter, km_ora, elozo_km) {
      let fogy = liter / ((km_ora - elozo_km) / 100);
      if (isNaN(fogy)) return 0;
      return fogy.toFixed(2);
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios
        .get(`autok/tankolasok?datum=${this.ev}-${this.honap}`)
        .then(response => {
          this.items = response.data;
          this.isLoading = false;
        });
    },
    saveInfo() {
      this.$store.dispatch("saveTankolasok", {
        filter: this.filter,
        ev: this.ev,
        honap: this.honap
      });
    }
  }
};
</script>

<style lang="stylus"></style>
