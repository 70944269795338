<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title>
          <h2>Kutyák</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="1" offset-md="8" class="py-0">
                  <v-select
                    v-model="altatva"
                    label="Altatva"
                    :items="altatItems"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageOptions: itemsPerPage
                }"
                :items-per-page="itemsPerPageNumber"
              >
                <template v-slot:[`item.szuletesi_ido`]="{ item }">
                  {{
                    item.szuletesi_ido
                      ? $moment(item.szuletesi_ido, "YYYY-MM").format(
                          "YYYY MMMM"
                        )
                      : "Nincs megadva"
                  }}
                </template>
                <template v-slot:[`item.telephely_datum`]="{ item }">
                  {{
                    item.telephely_datum
                      ? $moment(item.telephely_datum, "YYYY-MM").format(
                          "YYYY MMMM"
                        )
                      : "Nincs megadva"
                  }}
                </template>
                <template v-slot:[`item.utolso_veszettseg_oltas`]="{ item }">
                  {{
                    item.utolso_veszettseg_oltas
                      ? $moment(item.utolso_veszettseg_oltas, "YYYY-MM").format(
                          "YYYY MMMM"
                        )
                      : "Nincs megadva"
                  }}
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        color="success"
                        :block="true"
                        :to="'/kutyak/' + item.id"
                        >Adatlap</v-btn
                      >
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Kutyák",
  data() {
    return {
      filter: "",
      items: [],
      headers: [
        { text: "Név", value: "nev" },
        { text: "Születési dátum", value: "szuletesi_ido" },
        { text: "Telephely", value: "telephely" },
        { text: "Kihelyezés dátuma", value: "telephely_datum" },
        {
          text: "Veszettség elleni védőoltás",
          value: "utolso_veszettseg_oltas"
        },
        { text: "Műveletek", value: "muveletek" }
      ],
      isLoading: false,
      altatva: 0,
      itemsPerPage: [5, 10, 25],
      itemsPerPageNumber: 10,
      altatItems: [{ text: "Nem", value: 0 }, { text: "Igen", value: 1 }]
    };
  },
  watch: {
    altatva() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios.get(`kutyak?altatva=${this.altatva}`).then(response => {
        this.items = response.data;
        this.itemsPerPage.push(response.data.length);
        this.itemsPerPageNumber = response.data.length;
        this.isLoading = false;
      });
    }
  }
};
</script>

<style lang="stylus"></style>
