<template>
  <v-row>
    <v-col cols="12" md="6" class="pa-0">
      <v-card flat>
        <v-card-title>
          <h2>Régiók</h2>
        </v-card-title>
        <v-card-text>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="success" v-on="on" @click="ujRegio">
                Új Régió
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <h2>Régió adatai</h2>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col md="3" cols="12">
                      <v-text-field
                        v-model="editedItem.azonosito"
                        label="Azonosító"
                      />
                    </v-col>
                    <v-col md="9" cols="12" style="padding-left: 5px;">
                      <v-text-field
                        v-model="editedItem.megnevezes"
                        label="Megnevezés"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.kihez_id"
                        label="Kihez"
                        hint="A dolgozó azonosítója, több dolgozó esetén ; -el elválasztva (pl.: 1;1774)"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.ceg"
                        label="Cég"
                        :items="cegek"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="dialog = false">Mégse</v-btn>
                <v-btn color="success" @click="save(editedItem)">Mentés</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="removeDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <h1>Biztosan törölni akarod?</h1>
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="removeDialog = false"
                  >Mégse</v-btn
                >
                <v-btn color="error" @click="torles(removeItem)">Törlés</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-data-table
            :items="items"
            :headers="headers"
            :search="filter"
            :loading="isLoading"
            :footer-props="{ showFirstLastPage: true }"
          >
            <template v-slot:[`item.muveletek`]="{ item }">
              <v-icon @click="editItem(item)">edit</v-icon>
              <v-icon @click="deleteItem(item)">delete</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" class="pa-0">
      <v-card flat>
        <v-card-title>
          <h2>Objektumok</h2>
        </v-card-title>
        <v-card-text>
          <v-dialog v-model="objektumDialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="success" v-on="on" @click="ujObjektum">
                Új Objektum
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <h2>Objektum adatai</h2>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="objektumEditedItem.regio_id"
                        label="Régió"
                        :items="items"
                        item-value="azonosito"
                        :filter="objektumFilter"
                      >
                        <template slot="selection" slot-scope="{ item }">
                          {{ item.azonosito }} - {{ item.megnevezes }}
                        </template>
                        <template slot="item" slot-scope="{ item }">
                          {{ item.azonosito }} - {{ item.megnevezes }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="objektumEditedItem.megnevezes"
                        label="Megnevezés"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="objektumDialog = false"
                  >Mégse</v-btn
                >
                <v-btn color="success" @click="saveObjektum(objektumEditedItem)"
                  >Mentés</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="removeObjektumDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <h1>Biztosan törölni akarod?</h1>
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="removeObjektumDialog = false"
                  >Mégse</v-btn
                >
                <v-btn color="error" @click="torlesObjektum(removeObjektumItem)"
                  >Törlés</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-data-table
            :items="objektumItems"
            :headers="objektumHeaders"
            :search="objFilter"
            :loading="isObjektumLoading"
            :footer-props="{ showFirstLastPage: true }"
          >
            <template v-slot:[`item.muveletek`]="{ item }">
              <v-icon @click="editObjektumItem(item)">edit</v-icon>
              <v-icon @click="deleteObjektumItem(item)">delete</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Régiók, Objektumok",
  data() {
    return {
      items: [],
      objektumItems: [],
      isLoading: true,
      isObjektumLoading: true,
      filter: "",
      objFilter: "",
      headers: [
        { text: "Azonosító", value: "azonosito" },
        { text: "Megnevezés", value: "megnevezes" },
        { text: "Kihez", value: "kihez_id" },
        { text: "Műveletek", value: "muveletek" }
      ],
      objektumHeaders: [
        { text: "ID", value: "id" },
        { text: "Megnevezés", value: "megnevezes" },
        { text: "Régió", value: "regio_id" },
        { text: "Műveletek", value: "muveletek" }
      ],
      dialog: false,
      objektumDialog: false,
      removeDialog: false,
      removeObjektumDialog: false,
      editedItem: {
        azonosito: "",
        megnevezes: "",
        kihez_id: "",
        ceg: ""
      },
      objektumEditedItem: {
        id: "",
        regio_id: "",
        megnevezes: ""
      },
      removeItem: {
        checkbox: false
      },
      removeObjektumItem: {
        checkbox: false
      },
      cegek: [
        { text: "Nincs megadva", value: 0 },
        { text: "BON-SEC Kft.", value: 1 },
        { text: "Pronobiscum Zrt.", value: 2 }
      ]
    };
  },
  beforeMount() {
    if (!this.$store.getters.getPrivileges.editDolgozo) this.$router.go(-1);
    this.getRegiok();
    this.getObjektumok();
  },
  methods: {
    getRegiok() {
      this.items = [];
      this.isLoading = true;
      this.$axios.get("dolgozok/regiok").then(response => {
        this.items = response.data;
        this.isLoading = false;
      });
    },
    getObjektumok() {
      this.objektumItems = [];
      this.isObjektumLoading = true;
      this.$axios.get("dolgozok/objektumok").then(response => {
        this.objektumItems = response.data;
        this.isObjektumLoading = false;
      });
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.removeItem = Object.assign({}, item);
      this.removeDialog = true;
    },
    editObjektumItem(item) {
      this.objektumEditedItem = Object.assign({}, item);
      this.objektumDialog = true;
    },
    deleteObjektumItem(item) {
      this.removeObjektumItem = Object.assign({}, item);
      this.removeObjektumDialog = true;
    },
    ujRegio() {
      this.editedItem = {
        azonosito: "",
        megnevezes: "",
        kihez_id: "",
        ceg: "",
        ujRegio: true
      };
    },
    ujObjektum() {
      this.objektumEditedItem = {
        id: "",
        regio_id: "",
        megnevezes: "",
        ujObjektum: true
      };
    },
    save(item) {
      if (item.ujRegio) {
        this.$axios.post("dolgozok/addRegio", item).then(() => {
          this.getRegiok();
          this.dialog = false;
        });
      } else {
        this.$axios.post("dolgozok/updateRegio", item).then(() => {
          this.getRegiok();
          this.dialog = false;
        });
      }
    },
    torles(item) {
      this.$axios.post("dolgozok/deleteRegio", item).then(() => {
        this.getRegiok();
        this.getObjektumok();
        this.removeDialog = false;
      });
    },
    saveObjektum(item) {
      if (item.ujObjektum) {
        this.$axios.post("dolgozok/addObjektum", item).then(() => {
          this.getObjektumok();
          this.objektumDialog = false;
        });
      } else {
        this.$axios.post("dolgozok/updateObjektum", item).then(() => {
          this.getObjektumok();
          this.objektumDialog = false;
        });
      }
    },
    torlesObjektum(item) {
      this.$axios.post("dolgozok/deleteObjektum", item).then(() => {
        this.getObjektumok();
        this.removeObjektumDialog = false;
      });
    },
    objektumFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      const regioNev = item.megnevezes.toLowerCase();
      const azon = item.azonosito.toString().toLowerCase();
      return regioNev.indexOf(searchText) > -1 || azon.indexOf(searchText) > -1;
    }
  }
};
</script>

<style></style>
