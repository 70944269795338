<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">Új hibabejelentés</h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.sorszam"
                    label="Sorszám"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.felvevo"
                    label="Felvevő"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.munkalap_szama"
                    label="Munkalap száma"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.megrendeles_szama"
                    label="Megrendelés száma"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.bejelentes_datuma"
                    label="Bejelentés dátuma"
                    type="date"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.bejelentes_ideje"
                    label="Bejelentés ideje"
                    type="time"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.bejelento" label="Bejelentő" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.bejelento_tel"
                    label="Bejelentő telefonszáma"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.kiadas_datuma"
                    label="Kiadás dátuma"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.javitas_datuma"
                    label="Javítás dátuma"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.munkavegzo" label="Munkavégzők" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select
                    v-model="data.javitva"
                    label="Javítva"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-textarea v-model="data.hiba" label="Hiba" />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-textarea v-model="data.intezkedes" label="Intézkedés" />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0 mt-5 d-none d-sm-flex"
              style="align-items: flex-start;"
            >
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="success" @click="sendCreate">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn color="success" fab bottom right fixed @click="sendCreate"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Új Hiba",
  data() {
    return {
      data: {
        felvevo: this.$store.getters.getUser.nev,
        bejelentes_datuma: this.$moment().format("YYYY-MM-DD"),
        bejelentes_ideje: this.$moment().format("HH:mm"),
        azonosito: this.$route.params.id,
        javitva: 0
      },
      mentes: false,
      hiba: false,
      igenNem: [{ text: "Igen", value: 1 }, { text: "Nem", value: 0 }]
    };
  },
  methods: {
    sendCreate() {
      this.$axios.post("bizttech/createHiba", this.data).then(response => {
        this.mentes = true;
        this.$router.push(
          `/hibak/${this.$route.params.id}/${response.data.id}`
        );
      });
    }
  }
};
</script>

<style></style>
