<template>
  <v-row>
    <v-col cols="12" style="text-align: center" class="py-0">
      <h1>Alapadatok</h1>
    </v-col>
    <v-col cols="12"> <hr class="thinLine" /> </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field v-model="beleptetoAdat.szerverhely" label="Szerver helye" />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field
        v-model="beleptetoAdat.softwaretipus"
        label="Software tipus"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field
        v-model="beleptetoAdat.regkulcs"
        label="Regisztrációs kulcs"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field v-model="beleptetoAdat.opckulcs" label="Opciós kulcs" />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field v-model="beleptetoAdat.licencszam" label="Licencszám" />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field v-model="beleptetoAdat.olvasoszam" label="Olvasó száma" />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field v-model="beleptetoAdat.user" label="Felhasználónév" />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field
        v-model="beleptetoAdat.pass"
        label="Jelszó"
        :append-icon="
          beleptetoAdat.passshow
            ? 'mdi-checkbox-outline'
            : 'mdi-checkbox-blank-outline'
        "
        @click:append="beleptetoAdat.passshow = !beleptetoAdat.passshow"
      />
    </v-col>
    <v-col cols="12" md="4" class="py-0">
      <v-text-field v-model="beleptetoAdat.kulso_ip" label="Külső IP cím" />
    </v-col>
    <v-col cols="12" md="4" class="py-0">
      <v-text-field v-model="beleptetoAdat.belso_ip" label="Belső IP cím" />
    </v-col>
    <v-col cols="12" md="4" class="py-0">
      <v-text-field v-model="beleptetoAdat.domain" label="Domain" />
    </v-col>
    <v-col v-if="beleptetoAdat.csatolok.length > 0" cols="12">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" style="text-align: center;" mt-5>
              <h1>Lan Csatolók</h1>
            </v-col>
            <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
          </v-row>
        </v-col>
        <belepteto-csatolo
          v-for="(csatolo, key) in beleptetoAdat.csatolok"
          :key="key"
          v-bind="{ csatolo, vkey: key, removeCsatolo }"
        />
      </v-row>
    </v-col>
    <v-col v-if="beleptetoAdat.bovitok.length > 0" cols="12">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" style="text-align: center;" mt-5>
              <h1>Bővítők</h1>
            </v-col>
            <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
          </v-row>
        </v-col>
        <belepteto-bovito
          v-for="(bovito, key) in beleptetoAdat.bovitok"
          :key="key"
          v-bind="{ bovito, vkey: key, removeBovito }"
        />
      </v-row>
    </v-col>
    <v-col v-if="beleptetoAdat.olvasok.length > 0" cols="12">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" style="text-align: center;" mt-5>
              <h1>Olvasók</h1>
            </v-col>
            <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
          </v-row>
        </v-col>
        <belepteto-olvaso
          v-for="(olvaso, key) in beleptetoAdat.olvasok"
          :key="key"
          v-bind="{ olvaso, vkey: key, removeOlvaso }"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import beleptetoCsatolo from "./components/beleptetoCsatolo";
import beleptetoBovito from "./components/beleptetoBovito";
import beleptetoOlvaso from "./components/beleptetoOlvaso";

export default {
  components: {
    beleptetoCsatolo,
    beleptetoBovito,
    beleptetoOlvaso
  },
  props: ["beleptetoAdat", "removeCsatolo", "removeBovito", "removeOlvaso"]
};
</script>
