<template>
  <v-row>
    <v-col
      cols="12"
      md="2"
      :class="{ fixedSave: !isMobile }"
      style="margin-top: 15px"
    >
      <v-row>
        <v-col cols="12" class="px-0 py-1 d-none d-sm-flex">
          <v-btn :block="true" color="info" @click="$router.go(-1)">
            Vissza
          </v-btn>
        </v-col>
        <v-col cols="12" class="px-0 py-1 d-none d-sm-flex">
          <v-btn :block="true" color="success" @click="handleSubmit">
            Mentés
          </v-btn>
        </v-col>
        <v-col v-if="!newUgyfel" cols="12" class="px-0 py-1">
          <v-btn color="warning" :block="true" :to="`/munkalapok/newMunkalap`">
            Új munkalap
          </v-btn>
        </v-col>
        <v-col v-if="!newUgyfel" cols="12" class="px-0 py-1">
          <v-btn
            color="warning"
            :block="true"
            :to="`/karbantartasok/${azonosito}/newKarbantartas`"
          >
            Új karbantartás
          </v-btn>
        </v-col>
        <v-col v-if="!newUgyfel" cols="12" class="px-0 py-1">
          <v-btn
            color="warning"
            :block="true"
            :to="`/hibak/${azonosito}/newHiba`"
          >
            Új hiba
          </v-btn>
        </v-col>
        <v-col v-if="!newUgyfel" cols="12" class="px-0 py-1">
          <v-btn
            color="primary"
            :block="true"
            :to="`/karbantartasok/${azonosito}`"
          >
            Karbantartások
          </v-btn>
        </v-col>
        <v-col v-if="!newUgyfel" cols="12" class="px-0 py-1">
          <v-btn color="primary" :block="true" :to="`/hibak/${azonosito}`">
            Hibák
          </v-btn>
        </v-col>
        <v-col v-if="rendszerTipus === 1" cols="12" class="pa-0 my-3">
          <riaszto-buttons
            v-bind="{
              nyomtatPdf,
              nyomtatUres,
              ujZonaRiaszto,
              ujErtesitendoRiaszto,
              ujKezeloRiaszto,
              ujBovitoRiaszto,
              newUgyfel
            }"
          />
        </v-col>
        <v-col v-else-if="rendszerTipus === 2" cols="12" class="pa-0">
          <video-buttons
            v-bind="{
              nyomtatPdf,
              nyomtatUres,
              ujNetworking,
              ujKamera,
              ujNano,
              ujTartomany,
              newUgyfel,
              ujVHalozati
            }"
          />
        </v-col>
        <v-col v-else-if="rendszerTipus === 3" cols="12" class="pa-0">
          <belepteto-buttons
            v-bind="{
              nyomtatPdf,
              nyomtatUres,
              ujCsatolo,
              ujBovito,
              newUgyfel,
              ujOlvaso
            }"
          />
        </v-col>
        <v-col v-else-if="rendszerTipus === 4" cols="12" class="pa-0">
          <tuzjelzo-buttons
            v-bind="{
              nyomtatPdf,
              nyomtatUres,
              ujErtesitendoTuzjelzo,
              newUgyfel
            }"
          />
        </v-col>
        <v-col v-else-if="rendszerTipus === 5" cols="12" class="pa-0">
          <diszpecser-buttons
            v-bind="{
              ujGepDiszpecser,
              newUgyfel
            }"
          />
        </v-col>
        <v-col
          v-if="rendszerTipus !== 0 && rendszerTipus !== 3"
          cols="12"
          class="px-0 py-1"
        >
          <v-btn color="warning" :block="true" @click="ujHalozati">
            Új V.N. Hálózati Eszköz
          </v-btn>
        </v-col>
        <v-col v-if="rendszerTipus !== 0" cols="12" class="px-0 py-1">
          <v-btn color="warning" :block="true" @click="ujFile"> Új fájl </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="d-sm-none">
      <v-btn color="info" fab bottom left fixed @click="$router.go(-1)">
        <v-icon>arrow_left</v-icon>
      </v-btn>
      <v-btn color="success" fab bottom right fixed @click="handleSubmit">
        <v-icon>save</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import riasztoButtons from "./riasztoButtons";
import videoButtons from "./videoButtons";
import beleptetoButtons from "./beleptetoButtons";
import tuzjelzoButtons from "./tuzjelzoButtons";
import diszpecserButtons from "./diszpecserButtons";

export default {
  components: {
    riasztoButtons,
    videoButtons,
    beleptetoButtons,
    tuzjelzoButtons,
    diszpecserButtons
  },
  props: [
    "isMobile",
    "handleSubmit",
    "azonosito",
    "ujFile",
    "nyomtatPdf",
    "nyomtatUres",
    "ujZonaRiaszto",
    "ujErtesitendoRiaszto",
    "ujNetworking",
    "ujKamera",
    "ujNano",
    "ujTartomany",
    "ujCsatolo",
    "ujBovito",
    "ujErtesitendoTuzjelzo",
    "rendszerTipus",
    "ujHalozati",
    "newUgyfel",
    "ujKezeloRiaszto",
    "ujBovitoRiaszto",
    "ujGepDiszpecser",
    "ujOlvaso",
    "ujVHalozati"
  ]
};
</script>

<style lang="stylus">
.fixedSave {
  position: fixed;
  right: 50px;
  width: 280px;
  top: 50px
}
</style>
