<template>
  <v-col cols="12" md="6" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <h3>{{ parseInt(vkey + 1) }}. wifi eszköz</h3>
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="nano.nev" label="Név" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="nano.gyarto" label="Gyártó" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="nano.tipus" label="Tipus" />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field v-model="nano.sorozatszam" label="Sorozatszám" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="nano.ip" label="IP" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="nano.tavoli_eleres" label="Távoli elérés" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="nano.ssid" label="SSID" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="nano.ssidpass"
          label="SSID Jelszó"
          :append-icon="
            nano.ssidpassshow
              ? 'mdi-checkbox-outline'
              : 'mdi-checkbox-blank-outline'
          "
          @click:append="nano.ssidpassshow = !nano.ssidpassshow"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="nano.nanouser" label="Felhasználónév" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="nano.nanopass"
          label="Jelszó"
          :append-icon="
            nano.nanopassshow
              ? 'mdi-checkbox-outline'
              : 'mdi-checkbox-blank-outline'
          "
          @click:append="nano.nanopassshow = !nano.nanopassshow"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-textarea v-model="nano.megjegyzes" label="Megjegyzés" />
      </v-col>
      <v-col cols="12">
        <v-btn color="error" text :block="true" @click="removeNano(vkey)">{{
          `${vkey + 1}. Wifi eszköz törlése`
        }}</v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["nano", "vkey", "removeNano"]
};
</script>
