<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">Új számla</h1>
                </v-col>
                <v-col cols="12" md="6" offset-md="3">
                  <v-autocomplete
                    v-model="data.telefonszam"
                    label="Telefon"
                    :items="telefonok"
                    item-value="id"
                    item-text="text"
                    :filter="telefonFilter"
                    @change="testThing"
                  >
                    <template v-slot:item="{ item }">
                      {{ item.id }} | {{ item.nev }} - {{ item.telszam }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.id }} | {{ item.nev }} - {{ item.telszam }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select v-model="ev" :items="evItems" label="Év" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select v-model="honap" :items="honapItems" label="Hónap" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.parkolas"
                    label="Parkolás"
                    suffix="Ft"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.osszeg"
                    label="Összeg"
                    suffix="Ft"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0 mt-3 d-none d-sm-flex"
              style="align-items: flex-start;"
            >
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="success" @click="handleSubmit">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn
                color="success"
                fab
                bottom
                right
                fixed
                @click="handleSubmit"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres feltöltés!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Új Számla",
  data() {
    return {
      data: {
        telefonszam: 0,
        parkolas: 0,
        osszeg: 0,
        nev: "",
        nev_szam: "",
        alapdij: 0,
        nevtelep: "",
        szolgaltato: 0
      },
      ev: this.$moment().format("YYYY"),
      honap: this.$moment().format("M"),
      mentes: false,
      hiba: false,
      telefonok: [],
      filter: "",
      honapItems: [
        { text: "Január", value: "1" },
        { text: "Február", value: "2" },
        { text: "Március", value: "3" },
        { text: "Április", value: "4" },
        { text: "Május", value: "5" },
        { text: "Június", value: "6" },
        { text: "Július", value: "7" },
        { text: "Augusztus", value: "8" },
        { text: "Szeptember", value: "9" },
        { text: "Október", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" }
      ],
      evItems: [
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025"
      ]
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    testThing(data) {
      const telefon = this.telefonok.find(x => x.id === data);
      this.data.nev = telefon.nev;
      this.data.nev_szam = telefon.telszam;
      this.data.alapdij = telefon.alapdij;
      this.data.nevtelep = telefon.nevtelep;
      this.data.szolgaltato = telefon.szolgaltato;
    },
    handleSubmit() {
      if (this.data.telefonszam !== 0) {
        this.$axios
          .post("telefonok/createSzamla", {
            ...this.data,
            targyho: this.ev + "-" + this.honap
          })
          .then(() => {
            this.mentes = true;
            const telefon = this.telefonok.find(
              x => x.id === this.data.telefonszam + 1
            );
            this.data = {
              telefonszam: telefon ? this.data.telefonszam + 1 : 0,
              osszeg: 0,
              parkolas: 0,
              nev: telefon ? telefon.nev : "",
              nev_szam: telefon ? telefon.telszam : "",
              alapdij: telefon ? telefon.alapdij : 0,
              nevtelep: telefon ? telefon.nevtelep : "",
              szolgaltato: telefon ? telefon.szolgaltato : 0
            };
          })
          .catch(() => (this.hiba = true));
      } else {
        this.hiba = true;
      }
    },
    getData() {
      this.$axios
        .get("telefonok?deleted=0")
        .then(response => (this.telefonok = response.data));
    },
    telefonFilter(item, queryText) {
      const searchText = queryText.toString().toLowerCase();
      const telefonSzam = item.telszam.toString().toLowerCase();
      const telefonNev = item.nev.toString().toLowerCase();
      const telefonId = item.id.toString().toLowerCase();
      return (
        telefonSzam.indexOf(searchText) > -1 ||
        telefonNev.indexOf(searchText) > -1 ||
        telefonId.indexOf(searchText) > -1
      );
    }
  }
};
</script>

<style></style>
