<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Felhasználó jogok</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="1" offset-md="8" class="py-0">
                  <v-select
                    v-model="bejelentkezhet"
                    label="Bejelentkezhet?"
                    :items="igenNem"
                    item-text="text"
                    item-value="value"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-dialog v-model="editDialog" max-width="900px">
                <v-card>
                  <v-card-title>
                    <v-row>
                      <v-col cols="12">
                        <h1 style="text-align: center;">Jogok szerkesztése</h1>
                      </v-col>
                      <v-col cols="12">
                        <h2 style="text-align: center;">
                          {{ editedItem.user_privileges.uid }} -
                          {{ editedItem.nev }}
                        </h2>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <h2>Ügyfelek</h2>
                      </v-col>
                      <v-col cols="12" class="py-3">
                        <v-divider />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.ugyfelek"
                          class="px-0 py-0"
                          label="Ügyfelek"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.projektek"
                          class="px-0 py-0"
                          label="Projektek"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.gps"
                          class="px-0 py-0"
                          label="GPS"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.hiba"
                          class="px-0 py-0"
                          label="Hibák"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.karbantartas"
                          class="px-0 py-0"
                          label="Karbantartások"
                          :items="igenNem"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <h2>Dolgozók</h2>
                      </v-col>
                      <v-col cols="12" class="py-3">
                        <v-divider />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.dolgozok"
                          class="px-0 py-0"
                          label="Dolgozók"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.editDolgozo"
                          class="px-0 py-0"
                          label="Dolgozó módosítás"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.mindenDolgozo"
                          class="px-0 py-0"
                          label="Minden dolgozó"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.elszamolas"
                          class="px-0 py-0"
                          label="Elszámolás"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.belepokartya"
                          class="px-0 py-0"
                          label="Belépőkártya"
                          :items="igenNem"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <h2>Egyéb</h2>
                      </v-col>
                      <v-col cols="12" class="py-3">
                        <v-divider />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.autok"
                          class="px-0 py-0"
                          label="Autók"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.ceginfo"
                          class="px-0 py-0"
                          label="Céginfó"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.leltar"
                          class="px-0 py-0"
                          label="Leltár"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.munkaruha"
                          class="px-0 py-0"
                          label="Munkaruha"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.raktar"
                          class="px-0 py-0"
                          label="Raktár"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.telefonok"
                          class="px-0 py-0"
                          label="Telefonok"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.kutyak"
                          class="px-0 py-0"
                          label="Kutyák"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.fegyverlejarat"
                          class="px-0 py-0"
                          label="Fegyverlejárat"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.jelentesek"
                          class="px-0 py-0"
                          label="Jelentések"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.jelentes_edit"
                          class="px-0 py-0"
                          label="Jelentések mentés"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.munkalapok"
                          class="px-0 py-0"
                          label="Munkalapok"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.fgsz_edit"
                          class="px-0 py-0"
                          label="FGSZ Szerkesztés"
                          :items="igenNem"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select
                          v-model="editedItem.user_privileges.fgsz_view"
                          class="px-0 py-0"
                          label="FGSZ Nézés"
                          :items="igenNem"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row style="margin: 0;">
                      <v-col cols="12" md="3">
                        <v-btn
                          color="error"
                          :block="true"
                          @click="editDialog = false"
                        >
                          Mégse
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="3" offset-md="6">
                        <v-btn
                          color="success"
                          :block="true"
                          @click="savePrivileges(editedItem.user_privileges)"
                        >
                          Mentés
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="resetDialog" max-width="500px">
                <v-card>
                  <v-card-title> <h1>Biztos vagy benne?</h1> </v-card-title>
                  <v-card-actions>
                    <v-btn color="info" @click="resetDialog = false">
                      Mégse
                    </v-btn>
                    <v-spacer />
                    <v-btn color="error" @click="sendReset(resetData)">
                      Helyreállítás
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-data-table
                :items="items"
                :headers="headers"
                :search="filter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.bejelentkezes`]="{ item }">
                  <v-edit-dialog
                    @close="saveLoginAllowed(item.loginAllowed, item.id)"
                  >
                    {{ item.loginAllowed ? "Igen" : "Nem" }}
                    <v-select
                      slot="input"
                      v-model="item.loginAllowed"
                      label="Bejelentkezhet?"
                      :items="igenNem"
                    />
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.homePage`]="{ item }">
                  <v-edit-dialog @close="saveHomepage(item.homePage, item.id)">
                    {{ item.homePage }}
                    <v-text-field
                      slot="input"
                      v-model="item.homePage"
                      label="Főoldal"
                    />
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.passwordReset`]="{ item }">
                  <v-btn
                    color="info"
                    :block="true"
                    @click="resetPassword(item.id, item.username)"
                    >Jelszó helyreállítása</v-btn
                  >
                </template>
                <template v-slot:[`item.jogok`]="{ item }">
                  <v-btn
                    :block="true"
                    color="warning"
                    @click="szerkesztJogok(item.user_data)"
                    >Jogok szerkesztése</v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Dolgozó jogok",
  data() {
    return {
      items: [],
      headers: [
        { text: "Azonosító", value: "id" },
        { text: "Név", value: "user_data.nev" },
        { text: "Felhasználónév", value: "username" },
        { text: "Bejelentkezhet?", value: "bejelentkezes" },
        { text: "Főoldal", value: "homePage" },
        {
          text: "Jelszó helyreállítása",
          value: "passwordReset"
        },
        { text: "Jogok", value: "jogok" }
      ],
      isLoading: true,
      filter: "",
      bejelentkezhet: 1,
      igenNem: [{ text: "Nem", value: 0 }, { text: "Igen", value: 1 }],
      editedIndex: -1,
      editedItem: {
        nev: "",
        user_privileges: {
          id: 0,
          uid: 0,
          ugyfelek: 0,
          hiba: 0,
          karbantartas: 0,
          dolgozok: 0,
          munkaruha: 0,
          raktar: 0,
          gps: 0,
          projektek: 0,
          belepokartya: 0,
          autok: 0,
          telefonok: 0,
          leltar: 0,
          elszamolas: 0,
          mindenDolgozo: 0,
          ceginfo: 0,
          editDolgozo: 0,
          kutyak: 0,
          fegyverlejarat: 0,
          jelentesek: 0,
          jelentes_edit: 0,
          munkalapok: 0,
          fgsz_edit: 0,
          fgsz_view: 0
        }
      },
      editDialog: false,
      resetDialog: false,
      resetData: {
        uid: "",
        username: ""
      }
    };
  },
  watch: {
    bejelentkezhet() {
      this.getData();
    }
  },
  beforeMount() {
    if (!this.$store.getters.getPrivileges.editDolgozo) this.$router.go(-1);
    this.getData();
  },
  methods: {
    getData() {
      this.items = [];
      this.$axios
        .get("auth/getPrivileges?allowed=" + this.bejelentkezhet)
        .then(response => {
          this.items = response.data;
        });
    },
    saveHomepage(newItem, uid) {
      this.$axios.post("auth/saveHomepage", { newItem, uid }).then(() => {
        this.getData();
      });
    },
    saveLoginAllowed(newItem, uid) {
      this.$axios.post("auth/saveLoginAllowed", { newItem, uid }).then(() => {
        this.getData();
      });
    },
    resetPassword(uid, username) {
      this.resetData = Object.assign({}, { uid, username });
      this.resetDialog = true;
    },
    sendReset(data) {
      this.$axios.post("auth/resetUser", data).then(() => {
        this.resetDialog = false;
      });
    },
    szerkesztJogok(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editDialog = true;
    },
    savePrivileges(item) {
      this.$axios.post("auth/savePrivileges", item).then(() => {
        this.editDialog = false;
      });
    }
  }
};
</script>

<style></style>
