<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      style="background-color: #33414e;"
      app
      :mini-variant.sync="mini"
      class="navigationDrawer"
    >
      <v-list style="padding-top: 0;" dense>
        <v-list-item
          style="background-color: #85c875; padding-left: 8px; height: 55px;"
        >
          <v-list-item-avatar style="border-radius: 0;">
            <v-img src="/logo_big.png" contain />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title style="padding-left: 6px;">
              <h1>{{ currentSite }}</h1>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item style="padding-left: 8px;">
          <v-list-item-avatar>
            <v-img :src="getUserPicture()" @error="imageid = 0" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title style="padding-left: 6px;">
              {{ getUserNev }}
            </v-list-item-title>
            <v-list-item-subtitle style="padding-left: 6px;">
              {{ getUserJob }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-group
          v-for="(link, key) in filteredLinkList"
          :key="key"
          v-model="linkStates[key]"
          class="navigation"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon
                :color="
                  link.name === 'Munkalapok' && newMunkalapCount > 0
                    ? 'red'
                    : 'initial'
                "
                >{{ link.icon }}</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(sublink, subkey) in link.links"
            :key="subkey"
            link
            :to="sublink.url"
            class="subnavigation"
          >
            <v-list-item-content>
              <v-list-item-title>{{ sublink.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-if="$store.getters.getPrivileges.elszamolas"
          link
          :to="'/elszamolas'"
          class="navigation"
        >
          <v-list-item-icon>
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Elszámolás</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$store.getters.getPrivileges.leltar"
          link
          :to="'/leltar'"
          style="display: none;"
          class="navigation"
        >
          <v-list-item-icon>
            <v-icon>mdi-list</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Leltár</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      height="56px"
      app
      flat
      style="background-color: #d4d4bd;"
      @click.stop="toContent"
    >
      <v-icon v-if="!mini" @click.stop="toContent">chevron_left</v-icon>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <!--
      <v-btn
        v-if="getPrivileges.fgsz_view"
        hidden
        text
        href="https://fgsz.bonsecinfo.hu"
        target="_blank"
      >
        FGSZ
      </v-btn>
      <v-btn hidden text @click="test()">
        Teszt
      </v-btn>
      <v-btn hidden text @click="test2()">
        Teszt2
      </v-btn>
      -->
      <v-spacer />
      <v-toolbar-title style="margin: 0 10px;">
        {{ getUsername }}
      </v-toolbar-title>
      <v-btn icon :to="'/passwordChange'">
        <v-icon>mdi-lock</v-icon>
      </v-btn>
      <v-btn icon @click="logOut()">
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container
        fluid
        class="fill-height"
        style="align-items: flex-start;"
        @click.stop="toContent"
      >
        <v-fade-transition :hide-on-leave="true">
          <router-view style="min-width: -webkit-fill-available;" />
        </v-fade-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      newMunkalapCount: 0,
      drawer: this.$store.getters.isMobile ? false : true,
      mini: true,
      linkStates: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      links: [
        {
          name: "Ügyfelek",
          icon: "mdi-security",
          links: [
            {
              name: "Új ügyfél",
              url: "/ugyfelek/newUgyfel",
              canEnter: this.$store.getters.getPrivileges.ugyfelek
            },
            {
              name: "Ügyfelek",
              url: "/ugyfelek",
              canEnter: this.$store.getters.getPrivileges.ugyfelek
            },
            {
              name: "Exportálás",
              url: "/ugyfelek/export",
              canEnter: this.$store.getters.getPrivileges.ugyfelek
            }
          ],
          access: this.$store.getters.getPrivileges.ugyfelek,
          disabled: false
        },
        {
          name: "Munkalapok",
          icon: "mdi-file",
          links: [
            {
              name: "Új munkalap",
              url: "/munkalapok/newMunkalap",
              canEnter: this.$store.getters.getPrivileges.munkalapok
            },
            {
              name: "Munkalapok",
              url: "/munkalapok",
              canEnter: this.$store.getters.getPrivileges.munkalapok
            }
          ],
          access: this.$store.getters.getPrivileges.munkalapok,
          disabled: false
        },
        {
          name: "Jelentések",
          icon: "mdi-file-alert",
          links: [
            {
              name: "Új jelentés",
              url: "/jelentesek/newJelentes",
              canEnter: this.$store.getters.getPrivileges.jelentes_edit
            },
            {
              name: "Jelentések",
              url: "/jelentesek",
              canEnter: this.$store.getters.getPrivileges.jelentesek
            }
          ],
          access: this.$store.getters.getPrivileges.jelentesek,
          disabled: false
        },
        {
          name: "Munkalapok",
          icon: "mdi-file-edit",
          links: [
            {
              name: "Új munkalap",
              url: "/munkalapok/newMunkalap",
              canEnter: this.$store.getters.getPrivileges.munkalapok
            },
            {
              name: "Munkalapok",
              url: "/munkalapok",
              canEnter: this.$store.getters.getPrivileges.munkalapok
            }
          ],
          access: this.$store.getters.getPrivileges.munkalapok,
          disabled: true
        },
        {
          name: "Hibák",
          icon: "mdi-alert-circle",
          links: [
            {
              name: "Megkezdett hibák",
              url: "/hibak/megkezdett",
              canEnter: this.$store.getters.getPrivileges.hiba
            },
            {
              name: "Megkezdetlen hibák",
              url: "/hibak/megkezdetlen",
              canEnter: this.$store.getters.getPrivileges.hiba
            },
            {
              name: "Javított hibák",
              url: "/hibak/javitott",
              canEnter: this.$store.getters.getPrivileges.hiba
            }
          ],
          access: this.$store.getters.getPrivileges.hiba,
          disabled: false
        },
        {
          name: "GPS Hibák",
          icon: "mdi-crosshairs-gps",
          links: [
            {
              name: "Új GPS hiba",
              url: "/gps/newGpsHiba",
              canEnter: this.$store.getters.getPrivileges.gps
            },
            {
              name: "Megkezdett GPS hibajavítások",
              url: "/gps/megkezdett",
              canEnter: this.$store.getters.getPrivileges.gps
            },
            {
              name: "Megkezdetlen GPS hibajavítások",
              url: "/gps/megkezdetlen",
              canEnter: this.$store.getters.getPrivileges.gps
            },
            {
              name: "Elvégzett GPS Hibajavítások",
              url: "/gps/kesz",
              canEnter: this.$store.getters.getPrivileges.gps
            }
          ],
          access: this.$store.getters.getPrivileges.gps,
          disabled: false
        },
        {
          name: "Karbantartások",
          icon: "mdi-cog",
          links: [
            {
              name: "Karbantartások lista",
              url: "/karbantartasok",
              canEnter: this.$store.getters.getPrivileges.karbantartas
            },
            {
              name: "Visszalévő karbantartások",
              url: "/karbantartasok/visszalevo",
              canEnter: this.$store.getters.getPrivileges.karbantartas
            }
          ],
          access: this.$store.getters.getPrivileges.karbantartas,
          disabled: false
        },
        {
          name: "Projektek",
          icon: "mdi-chart-pie",
          links: [
            {
              name: "Új Projekt",
              url: "/projektek/newProjekt",
              canEnter: this.$store.getters.getPrivileges.projektek
            },
            {
              name: "Befejezetlen projektek",
              url: "/projektek/befejezetlen",
              canEnter: this.$store.getters.getPrivileges.projektek
            },
            {
              name: "Befejezett projektek",
              url: "/projektek/befejezett",
              canEnter: this.$store.getters.getPrivileges.projektek
            }
          ],
          access: this.$store.getters.getPrivileges.projektek,
          disabled: false
        },
        {
          name: "Dolgozók",
          icon: "mdi-account-multiple",
          links: [
            {
              name: "Új dolgozó",
              url: "/dolgozok/newDolgozo",
              canEnter: this.$store.getters.getPrivileges.editDolgozo
            },
            {
              name: "Dolgozók",
              url: "/dolgozok",
              canEnter: this.$store.getters.getPrivileges.dolgozok
            },
            {
              name: "Lejárt engedélyek",
              url: "/dolgozok/lejart",
              canEnter: this.$store.getters.getPrivileges.dolgozok
            },
            {
              name: "Lejáró engedélyek",
              url: "/dolgozok/lejaro",
              canEnter: this.$store.getters.getPrivileges.dolgozok
            },
            {
              name: "Ki/Belépők adatai",
              url: "/dolgozok/kibelepok",
              canEnter: this.$store.getters.getPrivileges.dolgozok
            },
            {
              name: "Régiók, Objektumok",
              url: "/dolgozok/regiok",
              canEnter: this.$store.getters.getPrivileges.editDolgozo
            },
            {
              name: "Jogok",
              url: "/dolgozok/dolgozoJogok",
              canEnter: this.$store.getters.getPrivileges.editDolgozo
            }
          ],
          access: this.$store.getters.getPrivileges.dolgozok,
          disabled: false
        },
        {
          name: "Autók",
          icon: "mdi-car-side",
          links: [
            {
              name: "Új Autó",
              url: "/autok/newAuto",
              canEnter: this.$store.getters.getPrivileges.autok
            },
            {
              name: "Autók",
              url: "/autok",
              canEnter: this.$store.getters.getPrivileges.autok
            },
            {
              name: "Új tankolás",
              url: "/autok/newTankolas",
              canEnter: this.$store.getters.getPrivileges.autok
            },
            {
              name: "Új menetlevél",
              url: "/autok/newMenetlevel",
              canEnter: this.$store.getters.getPrivileges.autok
            },
            {
              name: "Tankolások",
              url: "/autok/tankolasok",
              canEnter: this.$store.getters.getPrivileges.autok
            }
          ],
          access: this.$store.getters.getPrivileges.autok,
          disabled: false
        },
        {
          name: "Telefonok",
          icon: "mdi-cellphone",
          links: [
            {
              name: "Új telefonszám",
              url: "/telefonok/newTelefon",
              canEnter: this.$store.getters.getPrivileges.telefonok
            },
            {
              name: "Előfizetések",
              url: "/telefonok",
              canEnter: this.$store.getters.getPrivileges.telefonok
            },
            {
              name: "Új számla",
              url: "/telefonok/newSzamla",
              canEnter: this.$store.getters.getPrivileges.telefonok
            },
            {
              name: "Számlák",
              url: "/telefonok/szamlak",
              canEnter: this.$store.getters.getPrivileges.telefonok
            },
            {
              name: "Kimutatások",
              url: "/telefonok/kimutatasok",
              canEnter: this.$store.getters.getPrivileges.telefonok
            }
          ],
          access: this.$store.getters.getPrivileges.telefonok,
          disabled: false
        },
        {
          name: "Munkaruha",
          icon: "mdi-tshirt-v",
          links: [
            {
              name: "Új ruha",
              url: "/ruhak/newRuha",
              canEnter: this.$store.getters.getPrivileges.munkaruha
            },
            {
              name: "Ruhák",
              url: "/ruhak",
              canEnter: this.$store.getters.getPrivileges.munkaruha
            },
            {
              name: "Új rendelés",
              url: "/ruhak/newRendeles",
              canEnter: this.$store.getters.getPrivileges.munkaruha
            },
            {
              name: "Rendelések",
              url: "/ruhak/rendelesek",
              canEnter: this.$store.getters.getPrivileges.munkaruha
            },
            {
              name: "Új kiadás",
              url: "/ruhak/newKiadas",
              canEnter: this.$store.getters.getPrivileges.munkaruha
            },
            {
              name: "Kiadások",
              url: "/ruhak/kiadasok",
              canEnter: this.$store.getters.getPrivileges.munkaruha
            },
            {
              name: "Visszavételek",
              url: "/ruhak/visszavetelek",
              canEnter: this.$store.getters.getPrivileges.munkaruha
            },
            {
              name: "Raktárkészlet",
              url: "/ruhak/raktarkeszlet",
              canEnter: this.$store.getters.getPrivileges.munkaruha
            }
          ],
          access: this.$store.getters.getPrivileges.munkaruha,
          disabled: false
        },
        {
          name: "Kutyák",
          icon: "mdi-dog-side",
          links: [
            {
              name: "Új kutya",
              url: "/kutyak/newKutya",
              canEnter: this.$store.getters.getPrivileges.kutyak
            },
            {
              name: "Kutyák",
              url: "/kutyak",
              canEnter: this.$store.getters.getPrivileges.kutyak
            }
          ],
          access: this.$store.getters.getPrivileges.kutyak,
          disabled: false
        },
        {
          name: "Raktár",
          icon: "mdi-view-dashboard",
          links: [
            {
              name: "Új eszköz",
              url: "",
              canEnter: this.$store.getters.getPrivileges.raktar
            },
            {
              name: "Eszközök",
              url: "",
              canEnter: this.$store.getters.getPrivileges.raktar
            },
            {
              name: "Új rendelés",
              url: "",
              canEnter: this.$store.getters.getPrivileges.raktar
            },
            {
              name: "Megrendelt eszközök",
              url: "",
              canEnter: this.$store.getters.getPrivileges.raktar
            },
            {
              name: "Eszközök raktáron",
              url: "",
              canEnter: this.$store.getters.getPrivileges.raktar
            },
            {
              name: "Új kiadás",
              url: "",
              canEnter: this.$store.getters.getPrivileges.raktar
            },
            {
              name: "Kiadott eszközök",
              url: "",
              canEnter: this.$store.getters.getPrivileges.raktar
            },
            {
              name: "Visszavett eszközök",
              url: "",
              canEnter: this.$store.getters.getPrivileges.raktar
            },
            {
              name: "Raktárkészlet",
              url: "",
              canEnter: this.$store.getters.getPrivileges.raktar
            }
          ],
          access: this.$store.getters.getPrivileges.raktar,
          disabled: true
        },
        {
          name: "Céginfo",
          icon: "mdi-information",
          links: [
            {
              name: "Fegyverlejárat",
              url: "/ceginfo/fegyverlejarat",
              canEnter: this.$store.getters.getPrivileges.fegyverlejarat
              // lövészeten kell résztvenni dolgózó
            },
            {
              name: "Engedélyek",
              url: "/ceginfo/engedelyek",
              canEnter: this.$store.getters.getPrivileges.ceginfo
            }
          ],
          access: this.$store.getters.getPrivileges.ceginfo,
          disabled: false
        }
      ],
      imageid: this.$store.getters.getUser.id
    };
  },
  computed: {
    getUserID() {
      return this.$store.getters.getUser.id || 0;
    },
    getUserNev() {
      return this.$store.getters.getUser.nev;
    },
    getUserJob() {
      return this.$store.getters.getUser.beosztas;
    },
    getUsername() {
      return this.$store.getters.getUser.username;
    },
    getPrivileges() {
      return this.$store.getters.getPrivileges;
    },
    isLoggedIn() {
      return this.$store.getters.getToken ? true : false;
    },
    filteredLinkList() {
      const sublinks = this.links.filter(vals => {
        const newLinks = vals.links
          .filter(svals => svals.canEnter == 1)
          .filter(svals =>
            this.$store.getters.getUser.id === 2565 &&
            svals.name === "Új munkalap"
              ? null
              : svals
          );
        vals.links = newLinks;
        return true;
      });
      return sublinks.filter(val => val.access == 1 && !val.disabled);
    },
    currentSite() {
      return process.env.VUE_APP_NAME;
    }
  },
  beforeMount() {
    if (!this.isLoggedIn) this.$router.push({ path: "/login" });
    this.$axios.defaults.headers.common[
      "Authorization"
    ] = this.$store.getters.getToken;
    this.$store
      .dispatch("updatePrivileges", {
        get: this.axios.get,
        uid: this.$store.getters.getUser.id
      })
      .catch(err => {
        if (err.error === 403) this.logOut();
      });
    if (this.$store.getters.getUser.id !== 2565) {
      this.$axios
        .get("munkalapok/newMunkalapCount")
        .then(res => (this.newMunkalapCount = res.data));
    }
  },
  methods: {
    getUserPicture() {
      return window.location.protocol == "http:"
        ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/userimages/${this.imageid}.jpg`
        : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/userimages/${this.imageid}.jpg`;
    },
    logOut() {
      this.$store.dispatch("doLogout").then(() => {
        this.$router.push({ path: "/login" });
      });
    },
    toContent() {
      this.mini = true;
      this.linkStates.fill(false);
    },
    test() {
      this.$axios.get("migrate/makeUserDb").then(res => console.log(res));
    },
    test2() {
      this.$axios.get("migrate/makeRegioDb").then(res => console.log(res));
    }
  }
};

Object.defineProperty(Array.prototype, "forEachDone", {
  enumerable: false,
  value: function(task, cb) {
    var counter = 0;
    this.forEach(function(item, index, array) {
      task(item, index, array);
      if (array.length === ++counter) {
        if (cb) cb();
      }
    });
  }
});
</script>

<style lang="stylus">
.headerImage .v-image__image--cover {
  background-size: contain!important;
}

.navigationDrawer {
  .v-list-item__icon .v-icon, .v-list-item__title {
    color: white;
  }

  .v-list-item__subtitle {
    color: lightgrey !important;
  }

  .v-list-item--active {
    background-color: #293744;
  }

  .v-list-item--active .v-icon {
    color: white !important;
  }

  .navigation {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .v-list-item--link:before {
    background-color: #293744;
  }

  .v-application a {
    color: #33414e !important;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .subnavigation {
    background-color: #33414e;
  }
}

.hide {
  display: none;
}

.thinLine {
  width: 60%;
  margin: 10px auto 30px auto;
}

.danger {
  background-color: #f5c6cb;
  &:hover {
      background-color: #f1b0b7!important;
  }
}

.warn {
  background-color: #ffeeba;
    &:hover {
        background-color: #ffe8a1!important;
    }
}
</style>
