<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0"
              ><v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">
                    {{ data.nev }}
                  </h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.id"
                    label="Sorszám"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.nev"
                    label="Név"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select v-model="data.nem" label="Nem" :items="kutyaNem" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="data.fajta" label="Fajta" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.szuletesi_ido"
                    label="Születési idő"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="data.szin" label="Szín" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select v-model="data.chip" label="Chip" :items="igenNem" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="data.chipszam" label="Chipszám" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="data.ivartalanitas"
                    label="Ivartalanítás"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="data.allapot" label="Állapot" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.tartasi_forma"
                    label="Tartási forma"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="data.taplalas" label="Táplálás" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="data.poraz"
                    label="Póráz"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="data.szajkosar"
                    label="Szájkosár"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="data.edenyek"
                    label="Edények"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="data.bolhairto"
                    label="Bolhaírtó"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="data.egeszsegugyi_konyv"
                    label="Egészségügyi könyv"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="data.altatva"
                    label="Altatva"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.utolso_veszettseg_oltas"
                    label="Utolsó veszettség elleni oltás"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.utolso_feregtelenites"
                    label="Utolsó féregtelenítés"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.orvos_neve" label="Orvos neve" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.orvos_telszam"
                    label="Orvos telefonszáma"
                  />
                </v-col>
                <v-col cols="12" md="2" offset-md="1" class="py-0">
                  <v-text-field
                    v-model="data.telephely"
                    label="Telephely neve"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.telephely_elerhetoseg"
                    label="Telephely elérhetősége"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.telephely_datum"
                    label="Kihelyezés dátuma"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.teruleti_vezeto_neve"
                    label="Területi vezető neve"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="data.teruleti_vezeto_telszam"
                    label="Területi vezető telefonszáma"
                  />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea v-model="data.egyeb" label="Egyéb" />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0 mt-3 d-none d-sm-flex"
              style="align-self: flex-start;"
            >
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="success" @click="sendUpdate">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn color="success" fab bottom right fixed @click="sendUpdate"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Kutya",
  data() {
    return {
      data: {},
      mentes: false,
      hiba: false,
      kutyaNem: [{ text: "Kan", value: 0 }, { text: "Szuka", value: 1 }],
      igenNem: [{ text: "Nem", value: 0 }, { text: "Igen", value: 1 }]
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    sendUpdate() {
      this.$axios
        .post("kutyak/updateKutya", this.data)
        .then(() => {
          this.mentes = true;
        })
        .catch(() => {
          this.hiba = true;
        });
    },
    getData() {
      this.$axios
        .get(`kutyak/${this.$route.params.id}`)
        .then(response => (this.data = response.data));
    }
  }
};
</script>

<style></style>
