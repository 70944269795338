<template>
  <v-app id="login">
    <v-main>
      <v-row>
        <v-col cols="12" sm="8" md="4" offset-sm="2" offset-md="4">
          <v-row>
            <v-col cols="12" class="loginLogo pb-0">
              <img src="/logo_big.png" />
            </v-col>
            <v-col cols="12">
              <v-card style="margin: 0 10px">
                <v-card-title class="loginTitle">
                  <h2 style="font-size: 21px;">
                    <p>{{ siteName }}</p>
                    <p>Adminisztrációs rendszer</p>
                  </h2>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        v-model="username"
                        type="text"
                        prepend-inner-icon="person"
                        label="Felhasználónév"
                        @keyup.enter="sendLogin"
                      />
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        v-model="password"
                        :type="show ? 'text' : 'password'"
                        prepend-inner-icon="lock"
                        label="Jelszó"
                        :append-icon="show ? 'visibility' : 'visibility_off'"
                        @keyup.enter="sendLogin"
                        @click:append="show = !show"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="success" @click="sendLogin">
                    Bejelentkezés
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-snackbar v-model="hiba" :timeout="3000" :bottom="true" color="error">
        {{ hibaMsg }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
  title: "Bejelentkezés",
  data() {
    return {
      username: "",
      password: "",
      hiba: false,
      hibaMsg: "",
      show: false
    };
  },
  computed: {
    siteName() {
      return process.env.VUE_APP_LONGNAME;
    }
  },
  beforeMount() {
    if (this.$store.getters.getToken ? true : false)
      this.$router.push({ path: this.$store.getters.getUser.homePage });
  },
  methods: {
    sendLogin() {
      this.$store
        .dispatch("doLogin", {
          post: this.axios.post,
          username: this.username,
          password: this.password
        })
        .then(response => {
          if (response.needPasswordChange)
            this.$router.push({ path: "passwordChange" });
          else this.$router.push({ path: response.homePage });
        })
        .catch(err => {
          this.hibaMsg = err.message;
          this.hiba = true;
        });
    }
  }
};
</script>

<style lang="stylus">
#login {
  background-color: #f0efe4;

  .loginLogo {
    text-align: center;
    margin: 50px 0;

    img {
      height: 220px;
    }
  }

  .loginTitle {
    background-color: #d4d4bd;
    padding: 5px;

    h2 {
      margin: 0 auto;
      text-align: center;

      p {
        margin: 0;
      }
    }
  }
}
</style>
