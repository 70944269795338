import GpsHibakMegkezdett from "@/pages/gps/megkezdett";
import GpsHibakMegkezdetlen from "@/pages/gps/megkezdetlen";
import GpsHibakNewGpsHiba from "@/pages/gps/newGpsHiba";
import GpsHibakID from "@/pages/gps/_id";
import GpsHibakKesz from "@/pages/gps/kesz";

export default [
  {
    name: "gps-megkezdett",
    path: "gps/megkezdett",
    component: GpsHibakMegkezdett
  },
  {
    name: "gps-megkezdetlen",
    path: "gps/megkezdetlen",
    component: GpsHibakMegkezdetlen
  },
  {
    name: "gps-newGpsHiba",
    path: "gps/newGpsHiba",
    component: GpsHibakNewGpsHiba
  },
  {
    name: "gps-kesz",
    path: "gps/kesz",
    component: GpsHibakKesz
  },
  {
    name: "gps-id",
    path: "gps/:id",
    component: GpsHibakID
  }
];
