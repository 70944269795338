<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">
                    {{ ruha.barcode }} - {{ ruha.ruha_nev }} -
                    {{ ruha.ruha_meret }} adatai
                  </h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ruha.barcode"
                    label="Vonalkód"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field v-model="ruha.ruha_nev" label="Név" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field v-model="ruha.ruha_meret" label="Méret" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ruha.brutto_ar"
                    label="Bruttó ár"
                    suffix="Ft"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ruha.kezelesi_koltseg"
                    label="Kezelési költség"
                    suffix="Ft"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ruha.kihordasi_ido"
                    label="Kihordási idő"
                    suffix="hó"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-select
                    v-model="ruha.beszallito"
                    :items="beszallitok"
                    item-text="nev"
                    item-value="id"
                    label="Beszállító"
                  />
                </v-col>
                <v-col md="8" />
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    :value="ruha.raktar_darab"
                    label="Raktár"
                    suffix="db"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    :value="ruha.vissza_darab"
                    label="Használt raktár"
                    suffix="db"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    :value="ruha.kiadva_darab"
                    label="Kiadva"
                    suffix="db"
                    :disabled="true"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="py-0 mt-3 d-none d-sm-flex">
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn color="info" :block="true" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn color="success" :block="true" @click="sendUpdate">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn color="success" fab bottom right fixed @click="sendUpdate"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Ruha",
  data() {
    return {
      ruha: {},
      beszallitok: [],
      mentes: false,
      hiba: false
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      const ruha = this.$axios.get(`ruhak/ruha?id=${this.$route.params.id}`);
      const beszallitok = this.$axios.get("ruhak/beszallitok");
      Promise.all([ruha, beszallitok]).then(response => {
        this.ruha = response[0].data;
        this.beszallitok = [
          { id: 0, nev: "Nincs megadva" },
          ...response[1].data
        ];
      });
    },
    sendUpdate() {
      this.$axios
        .post("ruhak/updateRuha", this.ruha)
        .then(() => (this.mentes = true));
    }
  }
};
</script>
