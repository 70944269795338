<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">
                    {{ $route.params.id }} | {{ data.datum }} - {{ data.ev }} /
                    {{ data.negyedev }} karbantartás
                  </h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.id"
                    label="Azonosító"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.datum"
                    label="Dátum"
                    type="date"
                    max="2999-12-31"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select
                    v-model="data.ev"
                    label="Év"
                    :items="evek"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select
                    v-model="data.negyedev"
                    label="Negyedév"
                    :items="negyedevek"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    id="szerelok"
                    v-model="data.nev"
                    label="Munkavégzők"
                    name="szerelok"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.felvette" label="Felvevő" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    id="igazolta"
                    v-model="data.igazolta"
                    label="Igazoló"
                    name="igazolta"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select
                    v-model="data.felelos"
                    label="Felelős"
                    :items="felelosok"
                    item-text="nev"
                    item-value="nev"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-textarea v-model="data.megjegyzes" label="Megjegyzés" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.munkalapszama"
                    label="Munkalap száma"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="py-0 mt-3"
              ><v-row>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/ugyfelek/${$route.params.id}`"
                  >
                    Ügyfél adatlap
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="info"
                    :to="`/karbantartasok/${$route.params.id}`"
                  >
                    Karbantartások
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="success" @click="sendUpdate">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn color="success" fab bottom right fixed @click="sendUpdate"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Karbantartás adatok",
  data() {
    return {
      data: {},
      mentes: false,
      hiba: false,
      evek: [
        { text: "2007", value: 2007 },
        { text: "2008", value: 2008 },
        { text: "2009", value: 2009 },
        { text: "2010", value: 2010 },
        { text: "2011", value: 2011 },
        { text: "2012", value: 2012 },
        { text: "2013", value: 2013 },
        { text: "2014", value: 2014 },
        { text: "2015", value: 2015 },
        { text: "2016", value: 2016 },
        { text: "2017", value: 2017 },
        { text: "2018", value: 2018 },
        { text: "2019", value: 2019 },
        { text: "2020", value: 2020 },
        { text: "2021", value: 2021 },
        { text: "2022", value: 2022 },
        { text: "2023", value: 2023 },
        { text: "2024", value: 2024 },
        { text: "2025", value: 2025 }
      ],
      negyedevek: [
        { text: "1. Negyedév", value: 1 },
        { text: "2. Negyedév", value: 2 },
        { text: "3. Negyedév", value: 3 },
        { text: "4. Negyedév", value: 4 }
      ],
      felelosok: []
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    sendUpdate() {
      this.$axios
        .post("karbantartasok/updateKarbantartas", this.data)
        .then(() => (this.mentes = true))
        .catch(() => (this.hiba = true));
    },
    getData() {
      const karb = this.$axios.get(
        `karbantartasok/karbantartas/${this.$route.params.azon}`
      );
      const karbcsoportok = this.$axios.get(
        "karbantartasok/karbantartas_csoportok"
      );
      Promise.all([karb, karbcsoportok]).then(results => {
        this.data = results[0].data;
        this.felelosok = results[1].data;
      });
    }
  }
};
</script>

<style></style>
