<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Visszalévő karbantartások</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <v-btn color="warning" :block="true" @click="listaNyomtatas"
                    >Lista nyomtatás</v-btn
                  >
                </v-col>
                <v-col cols="12" md="2" offset-md="4" class="py-0">
                  <v-select
                    v-model="valasztottFelelos"
                    label="Karbantartási csoport"
                    :items="karbCsoportok"
                    item-text="nev"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="filteredList"
                :headers="headers"
                :search="filter"
                :loading="isLoading"
                item-key="azonosito"
                show-expand
                single-expand
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-btn
                        :block="true"
                        :to="`/karbantartasok/${item.azonosito}`"
                        color="success"
                      >
                        Továbbiak
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" pl-1>
                      <v-btn
                        :block="true"
                        :to="
                          `/karbantartasok/${item.azonosito}/newKarbantartas`
                        "
                        color="info"
                      >
                        Új karbantartás
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template
                  v-slot:[`item.data-table-expand`]="{
                    item,
                    isExpanded,
                    expand
                  }"
                >
                  <v-icon
                    v-if="item.hibak.length > 0 && !isExpanded"
                    @click="expand(true)"
                    >mdi-chevron-down</v-icon
                  >
                  <v-icon
                    v-if="item.hibak.length > 0 && isExpanded"
                    style="transform: rotate(-180deg)"
                    @click="expand(false)"
                    >mdi-chevron-down</v-icon
                  >
                </template>
                <template v-slot:expanded-item="{ item }">
                  <td v-if="item.hibak.length > 0" colspan="8">
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="12" md="1">
                              Sorszám
                            </v-col>
                            <v-col cols="12" md="1">
                              Bejelentő
                            </v-col>
                            <v-col cols="12" md="1">
                              Bejelentés dátuma
                            </v-col>
                            <v-col cols="12" md="1">
                              Bejelentés ideje
                            </v-col>
                            <v-col cols="12" md="1">
                              Kiadás dátuma
                            </v-col>
                            <v-col cols="12" md="5">
                              Hiba leírása
                            </v-col>
                            <v-col cols="12" md="2">
                              Műveletek
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="(i, key) in item.hibak" :key="key">
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="12" md="1">
                              {{ i.sorszam }}
                            </v-col>
                            <v-col cols="12" md="1">
                              {{ i.bejelento }}
                            </v-col>
                            <v-col cols="12" md="1">
                              {{ i.bejelentes_datuma }}
                            </v-col>
                            <v-col cols="12" md="1">
                              {{ i.bejelentes_ideje }}
                            </v-col>
                            <v-col cols="12" md="1">
                              {{ i.kiadas_datuma }}
                            </v-col>
                            <v-col cols="12" md="5">
                              {{ i.hiba }}
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-btn
                                color="success"
                                :block="true"
                                :to="`/hibak/${i.azonosito}/${i.sorszam}`"
                                >Hiba Adatlap</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { union } from "lodash";

export default {
  title: "Visszalévő Karbantartások",
  data() {
    return {
      isLoading: true,
      filter: "",
      karbCsoportok: [],
      valasztottFelelos: 0,
      items: [],
      headers: [
        { text: "Azonosító", value: "azonosito" },
        { text: "Ügyfél", value: "ugyfel" },
        { text: "Objektum", value: "objektum" },
        { text: "Város", value: "varos" },
        { text: "Karbantartási ciklus", value: "karbciklus" },
        {
          text: "Utolsó Karbantartás",
          value: "utolso_karbantartas"
        },
        { text: "Műveletek", value: "muveletek" }
      ]
    };
  },
  computed: {
    filteredList() {
      return this.items.filter(val =>
        this.valasztottFelelos === 0
          ? true
          : this.valasztottFelelos === val.felelosid
      );
    }
  },
  beforeMount() {
    this.getMeta();
    this.getData();
  },
  methods: {
    listaNyomtatas() {
      this.$axios
        .post(`/pdf/visszakarb?karbcsoport=${this.valasztottFelelos}`)
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    getMeta() {
      this.$axios
        .get("karbantartasok/karbantartas_csoportok")
        .then(result => (this.karbCsoportok = result.data));
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      const riaszto = this.$axios.get(
        `karbantartasok/visszaRiaszto_karbantartasok?karbcsoport=${this.valasztottFelelos}`
      );
      const video = this.$axios.get(
        `karbantartasok/visszaVideo_karbantartasok?karbcsoport=${this.valasztottFelelos}`
      );
      const tuzjelzo = this.$axios.get(
        `karbantartasok/visszaTuzjelzo_karbantartasok?karbcsoport=${this.valasztottFelelos}`
      );
      const belepteto = this.$axios.get(
        `karbantartasok/visszaBelepteto_karbantartasok?karbcsoport=${this.valasztottFelelos}`
      );
      const diszpecser = this.$axios.get(
        `karbantartasok/visszaDiszpecser_karbantartasok?karbcsoport=${this.valasztottFelelos}`
      );
      Promise.all([riaszto, video, tuzjelzo, belepteto, diszpecser]).then(
        response => {
          const data = union(
            response[0].data,
            response[1].data,
            response[2].data,
            response[3].data,
            response[4].data
          );
          let items = [];
          data.forEach((item, index) => {
            items.push({
              azonosito: item.azonosito,
              ugyfel: item.ugyfel,
              objektum: item.objektum,
              varos: item.varos,
              felelos: item.karbcsoport,
              felelosid: item.bizttech_karbcsoport.id,
              karbciklus: "",
              utolso_karbantartas: {},
              hibak: item.bizttech_hibas,
              muveletek: item.azonosito
            });
            if (item.bizttech_karbantartasoks[0])
              items[index].utolso_karbantartas =
                this.$moment(item.bizttech_karbantartasoks[0].datum).format(
                  "YYYY"
                ) +
                " - " +
                this.$moment(item.bizttech_karbantartasoks[0].datum).format(
                  "Q"
                ) +
                " / " +
                item.bizttech_karbantartasoks[0].datum;
            if (item.karbciklus == 4) items[index].karbciklus = "Negyedéves";
            else if (item.karbciklus == 2) items[index].karbciklus = "Féléves";
            else items[index].karbciklus = "Nincs megadva";
          });
          items.sort(sortKarbantartas);
          this.items = items;
          this.isLoading = false;
        }
      );
    }
  }
};

const sortKarbantartas = (a, b) => {
  let a1 = a.varos.toLowerCase();
  let b1 = b.varos.toLowerCase();
  if (a1.localeCompare(b1) == 0) {
    let a2 = a.ugyfel.toLowerCase();
    let b2 = b.ugyfel.toLowerCase();
    if (a2.localeCompare(b2) == 0) {
      let a3 = a.objektum.toLowerCase();
      let b3 = b.objektum.toLowerCase();
      if (a3.localeCompare(b3) == 0) {
        let a4 = a.azonosito.toString();
        let b4 = a.azonosito.toString();
        return a4.localeCompare(b4);
      }
      return a3.localeCompare(b3);
    }
    return a2.localeCompare(b2);
  }
  return a1.localeCompare(b1);
};
</script>

<style></style>
