<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>
            Karbantartások | {{ ugyfel.azonosito }} - {{ ugyfel.ugyfel }} -
            {{ ugyfel.objektum }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" hidden-xs-only class="py-0">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2" offset-md="3" class="py-0">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/ugyfelek/${$route.params.id}`"
                  >
                    Ügyfél adatlap
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-btn
                    :block="true"
                    color="warning"
                    :to="`/karbantartasok/${$route.params.id}/newKarbantartas`"
                  >
                    Új Karbantartás
                  </v-btn>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.evnegyedev`]="{ item }">
                  {{ item.ev }} - {{ item.negyedev }}
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-btn
                    :block="true"
                    :to="`/karbantartasok/${$route.params.id}/${item.id}`"
                    color="success"
                  >
                    Adatlap
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="d-sm-none">
      <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
        ><v-icon>arrow_left</v-icon></v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Karbantartások",
  data() {
    return {
      isLoading: true,
      ugyfel: {},
      items: [],
      filter: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "Karbantartók", value: "nev" },
        { text: "Dátum", value: "datum" },
        { text: "Felvette", value: "felvette" },
        { text: "Munkalap száma", value: "munkalapszama" },
        { text: "Év - Negyedév", value: "evnegyedev" },
        { text: "Igazolta", value: "igazolta" },
        { text: "Műveletek", value: "muveletek" }
      ]
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.items = [];
      this.isLoading = true;
      let karb = this.$axios.get(
        `karbantartasok/karbantartasok/${this.$route.params.id}`
      );
      let ugyfel;
      switch (this.$route.params.id.charAt(0)) {
        case "0":
          ugyfel = this.$axios.get(
            "bizttech/riaszto_ugyfelek/" + this.$route.params.id
          );
          break;
        case "1":
          ugyfel = this.$axios.get(
            "bizttech/riaszto_ugyfelek/" + this.$route.params.id
          );
          break;
        case "5":
          ugyfel = this.$axios.get(
            "bizttech/video_ugyfelek/" + this.$route.params.id
          );
          break;
        case "6":
          ugyfel = this.$axios.get(
            "bizttech/belepteto_ugyfelek/" + this.$route.params.id
          );
          break;
        case "7":
          ugyfel = this.$axios.get(
            "bizttech/tuzjelzo_ugyfelek/" + this.$route.params.id
          );
          break;
      }
      Promise.all([karb, ugyfel]).then(responses => {
        this.items = responses[0].data;
        this.ugyfel = responses[1].data;
        this.isLoading = false;
      });
    }
  }
};
</script>

<style lang="stylus"></style>
