<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0"
              ><v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">
                    {{ data.sorszam }} | {{ data.eszkoz }}
                  </h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.sorszam"
                    label="Sorszám"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.felvevo"
                    label="Felvevő"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.eszkoz"
                    label="Eszköz"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.munkalap_szama"
                    label="Munkalap száma"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.bejelentes_datuma"
                    label="Bejelentés dátuma"
                    type="date"
                    max="2999-12-31"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.bejelentes_ideje"
                    label="Bejelentés ideje"
                    type="time"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.bejelento" label="Bejelentő" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.bejelento_tel"
                    label="Bejelentő telefonszáma"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.kiadas_datuma"
                    label="Kiadás dátuma"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.javitas_datuma"
                    label="Javítás dátuma"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="data.megrendeles_szama"
                    label="Megrendelés száma"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="data.munkavegzo" label="Munkavégzők" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea v-model="data.hiba" label="Hiba" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="py-1 mt-5">
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="warning" @click="nyomtatGps">
                    Nyomtatás
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="success" @click="sendUpdate">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn color="success" fab bottom right fixed @click="sendUpdate"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "GPS Hibajavítás adatok",
  data() {
    return {
      data: {},
      hiba: false,
      mentes: false
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    sendUpdate() {
      this.$axios.post("bizttech/updateGpsHiba", this.data).then(() => {
        this.mentes = true;
      });
    },
    nyomtatGps() {
      this.$axios
        .post("/pdf/gpshiba", { id: this.$route.params.id })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    getData() {
      this.$axios
        .get(`bizttech/gpsHiba/${this.$route.params.id}`)
        .then(response => (this.data = response.data));
    }
  }
};
</script>

<style></style>
