import JelentesekIndex from "@/pages/jelentesek/index";
import JelentesekID from "@/pages/jelentesek/_id";
import JelentesekNewJelentes from "@/pages/jelentesek/newJelentes";

export default [
  {
    name: "jelentesek-index",
    path: "jelentesek",
    component: JelentesekIndex
  },
  {
    name: "jelentesek-newJelentes",
    path: "jelentesek/newJelentes",
    component: JelentesekNewJelentes
  },
  {
    name: "jelentesek-id",
    path: "jelentesek/:id",
    component: JelentesekID
  }
];
