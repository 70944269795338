import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import hu from "vuetify/es5/locale/hu";

export default new Vuetify({
  lang: {
    locales: { hu },
    current: "hu"
  },
  icons: {
    iconfont: "mdi"
  }
});
