<template>
  <v-row>
    <v-col v-if="diszpecserSzamitogepek.length > 0" cols="12">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" style="text-align: center;" mt-5>
              <h1>Számítógépek</h1>
            </v-col>
            <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
          </v-row>
        </v-col>
        <diszpecser-szamitogep
          v-for="(szamitogep, key) in diszpecserSzamitogepek"
          :key="key"
          v-bind="{
            szamitogep,
            vkey: key,
            removeSzamitogep,
            ujSzamitogepUser,
            removeSzamitogepUser
          }"
        />
      </v-row>
    </v-col>
    <v-col cols="12" md="8">
      <v-autocomplete
        v-model="ujAzonosito"
        label="Új Telep azonosító"
        :items="filteredTelepData"
        :filter="telepFilter"
      >
        <template slot="selection" slot-scope="data">
          {{ data.item.azonosito }} | {{ data.item.ugyfel }} -
          {{ data.item.objektum }}
        </template>
        <template slot="item" slot-scope="data">
          {{ data.item.azonosito }} | {{ data.item.ugyfel }} -
          {{ data.item.objektum }}
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" pt-2>
      <v-btn
        color="primary"
        :disabled="ujAzonosito == null"
        tile
        :block="true"
        @click="
          ujAzonLista(ujAzonosito);
          ujAzonosito = null;
        "
      >
        Hozzáadás
      </v-btn>
    </v-col>
    <v-col v-if="diszpecserAzonlista.length > 0" cols="12">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" style="text-align: center;" mt-5>
              <h1>Azonosítók</h1>
            </v-col>
            <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
          </v-row>
        </v-col>
        <diszpecser-azonosito
          v-for="(azon, key) in diszpecserAzonlista"
          :key="key"
          v-bind="{
            azon,
            vkey: key,
            removeAzonosito
          }"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import DiszpecserSzamitogep from "./components/diszpecserSzamitogep";
import DiszpecserAzonosito from "./components/diszpecserAzonosito";

export default {
  components: {
    DiszpecserSzamitogep,
    DiszpecserAzonosito
  },
  props: [
    "telepData",
    "hasznaltTelepData",
    "diszpecserAzonlista",
    "ujAzonLista",
    "removeAzonosito",
    "diszpecserSzamitogepek",
    "removeSzamitogep",
    "ujSzamitogepUser",
    "removeSzamitogepUser"
  ],
  data() {
    return {
      ujAzonosito: null
    };
  },
  computed: {
    filteredTelepData() {
      const arr = [];
      this.hasznaltTelepData.forEach(val => arr.push(val.id));
      return this.telepData.filter(val => !arr.includes(val.azonosito));
    }
  },
  methods: {
    telepFilter(item, queryText) {
      const searchText = queryText.toString().toLowerCase();
      const ugyfelUgyfel = item.ugyfel.toLowerCase();
      const ugyfelAzon = item.azonosito.toLowerCase();
      const ugyfelObjektum = item.objektum.toLowerCase();
      return (
        ugyfelUgyfel.indexOf(searchText) > -1 ||
        ugyfelAzon.indexOf(searchText) > -1 ||
        ugyfelObjektum.indexOf(searchText) > -1
      );
    }
  }
};
</script>
