<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Ruhák</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="1" offset-md="8" class="py-0">
                  <v-select
                    v-model="deleted"
                    label="Törölt ruhák?"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.brutto_ar`]="{ item }">
                  {{ item.brutto_ar }} Ft
                </template>
                <template v-slot:[`item.kezelesi_koltseg`]="{ item }">
                  {{ item.kezelesi_koltseg }} Ft
                </template>
                <template v-slot:[`item.darabar`]="{ item }">
                  {{ item.brutto_ar + item.kezelesi_koltseg }} Ft
                </template>
                <template v-slot:[`item.beszallito`]="{ item }">
                  {{
                    item.beszallito > 0
                      ? beszallitok[item.beszallito - 1].nev
                      : "Nincs megadva"
                  }}
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-btn
                    :to="`/ruhak/${item.id}`"
                    color="success"
                    :block="true"
                  >
                    Adatlap
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Ruhák",
  data() {
    return {
      isLoading: true,
      filter: "",
      items: [],
      headers: [
        { text: "Azonosító", value: "id" },
        { text: "Vonalkód", value: "barcode" },
        { text: "Ruha név", value: "ruha_nev" },
        { text: "Méret", value: "ruha_meret" },
        { text: "Bruttó ár", value: "brutto_ar" },
        { text: "Kezelési költség", value: "kezelesi_koltseg" },
        { text: "Darabár", value: "darabar" },
        { text: "Kihordási idő", value: "kihordasi_ido" },
        { text: "Beszállító", value: "beszallito" },
        { text: "Műveletek", value: "muveletek" }
      ],
      igenNem: [{ value: 0, text: "Nem" }, { value: 1, text: "Igen" }],
      deleted: 0,
      beszallitok: []
    };
  },
  watch: {
    deleted: function() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.items = [];
      this.isLoading = true;
      const promise = this.$axios.get(`ruhak?deleted=${this.deleted}`);
      const beszallitok = this.$axios.get("ruhak/beszallitok");
      Promise.all([promise, beszallitok]).then(responses => {
        this.items = responses[0].data;
        this.beszallitok = responses[1].data;
        this.isLoading = false;
      });
    }
  }
};
</script>

<style lang="stylus"></style>
