<template>
  <v-col cols="12" md="6" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0"
        ><h3>{{ vkey + 1 }}. csatoló</h3></v-col
      >
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="csatolo.nev" label="Név" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="csatolo.ip" label="IP" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="csatolo.cim" label="Címzés" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="csatolo.gyarto" label="Gyártó" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="csatolo.tipus" label="Tipus" />
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field v-model="csatolo.sorozatszam" label="Sorozatszám" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="csatolo.felhasznalonev" label="Felhasználónév" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="csatolo.jelszo"
          label="Jelszó"
          :append-icon="
            csatolo.jelszoshow
              ? 'mdi-checkbox-outline'
              : 'mdi-checkbox-blank-outline'
          "
          @click:append="csatolo.jelszoshow = !csatolo.jelszoshow"
        />
      </v-col>
      <v-col cols="12">
        <v-btn color="error" text :block="true" @click="removeCsatolo(vkey)">{{
          `${vkey + 1}. csatoló törlése`
        }}</v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["csatolo", "vkey", "removeCsatolo"]
};
</script>
