<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Menetlevelek</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" hidden-xs-only class="pb-0 pt-2">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2" offset-md="5" class="py-0">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="
                      `/autok/${$route.params.id}/menetlevelek/newMenetlevel`
                    "
                  >
                    Új Menetlevél
                  </v-btn>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-btn
                        :block="true"
                        color="success"
                        :to="`/autok/${item.autoid}/menetlevelek/${item.id}`"
                      >
                        Adatlap
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-btn
                        :block="true"
                        color="warning"
                        @click="downloadMenetlevel(item.fajlnev)"
                      >
                        Letöltés
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="d-sm-none">
      <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
        ><v-icon>arrow_left</v-icon></v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Menetlevelek",
  data() {
    return {
      isLoading: true,
      filter: this.$store.getters["getMenetlevel"],
      items: [],
      headers: [
        { text: "Azonosító", value: "id" },
        { text: "Dátum", value: "datum" },
        { text: "Fájlnév", value: "fajlnev" },
        { text: "Műveletek", value: "muveletek" }
      ]
    };
  },
  beforeMount() {
    this.getData();
  },
  beforeDestroy() {
    this.saveInfo();
  },
  methods: {
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios
        .get(`autok/menetlevelekid?autoid=${this.$route.params.id}`)
        .then(response => {
          this.items = response.data;
          this.isLoading = false;
        });
    },
    downloadMenetlevel(name) {
      window.open(
        window.location.protocol == "http:"
          ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/menetlevelek/${name}`
          : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/menetlevelek/${name}`,
        "_blank"
      );
    },
    saveInfo() {
      this.$store.dispatch("saveMenetlevel", this.filter);
    }
  }
};
</script>

<style lang="stylus"></style>
