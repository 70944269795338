<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title>
          <h2>Lejárt engedélyek</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" offset-md="7" class="py-0">
                  <v-select
                    v-model="tipusLejart"
                    :items="tipusok"
                    label="Engedély típusa"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filterLejart"
                    append-icon="search"
                    label="Keresés"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="filteredList"
                :loading="loadingLejart"
                :headers="headers"
                :footer-props="{ showFirstLastPage: true }"
                :search="filterLejart"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, key) in items" :key="key" class="danger">
                      <td>{{ item.azonosito }}</td>
                      <td>
                        <span>{{ item.nev }}</span>
                        <span
                          style="color: red;"
                          title="Egyszerűsített foglalkozatás"
                          >{{ item.fogltipus === 5 ? "*" : "" }}</span
                        >
                      </td>
                      <td>{{ item.regio }}</td>
                      <td>{{ item.ceg }}</td>
                      <td>{{ item.engedely }}</td>
                      <td>{{ item.lejarat }}</td>
                      <td>
                        <v-row>
                          <v-col cols="12" md="6" pr-1>
                            <v-btn
                              color="success"
                              :block="true"
                              :to="`/dolgozok/${item.azonosito}`"
                              >Adatlap</v-btn
                            >
                          </v-col>
                          <v-col
                            v-if="
                              item.tipus === 1 ||
                                item.tipus === 2 ||
                                item.tipus === 3 ||
                                item.tipus === 5 ||
                                item.tipus === 8 ||
                                item.tipus === 9
                            "
                            cols="12"
                            md="6"
                          >
                            <v-btn
                              color="warning"
                              :block="true"
                              @click="lejarNyomtat(item.engid)"
                              >Nyomtatás
                              <v-icon v-if="item.jelzett">error</v-icon></v-btn
                            >
                          </v-col>
                          <v-col v-else-if="item.tipus === 6" cols="12" md="6">
                            <v-btn
                              v-if="
                                kulonUID.includes(parseInt(item.azonosito)) ||
                                  kulonID.includes(parseInt(item.regioid))
                              "
                              color="warning"
                              :block="true"
                              @click="lejarMunkaZ(item.engid)"
                              >Nyomtatás
                              <v-icon v-if="item.jelzett">error</v-icon></v-btn
                            >
                            <v-btn
                              v-else
                              color="warning"
                              :block="true"
                              @click="lejarMunka(item.engid)"
                            >
                              Nyomtatás
                              <v-icon v-if="item.jelzett">error</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Lejárt engedélyek",
  data() {
    return {
      filterLejart: "",
      itemsLejart: [],
      loadingLejart: true,
      tipusLejart: "",
      headers: [
        { text: "Azonosító", value: "azonosito" },
        { text: "Név", value: "nev" },
        { text: "Régió", value: "regio" },
        {
          text: "Cég",
          value: "ceg"
        },
        { text: "Engedély", value: "engedely" },
        { text: "Lejárat", value: "lejarat" },
        { text: "Műveletek", value: "muveletek" }
      ],
      tipusok: [],
      kulonUID: [],
      kulonID: []
    };
  },
  computed: {
    mindenDolgozo() {
      return this.$store.getters.getPrivileges.mindenDolgozo;
    },
    cUser() {
      return this.$store.getters.getUser.id;
    },
    filteredList() {
      return this.itemsLejart.filter(val =>
        this.tipusLejart === "" ? true : this.tipusLejart === val.engedely
      );
    }
  },
  beforeMount() {
    this.getMeta();
    this.getLejart();
  },
  methods: {
    lejarMunka(id) {
      this.$axios
        .post("/pdf/munkaalk_new", { id: id })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    lejarMunkaZ(id) {
      this.$axios
        .post("/pdf/munkaalk_new", { id: id })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    lejarNyomtat(id) {
      this.$axios
        .post("/pdf/lejarNyomtat", { id: id })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    getLejart() {
      this.itemsLejart = [];
      this.loadingLejart = true;
      this.$axios
        .get(
          `dolgozok/users_lejart?mindenDolgozo=${this.mindenDolgozo}&cUser=${this.cUser}`
        )
        .then(response => {
          let data = [];
          response.data.forEach(item => {
            data.push({
              azonosito: item.uid,
              nev: item.user_data.nev,
              beosztas: item.user_data.meta_beosztas.megnevezes,
              regio: item.user_data.meta_regio.megnevezes,
              objektum: item.user_data.meta_objektum.megnevezes,
              ceg: item.user_data.meta_aktualisceg.megnevezes,
              engedely: item.engedely_tipus.megnevezes,
              lejarat: item.lejarat,
              tipus: item.tipus,
              regioid: item.user_data.meta_regio.azonosito,
              engid: item.id,
              jelzett: item.jelzett,
              fogltipus: item.user_data.fogltipus
            });
          });
          this.itemsLejart = data;
          this.loadingLejart = false;
        });
    },
    getMeta() {
      const promise = this.$axios.get("meta/engedelytipusok");
      const kulonID = this.$axios.get("meta/getKulonID");
      Promise.all([promise, kulonID]).then(responses => {
        this.tipusok.push({ text: "Nincs megadva", value: "" });
        for (let item of responses[0].data) {
          this.tipusok.push({
            text: item.megnevezes,
            value: item.megnevezes
          });
        }
        for (let item of responses[1].data) {
          if (item.tipus == 1) this.kulonUID.push(item.azonosito);
          else if (item.tipus == 2) this.kulonID.push(item.azonosito);
        }
      });
    }
  }
};
</script>
