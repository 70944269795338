<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <v-card flat>
        <v-card-title>
          <h2>Beosztás készítés</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2" class="px-1">
              <v-autocomplete
                v-model="info.regio_id"
                :items="regiok"
                label="Régió"
                item-value="azonosito"
                :item-text="getItemText"
              />
            </v-col>
            <v-col cols="12" md="2" class="px-1">
              <v-select
                v-model="info.objektum_azon"
                :items="objektumok"
                label="Objektum"
                item-value="id"
                item-text="megnevezes"
                :disabled="isDisabled"
              />
            </v-col>
            <v-col cols="12" md="2" class="px-1">
              <v-select v-model="genEv" label="Év" :items="evek" />
            </v-col>
            <v-col cols="12" md="2" class="px-1">
              <v-select v-model="genHonap" label="Hónap" :items="honapok" />
            </v-col>
            <v-col cols="12" md="2" class="px-1 mt-3">
              <v-dialog
                v-model="dolgozoDialog"
                width="700px"
                :disabled="genDisabled"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    :disabled="genDisabled"
                    :block="true"
                    v-on="on"
                  >
                    Dolgozók szerkesztése
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title primary-title>
                    <h3>Dolgozók szerkesztése</h3>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col
                        v-for="(dolgozo, key) in sendDolgozok"
                        :key="key"
                        cols="12"
                      >
                        <v-row v-if="dolgozo.newDolgozo">
                          <v-col cols="12" md="5">
                            <v-autocomplete
                              v-model="dolgozo.id"
                              :items="dolgozok"
                              label="Új dolgozó"
                              item-value="id"
                              :item-text="getNev"
                              @input="setData(dolgozo.id, key)"
                            />
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model.number="dolgozo.foglora"
                              label="Óraszám"
                            />
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model.number="dolgozo.napi_km"
                              label="Napi km"
                            />
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-btn
                              color="error"
                              :block="true"
                              @click="removeNewDolgozo(key)"
                              >Törlés</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="dolgozo.nev"
                              label="Dolgozó neve"
                              :disabled="true"
                            />
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model.number="dolgozo.foglora"
                              label="Óraszám"
                            />
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model.number="dolgozo.napi_km"
                              label="Napi km"
                            />
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-btn
                              color="error"
                              :block="true"
                              @click="removeNewDolgozo(key)"
                              >Törlés</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-btn color="success" @click="ujDolgozo">Új dolgozó</v-btn>
                    <v-spacer />
                    <v-btn color="error" @click="dolgozoDialog = false">
                      Vissza
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="2" class="px-1 mt-3">
              <v-btn
                :block="true"
                color="success"
                :disabled="genDisabled"
                @click="generateXLSX"
              >
                Beosztás készítése
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <v-card-title>
          <h2>Beosztás feltöltése</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4" class="pb-5 pt-0">
              <uploadButton
                ref="upButton"
                :type="'fájlt'"
                @kep="gotKep"
                @removedKep="removeKep"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" class="pt-0 px-1">
              <v-select v-model="evMost" label="Év" :items="evek" />
            </v-col>
            <v-col cols="12" md="2" class="py-0 px-1">
              <v-select v-model="honapMost" label="Hónap" :items="honapok" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" class="pa-0">
              <v-btn
                :disabled="disableUploadButton"
                color="success"
                :block="true"
                @click="uploadFile"
              >
                Feltöltés
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-card flat>
        <v-card-title>
          <h2>Beosztás keresés</h2>
          <v-spacer />
          <v-row>
            <v-col cols="12" md="2" class="pt-3">
              <v-btn
                color="info"
                block
                :disabled="osszesitoPressed"
                @click="generateOsszesito"
              >
                Havi összesítő
              </v-btn>
            </v-col>
            <v-col cols="12" md="2" class="py-0">
              <v-autocomplete
                v-model="keresRegio"
                :items="regiok"
                label="Régió"
                item-value="azonosito"
                :item-text="getItemText"
              />
            </v-col>
            <v-col cols="12" md="2" class="py-0">
              <v-select
                v-model="keresObjektum"
                :items="objektumokKeres"
                label="Objektum"
                item-text="megnevezes"
                item-value="id"
                :disabled="noRegio"
              />
            </v-col>
            <v-col cols="12" md="2" class="py-0">
              <v-select v-model="ev" :items="evek" />
            </v-col>
            <v-col cols="12" md="2" class="py-0">
              <v-select v-model="honap" :items="honapok" />
            </v-col>
            <v-col cols="12" md="2" class="py-0">
              <v-text-field
                v-model="filter"
                label="Keresés"
                append-icon="search"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel v-for="(item, key) in filteredItems" :key="key">
              <v-expansion-panel-header>
                {{ item[0].regio_id }} - {{ item[0].regio_nev }} |
                {{ item[0].objektum_azon }} - {{ item[0].objektum_nev }} |
                {{ item.length }} változat
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                  <v-list-item v-for="(i, key) in item" :key="key">
                    <v-list-item-content>
                      <v-expansion-panels multiple>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <v-row>
                              <v-col cols="12" md="10">
                                {{ `${i.id} - ${i.datum}` }}
                              </v-col>
                              <v-col cols="12" md="2">
                                <v-btn
                                  color="primary"
                                  :block="true"
                                  @click="letoltXLSX(i.url)"
                                >
                                  Excel letöltése
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-row>
                                    <v-col cols="12" md="1">
                                      Azonosító
                                    </v-col>
                                    <v-col cols="12" md="2">
                                      Név
                                    </v-col>
                                    <v-col cols="12" md="1" align="center">
                                      Óraszám
                                    </v-col>
                                    <v-col cols="12" md="3" align="center">
                                      Napok
                                    </v-col>
                                    <v-col cols="12" md="2" align="center">
                                      Napok száma
                                    </v-col>
                                    <v-col cols="12" md="1" align="center">
                                      Össz.óra
                                    </v-col>
                                    <v-col cols="12" md="2">
                                      Műveletek
                                    </v-col>
                                  </v-row>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                v-for="(x, key) in filterEgyszerusitett(
                                  i.elszamolas_beosztasok_nap.data
                                )"
                                :key="key"
                              >
                                <v-list-item-content>
                                  <v-row>
                                    <v-col cols="12" md="1">
                                      {{ x.azon }}
                                    </v-col>
                                    <v-col cols="12" md="2">
                                      {{ x.nev }}
                                    </v-col>
                                    <v-col cols="12" md="1" align="center">
                                      {{ x.oraszam }}
                                    </v-col>
                                    <v-col cols="12" md="3" align="center">
                                      <v-row>
                                        <div
                                          v-for="(mikor,
                                          keyd) in x.mikorDolgozott"
                                          :key="keyd"
                                        >
                                          <v-col
                                            v-if="mikor.hanyOraban > 0"
                                            align="center"
                                          >
                                            <div v-if="mikor.hanyOraban > 0">
                                              {{ keyd }}
                                            </div>
                                          </v-col>
                                        </div>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12" md="2" align="center">
                                      {{ x.napok }}
                                    </v-col>
                                    <v-col cols="12" md="1" align="center">
                                      {{ x.orak }}
                                    </v-col>
                                    <v-col cols="12" md="2" class="py-0">
                                      <v-btn
                                        color="warning"
                                        :block="true"
                                        :disabled="
                                          x.azon === 0 || x.azon === ''
                                        "
                                        @click="nyomtatDolgozo(x, i)"
                                      >
                                        Nyomtatás
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="false">
                                <v-list-item-content>
                                  <v-row>
                                    <v-col cols="12" md="10" />
                                    <v-col cols="12" md="2">
                                      <v-btn
                                        color="primary"
                                        :block="true"
                                        download="test"
                                        @click="downloadAll(i)"
                                      >
                                        Összes letöltése
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres feltöltés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba feltöltés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
import uploadButton from "@/components/uploadButton.vue";

export default {
  title: "Elszámolás",
  components: { uploadButton },
  data() {
    return {
      isLoading: true,
      mentes: false,
      hiba: false,
      info: {
        datum: this.$moment().format("YYYY-MM-DD"),
        regio_id: 0,
        regio_azon: 0,
        regio_nev: "",
        objektum_azon: 0,
        objektum_nev: ""
      },
      objektumok: [],
      regiok: [],
      isDisabled: true,
      genDisabled: true,
      dolgozoDialog: false,
      sendDolgozok: [],
      upfile: "",
      dolgozok: [],
      filter: "",
      evMost: this.$moment().format("YYYY"),
      honapMost: this.$moment().format("MM"),
      ev: this.$moment().format("YYYY"),
      honap: this.$moment().format("MM"),
      items: [],
      evek: [
        { value: "2007", text: "2007" },
        { value: "2008", text: "2008" },
        { value: "2009", text: "2009" },
        { value: "2010", text: "2010" },
        { value: "2011", text: "2011" },
        { value: "2012", text: "2012" },
        { value: "2013", text: "2013" },
        { value: "2014", text: "2014" },
        { value: "2015", text: "2015" },
        { value: "2016", text: "2016" },
        { value: "2017", text: "2017" },
        { value: "2018", text: "2018" },
        { value: "2019", text: "2019" },
        { value: "2020", text: "2020" },
        { value: "2021", text: "2021" },
        { value: "2022", text: "2022" },
        { value: "2023", text: "2023" },
        { value: "2024", text: "2024" },
        { value: "2025", text: "2025" }
      ],
      honapok: [
        { value: "01", text: "Január" },
        { value: "02", text: "Február" },
        { value: "03", text: "Március" },
        { value: "04", text: "Április" },
        { value: "05", text: "Május" },
        { value: "06", text: "Június" },
        { value: "07", text: "Július" },
        { value: "08", text: "Augusztus" },
        { value: "09", text: "Szeptember" },
        { value: "10", text: "Október" },
        { value: "11", text: "November" },
        { value: "12", text: "December" }
      ],
      objektumokKeres: [],
      keresObjektum: 0,
      keresRegio: 0,
      noRegio: true,
      kulonElsz: [],
      userMore: [],
      genEv: this.$moment().format("YYYY"),
      genHonap: this.$moment().format("MM"),
      egyszerusitettek: [],
      osszesitoPressed: false
    };
  },
  computed: {
    filteredItems() {
      const searchTerm = this.filter.toString().toLowerCase();
      return this.items
        .filter(x => {
          let mappedNames = [];
          for (let y of x) {
            mappedNames = mappedNames.concat(
              y.elszamolas_beosztasok_nap.data
                .filter(
                  z => z.azon !== 0 && z.azon !== "" && z.nev !== undefined
                )
                .map(z => z.nev.toString().toLowerCase())
            );
          }
          if (
            x[0].objektum_nev
              .toString()
              .toLowerCase()
              .indexOf(searchTerm) !== -1 ||
            x[0].regio_nev
              .toString()
              .toLowerCase()
              .indexOf(searchTerm) !== -1 ||
            mappedNames.find(x => x.indexOf(searchTerm) !== -1)
          ) {
            return x;
          }
        })
        .filter(x =>
          this.keresRegio !== 0 ? x[0].regio_id === this.keresRegio : x
        )
        .filter(x =>
          this.keresObjektum !== 0
            ? x[0].objektum_azon === this.keresObjektum
            : x
        );
    },
    disableUploadButton() {
      const uploadEv = this.$moment(this.evMost).format("YYYY");
      const uploadHonap = this.$moment(this.honapMost).format("MM");
      const ev = this.$moment().format("YYYY");
      const honap = this.$moment().format("MM");
      if (uploadEv === ev && uploadHonap === honap) {
        return this.upfile ? false : true;
      } else if (uploadEv > ev || uploadHonap > honap) {
        return this.upfile ? false : true;
      } else {
        if (!this.kulonElsz.includes(this.$store.getters.getUser.id)) {
          return true;
        } else {
          return this.upfile ? false : true;
        }
      }
    }
  },
  watch: {
    "info.regio_id": function() {
      this.getNewObjektumok();
    },
    "info.objektum_azon": function() {
      this.getGenerate();
    },
    keresRegio: function() {
      this.getNewKeresObjektumok();
    },
    ev: function() {
      this.getItems();
    },
    honap: function() {
      this.getItems();
    }
  },
  beforeMount() {
    this.getMeta();
    this.getItems();
  },
  methods: {
    getItemText(data) {
      return data.azonosito === 0
        ? data.megnevezes
        : `${data.azonosito} - ${data.megnevezes}`;
    },
    getNev(data) {
      return `${data.id} - ${data.nev}`;
    },
    generateOsszesito() {
      this.osszesitoPressed = true;
      const isAdmin = this.kulonElsz.includes(this.$store.getters.getUser.id);
      const kulonRegiok = this.regiok
        .map(x => x.azonosito)
        .filter(x => x !== 0);
      this.$axios
        .post("elszamolas/generateOsszesito", {
          ev: this.ev,
          honap: this.honap,
          regio: this.keresRegio,
          objektum: this.keresObjektum,
          kulonRegiok: isAdmin || this.keresRegio !== 0 ? [] : kulonRegiok
        })
        .then(response => {
          this.osszesitoPressed = false;
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/zips/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/zips/${response.data.fileName}`,
            "_blank"
          );
        });
    },
    removeKep() {
      this.upfile = "";
    },
    getNapData(data) {
      return data.filter(x => Object.keys(x.mikorDolgozott).length > 0);
    },
    filterEgyszerusitett(data) {
      return data
        .filter(x => x.azon !== "" && x.azon !== 0)
        .filter(x => (this.egyszerusitettek.includes(x.azon) ? null : x));
    },
    removeNewDolgozo(key) {
      this.sendDolgozok.splice(key, 1);
    },
    letoltXLSX(path) {
      window.open(
        window.location.protocol == "http:"
          ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/elszamolas/${path}`
          : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/elszamolas/${path}`,
        "_blank"
      );
    },
    uploadFile() {
      let formData = new FormData();
      formData.append("file", this.upfile);
      formData.append("ev", this.evMost);
      formData.append("honap", this.honapMost);
      formData.append("id", this.$store.getters.getUser.id);
      this.$axios
        .post("elszamolas/upXLSX", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.mentes = true;
          this.getItems();
          this.$refs.upButton.removeFile();
          this.upfile = "";
        })
        .catch(() => {
          this.hiba = true;
        });
    },
    uploadFileOld() {
      let formData = new FormData();
      formData.append("file", this.upfile);
      formData.append("ev", this.evMost);
      formData.append("honap", this.honapMost);
      this.$axios
        .post("elszamolas/upXLSXOld", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.mentes = true;
          this.getItems();
          this.$refs.upButton.removeFile();
          this.upfile = "";
        })
        .catch(() => {
          this.hiba = true;
        });
    },
    gotKep(e) {
      this.upfile = e;
    },
    setData(id, key) {
      this.$axios.get(`elszamolas/userNev?id=${id}`).then(response => {
        this.sendDolgozok[key].nev = response.data.nev;
        this.sendDolgozok[key].foglora = response.data.foglora;
        this.sendDolgozok[key].napi_km = response.data.napi_km;
      });
    },
    ujDolgozo() {
      this.sendDolgozok.push({
        nev: "",
        id: 0,
        foglora: 0,
        newDolgozo: true,
        napi_km: 0
      });
    },
    generateXLSX() {
      this.$axios
        .post("/elszamolas/generate", {
          info: this.info,
          dolgozok: this.sendDolgozok,
          date: `${this.genEv}-${this.genHonap}`
        })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/elszamolas/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/elszamolas/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    getNewKeresObjektumok() {
      this.$axios
        .get(`meta/objektumok?id=${this.keresRegio}`)
        .then(response => {
          this.objektumokKeres = [
            { id: 0, megnevezes: "Nincs megadva" },
            ...response.data
          ];
          this.noRegio = false;
        });
    },
    getNewObjektumok() {
      const objektumok = this.$axios.get(
        `meta/objektumok?id=${this.info.regio_id}`
      );
      const regioAzon = this.$axios.get(
        `meta/regioAdat?id=${this.info.regio_id}`
      );
      Promise.all([objektumok, regioAzon]).then(responses => {
        this.objektumok = responses[0].data;
        this.isDisabled = false;
        this.info.regio_azon = responses[1].data.azonosito;
        this.info.regio_nev = responses[1].data.megnevezes;
        this.info.objektum_azon = responses[0].data[0].id;
      });
    },
    getGenerate() {
      const objektumAdat = this.$axios.get(
        `meta/objektumAdat?id=${this.info.objektum_azon}`
      );
      const objektumUser = this.$axios.get(
        `elszamolas/objektumUser?objektum_azon=${this.info.objektum_azon}`
      );
      Promise.all([objektumAdat, objektumUser]).then(responses => {
        this.info.objektum_nev = responses[0].data.megnevezes;
        this.sendDolgozok = responses[1].data.sort((a, b) =>
          a.nev < b.nev ? -1 : a.nev > b.nev
        );
        this.genDisabled = false;
      });
    },
    getMeta() {
      const kulonElszamolas = this.$axios.get("meta/getKulonElszamolas");
      const regiok = this.$axios.get("meta/regiok");
      const objektumok = this.$axios.get(`meta/objektumok?id=0`);
      const dolgozok = this.$axios.get("elszamolas/tobbUser");
      const egyszerusitettek = this.$axios.get("elszamolas/egyszerusitettek");
      Promise.all([
        regiok,
        objektumok,
        dolgozok,
        kulonElszamolas,
        egyszerusitettek
      ]).then(responses => {
        this.kulonElsz = responses[3].data.map(x => x.azonosito);
        this.objektumok = responses[1].data;
        this.egyszerusitettek = responses[4].data.map(x => x.id);
        if (!this.kulonElsz.includes(this.$store.getters.getUser.id)) {
          this.regiok = [
            { azonosito: 0, kihez_id: "", megnevezes: "Nincs megadva" },
            ...responses[0].data.filter(x => {
              const kikhez = x.kihez_id.split(";");
              if (
                kikhez.includes(this.$store.getters.getUser.id.toString()) ||
                x.azonosito === 0
              ) {
                this.userMore.push(x.azonosito);
                return x;
              }
            })
          ];
          this.dolgozok = responses[2].data.sort((a, b) =>
            a.nev < b.nev ? -1 : a.nev > b.nev
          );
          //.filter(x => (this.userMore.includes(x.regio) ? x : null));
        } else {
          this.regiok = [
            { azonosito: 0, kihez_id: "", megnevezes: "Nincs megadva" },
            ...responses[0].data
          ];
          this.dolgozok = responses[2].data.sort((a, b) =>
            a.nev < b.nev ? -1 : a.nev > b.nev
          );
        }
      });
    },
    getItems() {
      this.items = [];
      this.$axios
        .get(`elszamolas/beosztasok?ev=${this.ev}&honap=${this.honap}`)
        .then(response => {
          if (!this.kulonElsz.includes(this.$store.getters.getUser.id)) {
            this.items = response.data
              .filter(x => (this.userMore.includes(x[0].regio_azon) ? x : null))
              .sort((a, b) => a[0].regio_id - b[0].regio_id);
          } else {
            this.items = response.data.sort(
              (a, b) => a[0].regio_id - b[0].regio_id
            );
          }
        });
    },
    nyomtatDolgozo(
      dolgozo,
      { regio_azon, regio_nev, objektum_azon, objektum_nev }
    ) {
      this.$axios
        .post("/pdf/munkaido_nyilvantartas", {
          //.post("/pdf/newElszamolasExport", {
          dolgozo,
          evho: `${this.ev}-${this.honap}-`,
          regioText: `${regio_azon} - ${regio_nev}${
            regio_nev === objektum_nev ? "" : " - " + objektum_nev
          }`,
          objektum_azon
        })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    downloadAll(item) {
      const dolgozok = item.elszamolas_beosztasok_nap.data.filter(x =>
        x.azon === 0 || x.azon === "" ? null : x
      );
      const regio_azon = item.regio_azon;
      const regio_nev = item.regio_nev;
      const objektum_azon = item.objektum_azon;
      const objektum_nev = item.objektum_nev;
      let arr = [];
      for (let x of dolgozok) {
        arr.push(
          this.$axios.post("/pdf/munkaido_nyilvantartas", {
            dolgozo: x,
            evho: `${this.ev}-${this.honap}-`,
            regioText: `${regio_azon} - ${regio_nev}${
              regio_nev === objektum_nev ? "" : " - " + objektum_nev
            }`,
            objektum_azon
          })
        );
      }
      Promise.all(arr).then(async success => {
        const links = success.map(x =>
          window.location.protocol == "http:"
            ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${x.data.fileName}`
            : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${x.data.fileName}`
        );
        for (let url of links) {
          const a = document.createElement("a");
          a.download = "";
          a.href = url;
          //a.target = "_blank";
          a.style.display = "none";
          document.body.append(a);
          a.click();

          await delay(100);
          a.remove();
        }
      });
    }
  }
};
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
</script>

<style lang="stylus"></style>
