<template>
  <v-row>
    <v-col v-if="videoNetworkingFiltered.length > 0" cols="12">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" style="text-align: center;" mt-5>
              <h1>Rögzítők</h1>
            </v-col>
            <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
          </v-row>
        </v-col>
        <video-rogzito
          v-for="(networking, key) in videoNetworkingFiltered"
          :key="key"
          v-bind="{
            networking,
            vkey: key,
            removeNetworking,
            ujRogzitoUser,
            removeRogzitoUser
          }"
        />
      </v-row>
    </v-col>
    <v-col v-if="videoAdat.kamera.length > 0" cols="12">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" style="text-align: center;" mt-5>
              <h1>Kamerák</h1>
            </v-col>
            <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
          </v-row>
        </v-col>
        <video-kamera
          v-for="(kamera, key) in videoAdat.kamera"
          :key="key"
          v-bind="{
            kamera,
            vkey: key,
            removeKamera
          }"
        />
      </v-row>
    </v-col>
    <v-col v-if="videoAdat.vhalozati.length > 0" cols="12">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" style="text-align: center;" mt-5>
              <h1>Hálózati Eszközök</h1>
            </v-col>
            <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
          </v-row>
        </v-col>
        <video-halozati
          v-for="(vhalozati, key) in videoAdat.vhalozati"
          :key="key"
          v-bind="{
            vhalozati,
            vkey: key,
            removeVHalozati
          }"
        />
      </v-row>
    </v-col>
    <v-col v-if="videoAdat.tartomany.length > 0" cols="12">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" style="text-align: center;" mt-5>
              <h1>Tartományok</h1>
            </v-col>
            <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
          </v-row>
        </v-col>
        <video-tartomany
          v-for="(tartomany, key) in videoAdat.tartomany"
          :key="key"
          v-bind="{
            tartomany,
            vkey: key,
            removeTartomany
          }"
        />
      </v-row>
    </v-col>
    <v-col v-if="videoAdat.nano.length > 0" cols="12">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" style="text-align: center;" mt-5>
              <h1>Wifi eszközök</h1>
            </v-col>
            <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
          </v-row>
        </v-col>
        <video-nano
          v-for="(nano, key) in videoAdat.nano"
          :key="key"
          v-bind="{
            nano,
            vkey: key,
            removeNano
          }"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import videoRogzito from "./components/videoRogzito";
import videoKamera from "./components/videoKamera";
import videoNano from "./components/videoNano";
import videoTartomany from "./components/videoTartomany";
import videoHalozati from "./components/videoHalozati";

export default {
  components: {
    videoRogzito,
    videoKamera,
    videoNano,
    videoTartomany,
    videoHalozati
  },
  props: [
    "videoAdat",
    "videoNetworking",
    "ujRogzitoUser",
    "removeRogzitoUser",
    "removeKamera",
    "removeNano",
    "removeNetworking",
    "removeTartomany",
    "removeVHalozati"
  ],
  computed: {
    videoNetworkingFiltered() {
      return this.videoNetworking.filter(val => !val.deleted);
    }
  }
};
</script>
