<template>
  <v-col cols="12" class="py-0">
    <v-row>
      <v-col v-if="!newUgyfel" cols="12" md="6" class="px-1 py-1">
        <v-btn
          :block="true"
          color="primary"
          style="height: 70px;"
          @click="nyomtatPdf(3)"
        >
          <v-row>
            <v-col cols="12" class="pa-0">
              <v-icon>print</v-icon>
            </v-col>
            <v-col cols="12" class="pa-0">
              Adatlap
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col v-if="!newUgyfel" cols="12" md="6" class="px-1 py-1">
        <v-btn
          :block="true"
          color="primary"
          style="height: 70px;"
          @click="nyomtatUres(3)"
        >
          <v-row>
            <v-col cols="12" class="pa-0">
              <v-icon>print</v-icon>
            </v-col>
            <v-col cols="12" class="pa-0">
              Adatlap
            </v-col>
            <v-col cols="12" class="pa-0">
              (üres)
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col cols="12" class="px-0 py-1">
        <v-btn class="warning" :block="true" @click="ujErtesitendoTuzjelzo">
          Új Értesítendő
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["nyomtatPdf", "nyomtatUres", "ujErtesitendoTuzjelzo", "newUgyfel"]
};
</script>
