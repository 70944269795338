<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">Új menetlevél</h1>
                </v-col>
                <v-col cols="12" md="6" offset-md="3" class="py-0">
                  <v-autocomplete
                    id="newmenetlevel_valasztott"
                    v-model="valasztott"
                    label="Autó"
                    :items="autok"
                    item-value="id"
                    item-text="text"
                    :filter="filterAutok"
                  />
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="newmenetlevel_datum"
                    v-model="data.datum"
                    label="Dátum"
                    type="date"
                    max="2999-12-31"
                  /> </v-col
                ><v-col cols="12" md="6" class="py-0">
                  <v-row v-if="data.fajlnev">
                    <v-col cols="12" md="6" class="py-0">
                      <v-text-field
                        id="menetlevel_fajlnev"
                        v-model="data.fajlnev"
                        label="Menetlevél"
                        readonly
                        clearable
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 mt-3">
                      <v-btn color="warning" :block="true" @click="getSzamla">
                        Menetlevél
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" md="6" class="py-0">
                      <v-text-field
                        id="menetlevel_fajlnev"
                        v-model="data.fajlnev"
                        label="Menetlevél"
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <uploadButton
                        ref="upButton"
                        :type="'fájlt'"
                        @kep="gotKep"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-1 mt-5 d-none d-sm-flex"
              style="align-items: flex-start;"
            >
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn :block="true" color="success" @click="handleSubmit">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn
                color="success"
                fab
                bottom
                right
                fixed
                @click="handleSubmit"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
import uploadButton from "@/components/uploadButton.vue";

export default {
  title: "Új Menetlevél",
  components: { uploadButton },
  data() {
    return {
      data: {
        datum: this.$moment().format("YYYY-MM-DD"),
        fajlnev: ""
      },
      mentes: false,
      hiba: false,
      valasztott: 0,
      kep: {
        name: ""
      },
      autok: []
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    gotKep(e) {
      this.kep = e;
    },
    getSzamla() {
      return window.open(
        window.location.protocol == "http:"
          ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/menetlevelek/${this.data.fajlnev}`
          : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/menetlevelek/${this.data.fajlnev}`,
        "_blank"
      );
    },
    handleSubmit() {
      this.$axios
        .post("autok/createMenetlevel", {
          autoid: this.valasztott,
          fajlnev: this.data.fajlnev ? this.data.fajlnev : "",
          deleted: 0,
          datum: this.data.datum
        })
        .then(resp => {
          if (this.kep.name == "") {
            this.mentes = true;
            this.data = {
              datum: this.$moment().format("YYYY-MM-DD"),
              fajlnev: ""
            };
            this.valasztott = 0;
          } else {
            let formData = new FormData();
            formData.append("file", this.kep);
            formData.append("id", resp.data.newid);
            this.$axios
              .post("autok/upMenetlevel", formData)
              .then(() => {
                this.mentes = true;
                this.$refs.upButton.removeFile();
                this.data = {
                  datum: this.$moment().format("YYYY-MM-DD"),
                  fajlnev: ""
                };
                this.valasztott = 0;
              })
              .catch(() => {
                this.hiba = true;
              });
          }
        })
        .catch(() => (this.hiba = true));
      /*
      let autoData = this.data;
      autoData.autoid = this.valasztott;
      autoData.deleted = 0;
      autoData.liter = autoData.liter.toString().replace(",", ".");
      this.$axios
        .post("autok/createTankolas", autoData)
        .then(() => {
          this.mentes = true;
          this.data = {
            rogzitette: this.$store.getters.getUser.nev,
            datum: this.$moment().format("YYYY-MM-DD"),
            elozo_km: 0,
            liter: null,
            km_ora: null,
            dolgozo: 0,
            ar: null
          };
          this.valasztott = 0;
        })
        .catch(() => (this.hiba = true));
        */
    },
    getData() {
      this.$axios.get("autok?deleted=0").then(response => {
        let data = [];
        for (let item of response.data) {
          data.push({
            id: item.id,
            text: `${item.id} - ${item.rendszam} | ${item.marka} ${item.tipus}`
          });
        }
        this.autok = data;
      });
    },
    filterAutok(item, filterText) {
      const searchText = filterText.toLowerCase();
      const text = item.text.toLowerCase();
      return text.indexOf(searchText) > 1;
    }
  }
};
</script>
