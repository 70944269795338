<template>
  <v-col cols="12" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <h5>{{ fkey + 1 }}. felhasználó</h5>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="felhasznalo.felhasznalonev" label="Felh. név" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="felhasznalo.jelszo"
          label="Jelszó"
          :append-icon="
            felhasznalo.passshow
              ? 'mdi-checkbox-outline'
              : 'mdi-checkbox-blank-outline'
          "
          @click:append="felhasznalo.passshow = !felhasznalo.passshow"
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          color="error"
          text
          :block="true"
          @click="removeSzamitogepUser(vkey, fkey)"
          >{{ vkey + 1 }}/{{ fkey + 1 }}. Felhasználó törlése</v-btn
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["felhasznalo", "vkey", "fkey", "removeSzamitogepUser"]
};
</script>
