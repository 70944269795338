<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Rendelések</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="1" offset-md="7" class="py-0">
                  <v-select
                    v-model="raktaron"
                    label="Raktáron?"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="1" class="py-0">
                  <v-select
                    v-model="deleted"
                    label="Törölt rendelések?"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                show-expand
                single-expand
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-row>
                    <v-col cols="12" md="6" pr-1>
                      <v-btn
                        :block="true"
                        color="success"
                        :disabled="raktaron ? true : false"
                        @click="toRaktar(item)"
                      >
                        Raktárra
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-btn
                        :block="true"
                        color="error"
                        :disabled="raktaron ? true : false"
                        @click="deleteItem(item)"
                      >
                        Törlés
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:expanded-item="{ item }">
                  <td colspan="5">
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="12" md="2">
                              Vonalkód
                            </v-col>
                            <v-col cols="12" md="2">
                              Megnevezés
                            </v-col>
                            <v-col cols="12" md="2">
                              Méret
                            </v-col>
                            <v-col cols="12" md="2">
                              Bruttó darabár
                            </v-col>
                            <v-col cols="12" md="2">
                              Darab
                            </v-col>
                            <v-col cols="12" md="2">Bruttó összár</v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-for="(rendeles, key) in item.ruha_rendelesek"
                        :key="key"
                      >
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="12" md="2">
                              {{ rendeles.ruha_adatok.barcode }}
                            </v-col>
                            <v-col cols="12" md="2">
                              {{ rendeles.ruha_adatok.ruha_nev }}
                            </v-col>
                            <v-col cols="12" md="2">
                              {{ rendeles.ruha_adatok.ruha_meret }}
                            </v-col>
                            <v-col cols="12" md="2">
                              {{ rendeles.ruha_adatok.brutto_ar }} Ft
                            </v-col>
                            <v-col cols="12" md="2">
                              {{ rendeles.rendeles_darab }} db
                            </v-col>
                            <v-col cols="12" md="2">
                              {{
                                rendeles.rendeles_darab *
                                  rendeles.ruha_adatok.brutto_ar
                              }}
                              Ft
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="12" md="2" offset-md="8"
                              >Rendelés összár:</v-col
                            >
                            <v-col cols="12" md="2"
                              >{{ osszAr(item.ruha_rendelesek) }} Ft</v-col
                            >
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Rendelések",
  data() {
    return {
      isLoading: true,
      filter: "",
      items: [],
      headers: [
        { text: "Azonosító", value: "id" },
        { text: "Megnevezés", value: "megnevezes" },
        { text: "Rendelés dátuma", value: "rendeles_datuma" },
        { text: "Műveletek", value: "muveletek", width: "300px" }
      ],
      igenNem: [{ value: 0, text: "Nem" }, { value: 1, text: "Igen" }],
      deleted: 0,
      raktaron: 0,
      beszallitok: []
    };
  },
  watch: {
    deleted: function() {
      this.getData();
    },
    raktaron: function() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios
        .get(
          `ruhak/rendelesek?deleted=${this.deleted}&raktaron=${this.raktaron}`
        )
        .then(response => {
          this.items = response.data;
          this.isLoading = false;
        });
    },
    toRaktar(item) {
      this.$axios.post("ruhak/rendelesRaktar", item).then(() => this.getData());
    },
    deleteItem(item) {
      this.$axios.post("ruhak/deleteRendeles", item).then(() => {
        this.getData();
      });
    },
    osszAr(rendelesek) {
      let ar = 0;
      rendelesek.forEach(
        val => (ar += val.rendeles_darab * val.ruha_adatok.brutto_ar)
      );
      return ar;
    }
  }
};
</script>

<style lang="stylus"></style>
