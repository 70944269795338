var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[(_vm.diszpecserSzamitogepek.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","mt-5":""}},[_c('h1',[_vm._v("Számítógépek")])]),_c('v-col',{attrs:{"cols":"12","mb-1":""}},[_c('hr',{staticClass:"thinLine"})])],1)],1),_vm._l((_vm.diszpecserSzamitogepek),function(szamitogep,key){return _c('diszpecser-szamitogep',_vm._b({key:key},'diszpecser-szamitogep',{
          szamitogep,
          vkey: key,
          removeSzamitogep: _vm.removeSzamitogep,
          ujSzamitogepUser: _vm.ujSzamitogepUser,
          removeSzamitogepUser: _vm.removeSzamitogepUser
        },false))})],2)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-autocomplete',{attrs:{"label":"Új Telep azonosító","items":_vm.filteredTelepData,"filter":_vm.telepFilter},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.azonosito)+" | "+_vm._s(data.item.ugyfel)+" - "+_vm._s(data.item.objektum)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.azonosito)+" | "+_vm._s(data.item.ugyfel)+" - "+_vm._s(data.item.objektum)+" ")]}}]),model:{value:(_vm.ujAzonosito),callback:function ($$v) {_vm.ujAzonosito=$$v},expression:"ujAzonosito"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","pt-2":""}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.ujAzonosito == null,"tile":"","block":true},on:{"click":function($event){_vm.ujAzonLista(_vm.ujAzonosito);
        _vm.ujAzonosito = null;}}},[_vm._v(" Hozzáadás ")])],1),(_vm.diszpecserAzonlista.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","mt-5":""}},[_c('h1',[_vm._v("Azonosítók")])]),_c('v-col',{attrs:{"cols":"12","mb-1":""}},[_c('hr',{staticClass:"thinLine"})])],1)],1),_vm._l((_vm.diszpecserAzonlista),function(azon,key){return _c('diszpecser-azonosito',_vm._b({key:key},'diszpecser-azonosito',{
          azon,
          vkey: key,
          removeAzonosito: _vm.removeAzonosito
        },false))})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }