<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">Új ruha adatai</h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="ruha.barcode"
                    label="Vonalkód"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field v-model="ruha.ruha_nev" label="Név" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field v-model="ruha.ruha_meret" label="Méret" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ruha.brutto_ar"
                    label="Bruttó ár"
                    suffix="Ft"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ruha.kezelesi_koltseg"
                    label="Kezelési költség"
                    suffix="Ft"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ruha.kihordasi_ido"
                    label="Kihordási idő"
                    suffix="hó"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-select
                    v-model="ruha.beszallito"
                    :items="beszallitok"
                    item-text="nev"
                    item-value="id"
                    label="Beszállító"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0 mt-3 d-none d-sm-flex"
              style="align-items: flex-start;"
            >
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn color="info" :block="true" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn color="success" :block="true" @click="sendCreate">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn color="success" fab bottom right fixed @click="sendCreate"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Új ruha",
  data() {
    return {
      ruha: {
        beszallito: 0
      },
      beszallitok: [],
      mentes: false,
      hiba: false
    };
  },
  methods: {
    sendCreate() {
      this.$axios.post("ruhak/ujRuha", this.ruha).then(response => {
        this.mentes = true;
        this.$router.push({
          name: "ruhak-id",
          params: { id: response.data.id }
        });
      });
    },
    getData() {
      this.$axios.get("ruhak/beszallitok").then(response => {
        this.beszallitok = [{ id: 0, nev: "Nincs megadva" }, ...response.data];
      });
    }
  }
};
</script>

<style></style>
