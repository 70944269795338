<template>
  <v-col cols="12" md="4" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <h3>{{ parseInt(vkey) + 1 }}. bővítő</h3>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="bovito.tipus" label="Típus"
      /></v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="bovito.gyarto" label="Gyártó"
      /></v-col>
      <v-col cols="12" class="py-0">
        <v-text-field v-model="bovito.sorozatszam" label="Sorozatszám" />
      </v-col>
      <v-col cols="12">
        <v-btn
          color="error"
          :block="true"
          text
          @click="removeBovitoRiaszto(vkey)"
          >Törlés</v-btn
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["bovito", "vkey", "removeBovitoRiaszto"]
};
</script>
