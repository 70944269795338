import Vue from "vue";
import Router from "vue-router";
import Login from "./pages/Login.vue";
import Layout from "./pages/Layout.vue";
import PasswordChange from "./pages/passwordChange.vue";
import Elszamolas from "./pages/elszamolas.vue";
import PageChange from "./pages/index.vue";

import Autok from "./routes/autok";
import Ceginfo from "./routes/ceginfo";
import Dolgozok from "./routes/dolgozok";
import GpsHibak from "./routes/gpshibak";
import Hibak from "./routes/hibak";
import Karbantartasok from "./routes/karbantartasok";
import Projektek from "./routes/projektek";
import Ruhak from "./routes/ruhak";
import Telefonok from "./routes/telefonok";
import Ugyfelek from "./routes/ugyfelek";
import Kutyak from "./routes/kutyak";
import Jelentesek from "./routes/jelentesek";
import Munkalapok from "./routes/munkalapok";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/",
      component: Layout,
      children: [
        {
          path: "",
          name: "index",
          component: PageChange
        },
        {
          path: "/passwordChange",
          name: "passwordChange",
          component: PasswordChange
        },
        {
          path: "/elszamolas",
          name: "elszamolas",
          component: Elszamolas
        },
        ...Autok,
        ...Ceginfo,
        ...Dolgozok,
        ...GpsHibak,
        ...Hibak,
        ...Karbantartasok,
        ...Projektek,
        ...Ruhak,
        ...Telefonok,
        ...Ugyfelek,
        ...Kutyak,
        ...Jelentesek,
        ...Munkalapok
      ]
    },
    {
      path: "*",
      beforeEnter: (to, from, next) => {
        next("/");
      }
    }
  ]
});
