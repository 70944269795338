<template>
  <v-row>
    <v-col cols="12" style="text-align: center" class="py-0">
      <h1>Központ adatai</h1>
    </v-col>
    <v-col cols="12"> <hr class="thinLine" /> </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field v-model="riasztoAdat.kozponttipus" label="Központ Tipus" />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field
        v-model="riasztoAdat.kozpontgyarto"
        label="Központ Gyártó"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field
        v-model="riasztoAdat.kozpontsorozatszam"
        label="Központ Sorozatszám"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field
        v-model="riasztoAdat.telefonszam"
        label="Központ Telefonszáma"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field v-model="riasztoAdat.vedendoobj" label="Védendő objektum" />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field v-model="riasztoAdat.teszt" label="Teszt" />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-select
        v-model="riasztoAdat.hangjelzo"
        :items="[{ value: 0, text: 'Nem aktív' }, { value: 1, text: 'Aktív' }]"
        label="Hangjelzés"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field
        v-model="riasztoAdat.akkumerules"
        label="Akkumerülés (óra)"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-select
        v-model="riasztoAdat.nyzjelzes"
        :items="vannincs"
        label="Nyitás-zárás átjelzés"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-select
        v-model="riasztoAdat.nyzfigyeles"
        :items="vannincs"
        label="Nyitás-zárás figyelés"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field v-model="riasztoAdat.kommunikator" label="Kommunikátor" />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field v-model="riasztoAdat.kodtabla" label="Kódtábla" />
    </v-col>
    <v-col cols="12" md="4" class="py-0">
      <v-text-field
        v-model="riasztoAdat.mesterkod"
        label="Mesterkód"
        :append-icon="
          riasztoAdat.mestershow
            ? 'mdi-checkbox-outline'
            : 'mdi-checkbox-blank-outline'
        "
        @click:append="riasztoAdat.mestershow = !riasztoAdat.mestershow"
      />
    </v-col>
    <v-col cols="12" md="4" class="py-0">
      <v-text-field
        v-model="riasztoAdat.mernokikod"
        label="Mérnöki kód"
        :append-icon="
          riasztoAdat.mernokishow
            ? 'mdi-checkbox-outline'
            : 'mdi-checkbox-blank-outline'
        "
        @click:append="riasztoAdat.mernokishow = !riasztoAdat.mernokishow"
      />
    </v-col>
    <v-col cols="12" md="4" class="py-0">
      <v-text-field v-model="riasztoAdat.kezelok_szama" label="Kezelők száma" />
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-text-field
        v-model="riasztoAdat.pcazonosito"
        label="PC Azonosító"
        :append-icon="
          riasztoAdat.pcazonshow
            ? 'mdi-checkbox-outline'
            : 'mdi-checkbox-blank-outline'
        "
        @click:append="riasztoAdat.pcazonshow = !riasztoAdat.pcazonshow"
      />
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-text-field
        v-model="riasztoAdat.pcjelszo"
        label="PC Jelszó"
        :append-icon="
          riasztoAdat.pcjelszoshow
            ? 'mdi-checkbox-outline'
            : 'mdi-checkbox-blank-outline'
        "
        @click:append="riasztoAdat.pcjelszoshow = !riasztoAdat.pcjelszoshow"
      />
    </v-col>
    <v-col v-if="riasztoAdat.ertesitendok.length > 0" cols="12">
      <v-row>
        <v-col cols="12" style="text-align: center;" mt-5>
          <h1>Értesítendők</h1>
        </v-col>
        <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
        <riaszto-ertesitendo
          v-for="(ertesitendo, key) in riasztoAdat.ertesitendok"
          :key="key"
          v-bind="{ ertesitendo, vkey: key, removeErtesitendoRiaszto }"
        />
      </v-row>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-text-field
        v-model="riasztoAdat.jelszo"
        label="Jelszó"
        :append-icon="
          riasztoAdat.jelszoshow
            ? 'mdi-checkbox-outline'
            : 'mdi-checkbox-blank-outline'
        "
        @click:append="riasztoAdat.jelszoshow = !riasztoAdat.jelszoshow"
      />
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-text-field
        v-model="riasztoAdat.ellenjelszo"
        label="Ellenjelszó"
        :append-icon="
          riasztoAdat.ellenjelszoshow
            ? 'mdi-checkbox-outline'
            : 'mdi-checkbox-blank-outline'
        "
        @click:append="
          riasztoAdat.ellenjelszoshow = !riasztoAdat.ellenjelszoshow
        "
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-select
        v-model="riasztoAdat.kiszallas"
        :items="vannincs"
        label="Kivonulás"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field
        v-if="riasztoAdat.kiszallas"
        v-model="riasztoAdat.jaror1"
        label="1. Járőr"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field
        v-if="riasztoAdat.kiszallas"
        v-model="riasztoAdat.jaror2"
        label="2. Járőr"
      />
    </v-col>
    <v-col cols="12" md="3" class="py-0">
      <v-text-field
        v-if="riasztoAdat.kiszallas"
        v-model="riasztoAdat.jaror3"
        label="3. Járőr"
      />
    </v-col>
    <v-col cols="12" :class="{ hide: riasztoAdat.zonak.length <= 0 }">
      <v-row>
        <v-col cols="12" style="text-align: center" mt-5>
          <h1>Zónák</h1>
        </v-col>
        <v-col cols="12"> <hr class="thinLine" /> </v-col>
        <riaszto-zona
          v-for="(zona, key) in riasztoAdat.zonak"
          :key="key"
          v-bind="{ zona, vkey: key, removeZonaRiaszto }"
        />
      </v-row>
    </v-col>
    <v-col cols="12" :class="{ hide: riasztoAdat.kezelok.length <= 0 }">
      <v-row>
        <v-col cols="12" style="text-align: center" mt-5>
          <h1>Kezelők</h1>
        </v-col>
        <v-col cols="12"> <hr class="thinLine" /> </v-col>
        <riaszto-kezelo
          v-for="(kezelo, key) in riasztoAdat.kezelok"
          :key="key"
          v-bind="{ kezelo, vkey: key, removeKezeloRiaszto }"
        />
      </v-row>
    </v-col>
    <v-col cols="12" :class="{ hide: riasztoAdat.bovitok.length <= 0 }">
      <v-row>
        <v-col cols="12" style="text-align: center" mt-5>
          <h1>Bővítők</h1>
        </v-col>
        <v-col cols="12"> <hr class="thinLine" /> </v-col>
        <riaszto-bovito
          v-for="(bovito, key) in riasztoAdat.bovitok"
          :key="key"
          v-bind="{ bovito, vkey: key, removeBovitoRiaszto }"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import riasztoZona from "./components/riasztoZona";
import riasztoErtesitendo from "./components/riasztoErtesitendo";
import riasztoKezelo from "./components/riasztoKezelo";
import riasztoBovito from "./components/riasztoBovito";

export default {
  components: {
    riasztoZona,
    riasztoErtesitendo,
    riasztoKezelo,
    riasztoBovito
  },
  props: [
    "riasztoAdat",
    "removeZonaRiaszto",
    "removeErtesitendoRiaszto",
    "removeKezeloRiaszto",
    "removeBovitoRiaszto",
    "vannincs"
  ]
};
</script>
