<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Exportálás</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="py-0" cols="12" md="2">
              <v-file-input v-model="file" />
            </v-col>
            <v-col class="py-0 pt-3" cols="12" md="2">
              <v-btn color="primary" :disabled="pressed" @click="makeExport">
                Export
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Nyomtatás",
  data() {
    return {
      file: null,
      pressed: false
    };
  },
  methods: {
    makeExport() {
      if (this.file.name.substr(-3) === "csv") {
        this.pressed = true;
        let formData = new FormData();
        formData.append("file", this.file);
        this.$axios
          .post("bizttech/exportUgyfelek", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            this.pressed = false;
            this.file = null;
            window.open(
              window.location.protocol == "http:"
                ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/zips/${response.data.fileName}`
                : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/zips/${response.data.fileName}`,
              "_blank"
            );
          });
      }
    }
  }
};
</script>
