<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title>
          <h2>Fegyverlejáratok</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" offset-md="7" class="py-0 mt-3">
                  <v-btn
                    color="warning"
                    to="/ceginfo/fegyverlejarat/newFegyver"
                    :block="true"
                  >
                    Új Fegyver
                  </v-btn>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="(item, key) in items"
                      :key="key"
                      :class="
                        item.rowType === 2
                          ? 'danger'
                          : item.rowType === 1
                          ? 'warn'
                          : ''
                      "
                    >
                      <td>{{ item.azonosito }}</td>
                      <td>{{ item.tipus }}</td>
                      <td>{{ item.fegyverszam }}</td>
                      <td>{{ item.ervenyesseg }}</td>
                      <td>{{ item.engedely }}</td>
                      <td>{{ item.hely }}</td>
                      <td>{{ item.ceg }}</td>
                      <td>
                        <v-btn
                          :block="true"
                          :to="`/ceginfo/fegyverlejarat/${item.azonosito}`"
                          color="success"
                        >
                          Adatlap
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Fegyverlejáratok",
  data() {
    return {
      isLoading: true,
      ceg: 0,
      filter: "",
      items: [],
      headers: [
        { text: "Azonosító", value: "azonosito" },
        { text: "Típus", value: "tipus" },
        { text: "Fegyverszám", value: "fegyverszam" },
        { text: "Érvényesség", value: "ervenyesseg" },
        { text: "Engedély", value: "engedely" },
        { text: "Hely", value: "hely" },
        { text: "Cég", value: "ceg" },
        { text: "Row Type", value: "rowType", class: "hide" },
        { text: "Műveletek", value: "muveletek" }
      ],
      tipusok: [
        { id: 0, nev: "Nincs megadva" },
        { id: 1, nev: "BON-SEC" },
        { id: 2, nev: "Pronob" }
      ]
    };
  },
  watch: {
    kilepett: function() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios
        .get(`ceginfo/fegyverlejaratok?ceg=${this.ceg}`)
        .then(response => {
          let data = [];
          response.data.forEach((item, index) => {
            data.push({
              azonosito: item.id,
              tipus: item.tipus,
              fegyverszam: item.fegyverszam,
              ervenyesseg: item.ervenyesseg,
              engedely: item.engedely,
              hely: item.hely,
              ceg: "",
              muveletek: item.id,
              rowType: 0
            });
            if (item.ceg == 1) data[index].ceg = this.tipusok[1].nev;
            else if (item.ceg == 2) data[index].ceg = this.tipusok[2].nev;
            else data[index].ceg = this.tipusok[0].nev;
            if (item.ervenyesseg < this.$moment().format("YYYY-MM-DD"))
              data[index].rowType = 2;
            else if (
              item.ervenyesseg > this.$moment().format("YYYY-MM-DD") &&
              item.ervenyesseg <
                this.$moment()
                  .add(2, "months")
                  .format("YYYY-MM-DD")
            )
              data[index].rowType = 1;
          });
          this.items = data.sort((a, b) => b.rowType - a.rowType);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="stylus"></style>
