<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">Új szervíz</h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="szerviz_datum"
                    v-model="data.datum"
                    label="Dátum"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="szerviz_km_ora"
                    v-model="data.km_ora"
                    label="Kilóméteróra állása"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field v-model="data.ar" label="Ár" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-select
                    id="szerviz_szerviz"
                    v-model="data.szerviz"
                    label="Szervíz"
                    :items="szervizek"
                    item-text="szerviz_nev"
                    item-value="szerviz"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-row v-if="data.szamla">
                    <v-col cols="12" md="6" class="py-0">
                      <v-text-field
                        id="szerviz_szamla"
                        v-model="data.szamla"
                        label="Számla"
                        readonly
                        clearable
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 mt-3">
                      <v-btn color="warning" :block="true" @click="getSzamla">
                        Számla
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" md="6" class="py-0">
                      <v-text-field
                        id="szerviz_szamla"
                        v-model="data.szamla"
                        label="Számla"
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <uploadButton
                        ref="upButton"
                        :type="'fájlt'"
                        @kep="gotKep"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    id="szerviz_munkalap"
                    v-model="data.munkalap"
                    label="Munkalap"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-textarea
                    id="szerviz_rovidleiras"
                    v-model="data.rovidleiras"
                    label="Rövid leírás"
                  />
                </v-col>
                <v-col cols="12" md="8" class="py-0">
                  <v-textarea
                    id="szerviz_leiras"
                    v-model="data.leiras"
                    label="Leírás"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="py-1 mt-3">
              <v-row>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="success" @click="handleSubmit">
                    Mentés
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/autok/${$route.params.id}`"
                  >
                    Autó Adatlap
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/autok/${$route.params.id}/szervizek`"
                  >
                    További Szervizek
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn
                color="success"
                fab
                bottom
                right
                fixed
                @click="handleSubmit"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
import uploadButton from "@/components/uploadButton.vue";

export default {
  title: "Új Szervíz",
  components: { uploadButton },
  data() {
    return {
      data: {
        deleted: 0,
        autoid: this.$route.params.id,
        szamla: ""
      },
      mentes: false,
      hiba: false,
      szervizek: [],
      kep: {
        name: ""
      }
    };
  },
  beforeMount() {
    this.getMeta();
  },
  methods: {
    getMeta() {
      this.$axios
        .get("autok/szerviz")
        .then(response => (this.szervizek = response.data));
    },
    gotKep(e) {
      this.kep = e;
    },
    handleSubmit() {
      this.$axios
        .post("autok/createSzerviz", {
          ...this.data,
          szamla: this.data.szamla ? this.data.szamla : ""
        })
        .then(resp => {
          if (this.kep.name == "") {
            this.mentes = true;
            this.$router.push({
              path:
                "/autok/" +
                this.$route.params.id +
                "/szervizek/" +
                resp.data.newid
            });
          } else {
            let formData = new FormData();
            formData.append("file", this.kep);
            formData.append("id", resp.data.newid);
            this.$axios
              .post("autok/upSzamla", formData)
              .then(() => {
                this.mentes = true;
                this.$refs.upButton.removeFile();
                this.$router.push({
                  path:
                    "/autok/" +
                    this.$route.params.id +
                    "/szervizek/" +
                    resp.data.newid
                });
              })
              .catch(() => {
                this.hiba = true;
              });
          }
        })
        .catch(() => (this.hiba = true));
    }
  }
};
</script>
