<template>
  <v-col cols="12">
    <v-row align-content="start">
      <v-col cols="12" class="py-0">
        <v-autocomplete
          v-model="value.ceg"
          label="Cég"
          :items="cegek"
          item-value="id"
          item-text="megnevezes"
          required
          :rules="[v => !!v || 'Cég kiválasztása kötelező!']"
          :filter="singleFilter"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="value.cegbe"
          label="Belépés dátuma"
          type="date"
          max="2999-12-13"
          required
          :rules="[v => !!v || 'Belépés dátuma kötelező!']"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="value.cegki"
          label="Kilépés dátuma"
          type="date"
          max="2999-12-31"
        />
      </v-col>
      <v-col
        cols="12"
        class="hoverDelete"
        style="text-align: center; cursor: pointer;"
        @click="delCeg(vkey)"
      >
        <v-icon style="color: red;">delete</v-icon>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["value", "cegek", "delCeg", "vkey", "singleFilter"]
};
</script>

<style></style>
