<template>
  <v-col cols="12" class="pb-2 pt-0">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col
            v-if="!value.isNew"
            md="8"
            cols="12"
            style="margin: auto 0 0 0;"
          >
            <h3 class="engedelyNev">{{ value.engedely_tipus.megnevezes }}</h3>
          </v-col>
          <v-col v-else md="8" cols="12" class="py-0">
            <v-select
              v-model="value.tipus"
              label="Tipus"
              :items="engedelytipusok"
              item-value="id"
              item-text="megnevezes"
              required
              :rules="[v => !!v || 'Tipus kiválasztása kötelező!']"
            />
          </v-col>
          <v-col
            cols="4"
            style="cursor: pointer; text-align: center;"
            class="hoverPrint"
          >
            <span
              v-if="
                value.tipus == 1 && !value.isNew && userData.aktualisceg != 8
              "
              @click="fegyverNyomtatas"
            >
              <v-icon style="color: blue;">print</v-icon>
            </span>
            <span v-else-if="value.tipus == 1 && !value.isNew">
              <v-icon style="color: blue;" @click="fegyverPronobNyomtatas">
                print
              </v-icon>
              <v-icon style="color: green;" @click="oktatoPronobNyomtatas">
                print
              </v-icon>
            </span>
            <span
              v-if="
                value.tipus == 6 &&
                  !value.isNew &&
                  (kulonUID.includes(parseInt(userData.id)) ||
                    kulonID.includes(parseInt(userRegio)))
              "
              @click="lejarMunkaZ"
            >
              <v-icon style="color: blue;">print</v-icon>
            </span>
            <span
              v-else-if="value.tipus == 6 && !value.isNew"
              @click="lejarMunka"
            >
              <v-icon style="color: blue;">print</v-icon>
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="3" cols="12" class="py-0">
        <v-select v-model="value.jelzett" label="Jelzett" :items="igennem" />
      </v-col>
      <v-col v-if="value.tipus !== 11" md="9" cols="12" class="py-0">
        <v-text-field
          v-model="value.szam"
          label="Szám"
          @change="onChangeData"
        />
      </v-col>
      <v-col v-if="value.tipus === 11" md="3" class="py-0">
        <v-select
          v-model="value.oltasvan"
          label="Olt. kapott"
          :items="igennem"
        />
      </v-col>
      <v-col v-if="value.tipus === 11" md="3" class="py-0">
        <v-select v-model="value.igvan" label="Ig. van" :items="igennem" />
      </v-col>
      <v-col v-if="value.tipus === 11" md="3" class="py-0">
        <v-select
          v-model="value.igcsatolt"
          label="Ig. csatolt"
          :items="igennem"
        />
      </v-col>
      <v-col v-if="value.tipus === 11" md="6" cols="12" class="py-0">
        <v-text-field
          v-model="value.oltas"
          label="Oltás ideje"
          type="date"
          max="2999-12-31"
          @change="onChangeData"
        />
      </v-col>
      <v-col md="6" cols="12" class="py-0">
        <v-text-field
          v-model="value.lejarat"
          label="Lejárat"
          type="date"
          max="2999-12-31"
          @change="onChangeData"
        />
      </v-col>
      <v-col
        v-if="value.tipus != 5 && value.tipus != 7 && value.tipus !== 11"
        md="6"
        cols="12"
        class="py-0"
      >
        <v-text-field
          v-model="value.hatosag"
          label="Hatóság"
          @change="onChangeData"
        />
      </v-col>
      <v-col
        cols="12"
        class="hoverDelete"
        style="cursor: pointer; text-align: center;"
        @click="delEngedely(vkey)"
      >
        <v-icon style="color: red;">delete</v-icon>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: [
    "value",
    "engedelytipusok",
    "userData",
    "delEngedely",
    "vkey",
    "userRegio",
    "kulonID",
    "kulonUID"
  ],
  data() {
    return {
      igennem: [{ value: 0, text: "Nem" }, { value: 1, text: "Igen" }]
    };
  },
  methods: {
    fegyverNyomtatas() {
      this.$axios
        .post("/pdf/fegyverigazolas", {
          id: this.userData.id,
          szakmaid: this.value.id
        })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    fegyverPronobNyomtatas() {
      this.$axios
        .post("/pdf/fegyverigazolas_pronob", {
          id: this.userData.id,
          szakmaid: this.value.id
        })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    oktatoPronobNyomtatas() {
      this.$axios
        .post("/pdf/oktatoigazolas_pronob", {
          id: this.userData.id,
          szakmaid: this.value.id
        })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    lejarMunka() {
      this.$axios
        .post("/pdf/munkaalk_new", { id: this.value.id })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    lejarMunkaZ() {
      this.$axios
        .post("/pdf/munkaalk_new", { id: this.value.id })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    onChangeData() {
      this.value.jelzett = 0;
    }
  }
};
</script>

<style></style>
