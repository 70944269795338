<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Ügyfelek</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" offset-md="7" class="py-0">
                  <v-select
                    v-model="filterType"
                    label="Rendszertípus"
                    :items="rendszerTipusok"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="filteredList"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :custom-filter="customFilter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:body="{ items }">
                  <tbody v-if="isMobile">
                    <tr
                      v-for="item in items"
                      :key="item.azonosito"
                      class="hidden-sm-and-up"
                      @click="$router.push(`/ugyfelek/${item.azonosito}`)"
                    >
                      <td>{{ item.azonosito }}</td>
                      <td>{{ item.ugyfel }} - {{ item.objektum }}</td>
                      <td>{{ item.varos }}</td>
                      <td class="hide">{{ item.type }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="item in items"
                      :key="item.azonosito"
                      class="hidden-sm-and-down"
                    >
                      <td>{{ item.azonosito }}</td>
                      <td>{{ item.ugyfel }}</td>
                      <td>{{ item.objektum }}</td>
                      <td>{{ `${item.varos}, ${item.utcahsz}` }}</td>
                      <td class="hide">{{ item.type }}</td>
                      <td>
                        <v-row>
                          <v-col cols="12" md="3">
                            <v-btn
                              color="success"
                              :block="true"
                              :to="`/ugyfelek/${item.azonosito}`"
                            >
                              Adatlap
                            </v-btn>
                          </v-col>
                          <v-col
                            v-if="item.azonosito.charAt(0) !== '9'"
                            cols="12"
                            md="2"
                          >
                            <v-btn
                              color="primary"
                              :block="true"
                              @click="printUgyfel(item.azonosito)"
                            >
                              <v-icon>print</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" md="4" pl-1 pr-1>
                            <v-btn
                              color="info"
                              :block="true"
                              :to="
                                `/karbantartasok/${item.azonosito}/newKarbantartas`
                              "
                            >
                              Új karbantartás
                            </v-btn>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-btn
                              color="warning"
                              :block="true"
                              :to="`/hibak/${item.azonosito}/newHiba`"
                            >
                              Új hiba
                            </v-btn>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { union } from "lodash";

export default {
  title: "Ügyfelek",
  data() {
    return {
      isLoading: true,
      rendszerTipusok: [
        {
          text: "Nincs megadva",
          value: 0
        },
        {
          text: "Riasztórendszer",
          value: 1
        },
        { text: "Videórendszer", value: 2 },
        { text: "Beléptetőrendszer", value: 3 },
        { text: "Tűzjelzőrendszer", value: 4 },
        { text: "Távfelügyeleti pont", value: 5 }
      ],
      filter: this.$store.getters["getUgyfelek"].filter,
      filterType: this.$store.getters["getUgyfelek"].type,
      headers: [
        { text: "ID", value: "azonosito" },
        { text: "Ügyfél", value: "ugyfel", class: "hidden-sm-and-down" },
        { text: "Objektum", value: "objektum" },
        { text: "Város", value: "cim", class: "hidden-sm-and-down" },
        { text: "Tipus", value: "tipus", class: "hide" },
        { text: "Műveletek", value: "muveletek", class: "hidden-sm-and-down" }
      ],
      items: []
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    filteredList() {
      return this.items.filter(val =>
        this.filterType === 0 ? true : this.filterType === val.type
      );
    }
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    this.items = this.$store.getters.ugyfelekTable;
  },
  beforeDestroy() {
    this.saveInfo();
  },
  methods: {
    customFilter(value, search, item) {
      const searchString = search.toString().toLowerCase();
      const normalizedUgyfel = item.ugyfel
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const normalizedObjektum = item.objektum
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return (
        item.azonosito.toString().indexOf(search.toString()) !== -1 ||
        item.ugyfel
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        normalizedUgyfel.indexOf(searchString) !== -1 ||
        item.objektum
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        normalizedObjektum.indexOf(searchString) !== -1 ||
        item.varos
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.utcahsz
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1
      );
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      const riaszto = this.$axios.get("bizttech/riaszto_ugyfelek");
      const video = this.$axios.get("bizttech/video_ugyfelek");
      const belepteto = this.$axios.get("bizttech/belepteto_ugyfelek");
      const tuzjelzo = this.$axios.get("bizttech/tuzjelzo_ugyfelek");
      const diszpecserKozpontok = this.$axios.get(
        "bizttech/diszpecser_ugyfelek"
      );
      Promise.all([
        riaszto,
        video,
        belepteto,
        tuzjelzo,
        diszpecserKozpontok
      ]).then(response => {
        const data = union(
          response[0].data,
          response[1].data,
          response[2].data,
          response[3].data,
          response[4].data
        );
        data.map(val => {
          switch (val.azonosito.charAt(0)) {
            case "5":
              val.type = 2;
              break;
            case "6":
              val.type = 3;
              break;
            case "7":
              val.type = 4;
              break;
            case "9":
              val.type = 5;
              break;
            default:
              val.type = 1;
              break;
          }
        });
        this.items = data;
        this.$store.dispatch("setUgyfelek", data);
        this.isLoading = false;
      });
    },
    saveInfo() {
      this.$store.dispatch("saveUgyfelek", {
        filter: this.filter,
        type: this.filterType
      });
    },
    printUgyfel(azonosito) {
      let type = 0;
      if (azonosito.charAt(0) === "5") type = 1;
      if (azonosito.charAt(0) === "6") type = 2;
      if (azonosito.charAt(0) === "7") type = 3;
      const tipusok = [
        "riasztoNyomtat",
        "videoNyomtat",
        "beleptetoNyomtat",
        "tuzjelzoNyomtat"
      ];
      this.$axios
        .post(`/pdf/${tipusok[type]}`, { azonosito: azonosito })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            "_blank"
          )
        );
    }
  }
};
</script>
