<template>
  <v-row class="mt-2">
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <h1 style="text-align: center;">
                        Projekt alapadatok - BSTP{{ projekt.ev }}/{{
                          projekt.sorszam.toString().padStart(4, "0")
                        }}
                      </h1>
                    </v-col>
                    <v-col cols="12"> <hr class="thinLine" /> </v-col>
                    <v-col cols="12" md="3" class="py-0">
                      <v-text-field v-model="projekt.ugyfel" label="Ügyfél" />
                    </v-col>
                    <v-col cols="12" md="3" class="py-0">
                      <v-text-field
                        v-model="projekt.objektum"
                        label="Objektum"
                      />
                    </v-col>
                    <v-col cols="12" md="3" class="py-0">
                      <v-text-field
                        v-model="projekt.megkezdve"
                        label="Projekt kezdés"
                        type="date"
                        max="2999-12-31"
                      />
                    </v-col>
                    <v-col cols="12" md="3" class="py-0">
                      <v-text-field
                        v-model="projekt.befejezve"
                        label="Projekt vége"
                        type="date"
                        max="2999-12-31"
                      />
                    </v-col>
                    <v-col cols="12" md="4" class="py-0">
                      <v-text-field
                        v-model="projekt.iranyitoszam"
                        label="Irányítószám"
                      />
                    </v-col>
                    <v-col cols="12" md="4" class="py-0">
                      <v-text-field v-model="projekt.varos" label="Város" />
                    </v-col>
                    <v-col cols="12" md="4" class="py-0">
                      <v-text-field
                        v-model="projekt.utcahsz"
                        label="Utca, házszám"
                      />
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-textarea
                        v-model="projekt.megjegyzes"
                        label="Megjegyzés"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="projekt.esemenyek.length > 0"
                  cols="12"
                  style="margin-top: 10px;"
                >
                  <v-row>
                    <v-col cols="12">
                      <h1 style="text-align: center;">Események</h1>
                    </v-col>
                    <v-col cols="12"> <hr class="thinLine" /> </v-col>
                    <v-col
                      v-for="(esemeny, key) in projekt.esemenyek"
                      :key="key"
                      cols="12"
                      md="6"
                    >
                      <v-row v-if="!esemeny.deleted">
                        <v-col cols="12">
                          <h2>{{ key + 1 }}. esemény</h2>
                        </v-col>
                        <v-col cols="12" md="7" class="py-0">
                          <v-select
                            v-model="esemeny.tipus"
                            :items="esemenyTipusok"
                          />
                        </v-col>
                        <v-col cols="12" md="5" class="py-0">
                          <v-text-field
                            v-model="esemeny.datum"
                            label="Dátum"
                            type="date"
                            max="2999-12-31"
                          />
                        </v-col>
                        <v-col cols="12" px1>
                          <v-row v-if="esemeny.melleklet">
                            <v-col cols="12" md="8">
                              <v-text-field
                                v-model="esemeny.melleklet"
                                label="Melléklet"
                                readonly
                                clearable
                              />
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-btn
                                color="warning"
                                :block="true"
                                @click="getEsemeny(esemeny.melleklet)"
                                >Melléklet</v-btn
                              >
                            </v-col>
                          </v-row>
                          <v-row v-else>
                            <v-col cols="12" md="8">
                              <v-text-field
                                v-model="esemeny.melleklet"
                                label="Melléklet"
                                disabled
                              />
                            </v-col>
                            <v-col cols="12" md="4">
                              <upload-button
                                ref="upButton"
                                type="fájlt"
                                :esemeny-key="key"
                                @melleklet="gotEsemeny"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-textarea v-model="esemeny.leiras" label="Leírás" />
                        </v-col>
                        <v-col cols="12" md="4" offset-md="8" class="py-0">
                          <v-btn
                            :block="true"
                            color="error"
                            @click="deleteEsemeny(key)"
                          >
                            Törlés
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="projekt.kapcsolattartok.length > 0"
                  cols="12"
                  style="margin-top: 10px;"
                >
                  <v-row>
                    <v-col cols="12">
                      <h1 style="text-align: center;">Kapcsolattartók</h1>
                    </v-col>
                    <v-col cols="12"> <hr class="thinLine" /> </v-col>
                    <v-col
                      v-for="(kapcsolat, key) in projekt.kapcsolattartok"
                      :key="key"
                      cols="12"
                      md="6"
                    >
                      <v-row>
                        <v-col cols="12" md="3" class="py-0">
                          <v-text-field v-model="kapcsolat.nev" label="Név" />
                        </v-col>
                        <v-col cols="12" md="3" class="py-0">
                          <v-text-field
                            v-model="kapcsolat.telefonszam"
                            label="Telefonszám"
                          />
                        </v-col>
                        <v-col cols="12" md="4" class="py-0">
                          <v-text-field
                            v-model="kapcsolat.email"
                            label="Email cím"
                          />
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-btn
                            color="error"
                            :block="true"
                            @click="deleteKapcsolat(key)"
                          >
                            Törlés
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="py-0 mt-3">
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn color="info" :block="true" @click="createEsemeny">
                    Új esemény
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-btn color="info" :block="true" @click="createKapcsolat">
                    Új kapcsolattartó
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn color="info" :block="true" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn color="success" :block="true" @click="updateProjekt">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn
                color="success"
                fab
                bottom
                right
                fixed
                @click="updateProjekt"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres mentés!
    </v-snackbar>
    <v-snackbar
      v-model="hiba"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="error"
    >
      Hiba mentés közben!
    </v-snackbar>
  </v-row>
</template>

<script>
import uploadButton from "@/components/uploadButton";

export default {
  title: "Projekt",
  components: {
    uploadButton
  },
  data() {
    return {
      esemenyTipusok: [
        {
          text: "Ügyfél jelentkezése",
          value: 1
        },
        {
          text: "Felmérés",
          value: 2
        },
        {
          text: "Árajánlat készítése",
          value: 3
        },
        {
          text: "Megrendelés",
          value: 4
        },
        {
          text: "Szerződés",
          value: 5
        },
        {
          text: "Munka megkezdve",
          value: 6
        },
        {
          text: "Munkaközi esemény",
          value: 7
        },
        {
          text: "Munka befejezve",
          value: 8
        },
        {
          text: "Átadás/átvétel",
          value: 9
        },
        {
          text: "Számlázás",
          value: 10
        }
      ],
      projekt: {
        sorszam: "",
        kapcsolattartok: [],
        esemenyek: []
      },
      mentes: false,
      hiba: false
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    gotEsemeny(e) {
      let formData = new FormData();
      formData.append("file", e.file);
      this.$axios.post("projektek/upProjektKep", formData).then(() => {
        this.projekt.esemenyek[e.key].melleklet = e.file.name;
      });
    },
    getEsemeny(melleklet) {
      return window.open(
        window.location.protocol == "http:"
          ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/projektek/${melleklet}`
          : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/projektek/${melleklet}`,
        "_blank"
      );
    },
    getData() {
      this.$axios.get("/projektek/" + this.$route.params.id).then(response => {
        this.projekt = response.data;
      });
    },
    createEsemeny() {
      this.projekt.esemenyek.push({
        tipus: 1,
        datum: this.$moment().format("YYYY-MM-DD"),
        leiras: "",
        melleklet: ""
      });
    },
    deleteEsemeny(key) {
      this.projekt.esemenyek.splice(key, 1);
    },
    createKapcsolat() {
      this.projekt.kapcsolattartok.push({
        nev: "",
        telefonszam: "",
        email: ""
      });
    },
    deleteKapcsolat(key) {
      this.projekt.kapcsolattartok.splice(key, 1);
    },
    updateProjekt() {
      this.$axios
        .post("projektek/updateProjekt", this.projekt)
        .then(() => (this.mentes = true))
        .catch(() => (this.hiba = true));
    }
  }
};
</script>
