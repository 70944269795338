import KarbantartasokIndex from "@/pages/karbantartasok/index";
import KarbantartasokVisszalevo from "@/pages/karbantartasok/visszalevo";
import KarbantartasokID from "@/pages/karbantartasok/_id";
import KarbantartasokAzon from "@/pages/karbantartasok/_id/_azon";
import KarbantartasokNewKarbantartas from "@/pages/karbantartasok/_id/newKarbantartas";

export default [
  {
    name: "karbantartasok-index",
    path: "karbantartasok",
    component: KarbantartasokIndex
  },
  {
    name: "karbantartasok-visszalevo",
    path: "karbantartasok/visszalevo",
    component: KarbantartasokVisszalevo
  },
  {
    name: "karbantartasok-id",
    path: "karbantartasok/:id",
    component: KarbantartasokID
  },
  {
    name: "karbantartasok-newKarbantartas",
    path: "karbantartasok/:id/newKarbantartas",
    component: KarbantartasokNewKarbantartas
  },
  {
    name: "karbantartasok-karbantartasazon",
    path: "karbantartasok/:id/:azon",
    component: KarbantartasokAzon
  }
];
