<template>
  <router-view />
</template>

<script>
export default {
  beforeMount() {
    if (this.$store.getters.getToken ? true : false)
      this.$router.push({ path: this.$store.getters.getUser.homePage });
  }
};
</script>

<style></style>
