import { render, staticRenderFns } from "./ugyfelekButtons.vue?vue&type=template&id=848715a0"
import script from "./ugyfelekButtons.vue?vue&type=script&lang=js"
export * from "./ugyfelekButtons.vue?vue&type=script&lang=js"
import style0 from "./ugyfelekButtons.vue?vue&type=style&index=0&id=848715a0&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_prettier@1.18.2_vue-tem_r5ucawze3km5bgpl4jbbqisbla/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports