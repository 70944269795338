var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[(_vm.videoNetworkingFiltered.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","mt-5":""}},[_c('h1',[_vm._v("Rögzítők")])]),_c('v-col',{attrs:{"cols":"12","mb-1":""}},[_c('hr',{staticClass:"thinLine"})])],1)],1),_vm._l((_vm.videoNetworkingFiltered),function(networking,key){return _c('video-rogzito',_vm._b({key:key},'video-rogzito',{
          networking,
          vkey: key,
          removeNetworking: _vm.removeNetworking,
          ujRogzitoUser: _vm.ujRogzitoUser,
          removeRogzitoUser: _vm.removeRogzitoUser
        },false))})],2)],1):_vm._e(),(_vm.videoAdat.kamera.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","mt-5":""}},[_c('h1',[_vm._v("Kamerák")])]),_c('v-col',{attrs:{"cols":"12","mb-1":""}},[_c('hr',{staticClass:"thinLine"})])],1)],1),_vm._l((_vm.videoAdat.kamera),function(kamera,key){return _c('video-kamera',_vm._b({key:key},'video-kamera',{
          kamera,
          vkey: key,
          removeKamera: _vm.removeKamera
        },false))})],2)],1):_vm._e(),(_vm.videoAdat.vhalozati.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","mt-5":""}},[_c('h1',[_vm._v("Hálózati Eszközök")])]),_c('v-col',{attrs:{"cols":"12","mb-1":""}},[_c('hr',{staticClass:"thinLine"})])],1)],1),_vm._l((_vm.videoAdat.vhalozati),function(vhalozati,key){return _c('video-halozati',_vm._b({key:key},'video-halozati',{
          vhalozati,
          vkey: key,
          removeVHalozati: _vm.removeVHalozati
        },false))})],2)],1):_vm._e(),(_vm.videoAdat.tartomany.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","mt-5":""}},[_c('h1',[_vm._v("Tartományok")])]),_c('v-col',{attrs:{"cols":"12","mb-1":""}},[_c('hr',{staticClass:"thinLine"})])],1)],1),_vm._l((_vm.videoAdat.tartomany),function(tartomany,key){return _c('video-tartomany',_vm._b({key:key},'video-tartomany',{
          tartomany,
          vkey: key,
          removeTartomany: _vm.removeTartomany
        },false))})],2)],1):_vm._e(),(_vm.videoAdat.nano.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","mt-5":""}},[_c('h1',[_vm._v("Wifi eszközök")])]),_c('v-col',{attrs:{"cols":"12","mb-1":""}},[_c('hr',{staticClass:"thinLine"})])],1)],1),_vm._l((_vm.videoAdat.nano),function(nano,key){return _c('video-nano',_vm._b({key:key},'video-nano',{
          nano,
          vkey: key,
          removeNano: _vm.removeNano
        },false))})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }