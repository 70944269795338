<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Hibajavítások | {{ $route.params.id }}</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" hidden-xs-only class="py-0">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2" offset-md="3" class="py-0">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/ugyfelek/${$route.params.id}`"
                  >
                    Ügyfél adatlap
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-btn
                    :block="true"
                    color="warning"
                    :to="`/hibak/${$route.params.id}/newHiba`"
                  >
                    Új Hibabejelentés
                  </v-btn>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="items"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-btn
                    :block="true"
                    :to="`/hibak/${$route.params.id}/${item.sorszam}`"
                    color="success"
                  >
                    Adatlap
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="d-sm-none">
      <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
        ><v-icon>arrow_left</v-icon></v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Hibák",
  data() {
    return {
      isLoading: true,
      items: [],
      filter: "",
      headers: [
        { text: "Karbantartók", value: "munkavegzo" },
        { text: "Dátum", value: "bejelentes_datuma" },
        { text: "Felvette", value: "felvevo" },
        { text: "Munkalap száma", value: "munkalap_szama" },
        { text: "Javítás dátuma", value: "javitas_datuma" },
        { text: "Műveletek", value: "muveletek" }
      ]
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.items = [];
      this.$axios
        .get(`bizttech/hibak/${this.$route.params.id}`)
        .then(response => {
          this.items = response.data;
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="stylus"></style>
