<template>
  <v-col cols="12" md="3" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0"
        ><h3>{{ vkey + 1 }}. kamera</h3></v-col
      >
      <v-col cols="12" class="py-0">
        <v-text-field v-model="kamera.kameratipus" label="Típus" />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field v-model="kamera.sorozatszam" label="Sorozatszám" />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field v-model="kamera.kameraszam" label="Név" />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field v-model="kamera.kameraip" label="IP" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="kamera.felhasznalonev" label="Felh. név" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="kamera.jelszo"
          label="Jelszó"
          :append-icon="
            kamera.passshow
              ? 'mdi-checkbox-outline'
              : 'mdi-checkbox-blank-outline'
          "
          @click:append="kamera.passshow = !kamera.passshow"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-textarea v-model="kamera.megjegyzes" label="Megjegyzés" />
      </v-col>
      <v-col cols="12">
        <v-btn color="error" text :block="true" @click="removeKamera(vkey)"
          >{{ `${vkey + 1}.` }}<br />kamera törlése</v-btn
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["kamera", "vkey", "removeKamera"]
};
</script>
