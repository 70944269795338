<template>
  <v-col cols="12" md="4" class="py-0">
    <v-row>
      <v-col cols="12" class="py-0"
        ><h3>{{ vkey + 1 }}. olvasó</h3></v-col
      >
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="olvaso.gyarto" label="Gyártó" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="olvaso.tipus" label="Tipus" />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field v-model="olvaso.gyariszam" label="Gyáriszám" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-checkbox v-model="olvaso.vesznyito" label="Vésznyitó" />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-checkbox v-model="olvaso.nyomogomb" label="Nyomógomb" />
      </v-col>
      <v-col cols="12">
        <v-btn color="error" text :block="true" @click="removeOlvaso(vkey)">{{
          `${vkey + 1}. olvasó törlése`
        }}</v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["olvaso", "vkey", "removeOlvaso"]
};
</script>
