export const SET_DOLGOZOK = "SET_DOLGOZOK";
export const SET_UGYFELEK = "SET_UGYFELEK";
export const LOGOUT = "LOGOUT";

const state = {
  dolgozok: [],
  ugyfelek: []
};

const actions = {
  setDolgozok({ commit }, dolgozok) {
    return commit(SET_DOLGOZOK, dolgozok);
  },
  setUgyfelek({ commit }, ugyfelek) {
    return commit(SET_UGYFELEK, ugyfelek);
  },
  doLogout({ commit }) {
    return new Promise(resolve => {
      commit(LOGOUT);
      resolve({ success: true });
    });
  }
};

const mutations = {
  [SET_DOLGOZOK](state, data) {
    state.dolgozok = data;
  },
  [SET_UGYFELEK](state, data) {
    state.ugyfelek = data;
  },
  [LOGOUT](state) {
    state.dolgozok = [];
    state.ugyfelek = [];
  }
};

const getters = {
  dolgozokTable: state => {
    return state.dolgozok;
  },
  ugyfelekTable: state => {
    return state.ugyfelek;
  }
};

export default {
  //namespaced: true,
  state,
  mutations,
  actions,
  getters
};
